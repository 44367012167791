import React from "react";

import SVG from 'react-inlinesvg';


function PlaylistIcon({ playlist, isLightMode }) {

  if (playlist.icon) {
    return (<SVG src={playlist.icon} alt={playlist.name} style={{
      width: 24, height: 24, marginRight: 0, 
      //filter: isLightMode?'invert(100%)':'',
    }} />);
  }

  const iconName = SlugIconMap[playlist.slug];
  if (iconName) {
    return (<img src={!isLightMode ? '/icons/' + iconName : '/icons-light/' + iconName} alt={playlist.name} style={{width: 24, height: 24}} />);
  }

  return (<svg
    width="18"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17C13.6569 17 15 15.6569 15 14C15 12.3431 13.6569 11 12 11C10.3431 11 9 12.3431 9 14C9 15.6569 10.3431 17 12 17Z"
      stroke={isLightMode ? "black" : "white"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 14V1H19M11 2H1M1 6H11M7 10H1"
      stroke={isLightMode ? "black" : "white"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>);
}

const SlugIconMap = {
  "pop": "micro.svg",
  "rock": "guitar.svg",
  "indie": "cassette.svg",
  "classical": "violin.svg",
  "country": "cowboy-hat.svg",
  "latin": "latino.svg",
  "ballads": "piano.svg",
  "edm": "turntables.svg",
  "hip-hop": "cap.svg",
  "rnb": "boombox.svg",
  "lofi": "headphones.svg",
  "gaming": "game-controller.svg",
  "film": "tv.svg",
  "disco": "discoball.svg",
  "k-pop": "heart-with-stars.svg",
  "bedroom-pop": "sneaker.svg",
  "psychedelic": "eye-square.svg",
  "punk": "rock-finger.svg"
}

export default PlaylistIcon;
