import { setCatchHandler } from "workbox-routing";
import { AUTH_LOGOUT } from "./store/action/ActionType";
import { refresh } from "./store/action/AuthAction";
import React from "react";
import {useSelector} from "react-redux";

const DEFAULT_AVATAR = "/img/avatar-fill.png";
const MIN_VIEWS = 15;

function getDefaultAvatar(seed, bgColor) {
  return `https://api.dicebear.com/5.x/adventurer/svg?seed=${seed}${bgColor || ''}&backgroundColor=${bgColor || "f68a92"}`;
}

function mediaURL(path) {
  return `${process.env.REACT_APP_MEDIA_BASE}${path}`;
}

function fillUserFields(user) {
  user.name = user.display_name || user.username;
  user.url = "/users/" + user.username;
  // user.avatarIcon = user.avatar
  //   ? mediaURL(user.avatar.replace(".thumb.jpeg", ".icon.jpeg"))
  //   : getDefaultAvatar(user.email, avatarBgColor);
}

function getArtistsStr(artists) {
  return artists.map((aa) => aa.name).join(", ");
}

function formatDuration(ms) {
  if (ms == null) return '';
  const seconds = ms / 1000;
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(1, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
}

function _getToken(tokenName, checkExpiration) {
  if (checkExpiration) {
    const exp_at = localStorage.getItem(tokenName + "_exp");
    if (!exp_at || new Date().getTime() > +exp_at) {
      return "";
    }
  }
  return localStorage.getItem(tokenName);
}

function getAccessToken(checkExpiration = false) {
  return _getToken("access_token", checkExpiration);
}

function getAccessConfig() {
  const token = getAccessToken();
  if (!token) {
    return {};
  }
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
}
function setLightTheme() {
  document.body.classList.add("Light-Theme");
  localStorage.setItem("theme", "light");
}
function setDarkTheme() {
  document.body.classList.remove("Light-Theme");
  localStorage.removeItem("theme");
}
function getTheme() {
  return localStorage.getItem("theme");
}
function updateTokenChecker(
  dispatch,
  isOnLogout,
  isReloadAfterRefresh = false
) {
  clearTimeout(window._check_token_task);
  if (isOnLogout) {
    localStorage.clear();
    dispatch({
      type: AUTH_LOGOUT,
    });
    return;
  }

  if (!getAccessToken()) {
    // No access token, even expired one, nothing to refresh
    return;
  }

  const exp_at = localStorage.getItem("access_token_exp");
  const now = new Date().getTime();
  const exp_in = +exp_at - now;
  if (exp_in > 100) {
    window._check_token_task = setTimeout(
      () => updateTokenChecker(dispatch, false),
      exp_in
    );
    return;
  }

  const refreshToken = _getToken("refresh_token", true);
  if (!refreshToken) {
    localStorage.setItem("access_token", "");
    localStorage.setItem("access_token_exp", "");
    localStorage.setItem("refresh_token", "");
    localStorage.setItem("refresh_token_exp", "");
    document.location.reload();
    return;
  }

  const formData = new FormData();
  formData.append("refresh", refreshToken);
  dispatch(refresh(formData, isReloadAfterRefresh));

  // schedule additional check to handle possible refresh failure
  window._check_token_task = setTimeout(
    () => updateTokenChecker(dispatch, false),
    30000
  );
}

function makeGrid(items, cols) {
  const ret = [];
  let col = -1;
  let row = null;
  for (let item of items) {
    col = (col + 1) % cols;
    if (!col) {
      row = [];
      ret.push(row);
    }
    row.push(item);
  }
  if (ret.length) {
    const lastRow = ret[ret.length - 1];
    while (lastRow.length < cols) {
      lastRow.push(null);
    }
  }
  return ret;
}

function makeNumberSmall(number) {
  if (number > 1000000000) return `${Math.floor(number / 1000000000)}B`;
  else if (number > 1000000) return `${Math.floor(number / 1000000)}M`;
  else if (number > 1000) return `${Math.floor(number / 1000)}K`;
  else return `${number}`;
}

function formatNumber(cnt) {
  if (cnt >= 1000000) {
    //1.2M
    cnt = Math.floor(cnt / 100000.0 + 0.005);
    return `${cnt/10.0}M`;
  }
  if (cnt >= 100000) {
    //965K
    cnt = Math.floor(cnt / 1000.0 + 0.005);
    return `${cnt}K`;
  }
  if (cnt >= 1000) {
    //86.1K
    cnt = Math.floor(cnt / 100.0 + 0.005);
    return `${cnt/10.0}K`;
  }
  return cnt;
}

function Viewers({count}) {
  const {isLightMode} = useSelector((state) => state.toggle);
  if (count < MIN_VIEWS) {
    return '';
  }
  return <span className="viewers" style={{
    color: isLightMode ? "#180a21" : '#FFFFFF'
  }}>
    <img
      src={isLightMode ? "/icons-light/eye.svg" : "/icons/eye.svg"}
      alt="img"
      style={{width: "16px", top: '-1px', opacity: 0.5, marginRight: "4px"}}
    />
    {formatNumber(count)}{" "}
    {/*viewers*/}
  </span>
}

function Stars({level, isLightMode, align}) {
  align = align || 'right';
  const css = {};
  if (align === 'right') {
    css.float = 'right';
    css.margin = 0;
    css.lineHeight = '18px';
    css.verticalAlign = 'middle'
  }
  return (
    <div className="level-stars" style={css}>
      <ul>
        {[10, 20, 30, 40].map((ii) => (
          <li key={ii}>
            <img
              src={
                isLightMode
                  ? `/img/star-${
                    level >= ii ? "1" : "3"
                    }.svg`
                  : `/img/star-${
                    level >= ii ? "1" : "2"
                    }.svg`
              }
              alt="img"
              className="img-fluid"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

function getChordData(chordData, videoDuration) {
  const chordProgressions = chordData.map(item => {
    const chords = item[0].split(',');
    return chords;
  });
  const timeStamps = chordData.map(item => {
    let res = []
    let i=0
    for (const elem of item){
      if(i===0) {
        i++;
        continue;
      }
      res.push(elem/1000.0);
    }
    return res;
  });

  const sepChords = [];
  for (let groupIdx in chordProgressions) {
    for (let chordIdx in chordProgressions[groupIdx]) {
      sepChords.push({
        ts: timeStamps[groupIdx][chordIdx],
        midTS: timeStamps[groupIdx][chordIdx],
        chord: chordProgressions[groupIdx][chordIdx],
        groupIdx: +groupIdx,
        chordIdx: +chordIdx,
        flatIdx: sepChords.length,
        isFirst: false,
        isLast: false,

        isFirstInGroup: +chordIdx === 0,
        isLastInGroup: +chordIdx === chordProgressions[groupIdx].length-1,
      });
    }
  }

  for(let ii=0; ii<sepChords.length-1; ii++) {
    sepChords[ii].midTS = (sepChords[ii].ts + sepChords[ii+1].ts)/2.0;
  }
  sepChords[sepChords.length-1].midTS = (sepChords[sepChords.length-1].ts + videoDuration)/2.0;

  if (sepChords.length > 0) {
    sepChords[0].isFirst = true;
    sepChords[sepChords.length-1].isLast = true;
  }

  return { chordProgressions, timeStamps, chords: sepChords };
}

function changeChordStyles(e) {
  const isEdit = e.$el.hasClass('chordEdit');
  const allSlides = e.slides;
  allSlides[e.activeIndex].style.backgroundColor = "#141414";
  // Reset all slides opacity to 0.4 and
  // set backgrounds for slides on the sides
  for (let i = 0; i < allSlides.length; i++) {
    const slide = allSlides[i];
    slide.style.opacity = isEdit?1.0:0.4;
    if (i < e.activeIndex) slide.style.backgroundColor = "#9B46D7";
    else if (i > e.activeIndex) slide.style.backgroundColor = "#141414";
    else {
      // Set active slide opacity to 1
      slide.style.opacity = 1;
    }
  }
}

function getImageColorInfo(imageEl) {
  const DEFAULT_RGB = { r: 20, g: 20, b: 20 }; // Default to main theme color
  const rgb = { r: 0, g: 0, b: 0 };
  const canvas = document.createElement("canvas");
  const context = canvas?.getContext("2d");
  if (!context || !imageEl) return DEFAULT_RGB;
  canvas.width = imageEl.width;
  canvas.height = imageEl.height;
  
  context.drawImage(imageEl, 0, 0);
  
  let imageData;
  imageEl.crossOrigin = 'anonymous';
  try {
    imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  } catch(err) {
    return DEFAULT_RGB;
  }

  const dataLength = imageData.data.length;
  const BLOCK_SIZE = 5;
  let i = -4;
  let steps = 0;
  while ((i += BLOCK_SIZE * 4) < dataLength) {
    rgb.r += imageData.data[i];
    rgb.g += imageData.data[i + 1];
    rgb.b += imageData.data[i + 2];
    steps++;
  }

  rgb.r = Math.floor(rgb.r/steps);
  rgb.g = Math.floor(rgb.g/steps);
  rgb.b = Math.floor(rgb.b/steps);

  const secondary = { ...rgb };
  rgb.r += 20;
  rgb.g += 20;
  rgb.b += 29;
  if (rgb.r > 255) rgb.r = 255;
  if (rgb.g > 255) rgb.g = 255;
  if (rgb.b > 255) rgb.b = 255;
  const primary = { ...rgb };

  return { primary, secondary };
}

function getRandomColor() {
  const hexChars = '0123456789ABCDEF';
  let color = '';
  for (let i = 0; i < 6; i++) {
    color += hexChars[Math.floor(Math.random() * 16)];
  }
  return color;
}

const sharpMap = new Map([
  ["C", 0],
  ["Am", 0],
  ["G", 1],
  ["Em", 1],
  ["D", 2],
  ["Bm", 2],
  ["A", 3],
  ["F♯m", 3],
  ["E", 4],
  ["C♯m", 4],
  ["B", 5],
  ["G♯m", 5],
  ["F♯", 6],
  ["D♯m", 6],
  ["C♯", 7],
  ["A♯m", 7],
]);
const flatMap = new Map([
  ["F", 1],
  ["Dm", 1],
  ["B♭", 2],
  ["Gm", 2],
  ["E♭", 3],
  ["Cm", 3],
  ["A♭", 4],
  ["Fm", 4],
  ["D♭", 5],
  ["B♭m", 5],
  ["G♭", 6],
  ["E♭m", 6],
  ["C♭", 7],
  ["A♭m", 7],
]);
const orderedNotes = "ABCDEFG";
function getComponentPitches(chord) { // As per https://en.wikipedia.org/wiki/Key_signature#Major_scale_structure
  if (chord == null) return;
  const isSharpChords = sharpMap.has(chord);
  const isFlatChords = flatMap.has(chord);
  if (!isSharpChords && !isFlatChords) return;

  let commonNotesCount = sharpMap.get(chord) || flatMap.get(chord) || 0;
  let commonNotes = '';
  if (isSharpChords) {
    commonNotes = "FCGDAEB".substring(0, commonNotesCount);
  }
  else if (isFlatChords) {
    commonNotes = "BEADGCF".substring(0, commonNotesCount);
  }
  const rootIndex = orderedNotes.indexOf(chord[0]);
  const componentPitches = (orderedNotes.substring(rootIndex) + orderedNotes.substring(0, rootIndex))
    .split('')
    .map((key) => {
      if (commonNotes.includes(key)) {
        if (isSharpChords) {
          return key + '♯';
        }
        else if (isFlatChords) {
          return key + '♭';
        }
      }
      return key;
    });
  const sharpOrFlat = chord.includes('♯') ? '♯' : chord.includes('♭') ? '♭' : '';
  if (!componentPitches[0].includes('♯') && !componentPitches[0].includes('♭')) {
    componentPitches[0] += sharpOrFlat;
  }
  return { componentPitches, commonNotesCount, isSharpChords };
}

const scale = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
const normalizeMap = new Map([
  ["Cb", "B"],
  ["Db", "C#"],
  ["Eb", "D#"],
  ["Fb", "E"],
  ["Gb", "F#"],
  ["Ab", "G#"],
  ["Bb", "A#"],
  ["E#", "F"],
  ["B#", "C"]
]);
function transposeChord(chord, amount) {
  const isNormalChar = chord.includes('#') || chord.includes('b');
  chord = chord
    .replace('♯', '#')
    .replace('♭', 'b')
    .replace(/[CDEFGAB](b|#)?/g, (match) => {
      const i = (scale.indexOf(normalizeMap.get(match) || match) + amount) % scale.length;
      return scale[i < 0 ? i + scale.length : i];
    });
  if (isNormalChar) {
    return chord;
  } else {
    return chord.replace('#', '♯').replace('b', '♭');
  }
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function getColorBrightness(r, g, b) {
  return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
}

export {
  mediaURL,
  getAccessToken,
  getAccessConfig,
  updateTokenChecker,
  getArtistsStr,
  formatDuration,
  DEFAULT_AVATAR,
  getDefaultAvatar,
  fillUserFields,
  makeGrid,
  setDarkTheme,
  setLightTheme,
  getTheme,
  makeNumberSmall,
  formatNumber,
  Viewers,
  Stars,
  getChordData,
  changeChordStyles,
  getImageColorInfo,
  getRandomColor,
  getComponentPitches,
  transposeChord,
  hexToRgb,
  getColorBrightness,
};
