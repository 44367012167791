import { useHistory } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";

import { getAccessConfig, getAccessToken } from "../../utils";
import styles from "./BookmarkButton.module.scss";


export default function BookmarkButton({ className, song, user }) {
  const history = useHistory();
	const [bookmarked, setBookmarked] = useState(false);
  useEffect(() => {
    if (user?.favorite_songs.length > 0 && song?.id) {
      setBookmarked(user.favorite_songs.includes(song.id));
    }
  }, [user, song])
  async function toggleBookmark() {
    if (!getAccessToken()) {
      history.push("/login");
      return;
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/songs/favorite/toggle/`;
      await axios.post(
        url,
        { song: song.id, favorite: !bookmarked },
        getAccessConfig()
      );
      setBookmarked((prev) => !prev);
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      console.log(error);
    }
  };
  
  return (
    <button
      style={{ opacity: bookmarked ? 1 : 0.2 }}
      className={`${styles.bookmarkButton} ${className}`}
      onClick={toggleBookmark}
    >
      <img
        width="25"
        src="/icons/bookmark-song-unfill.svg"
        alt="Bookmark"
      />
    </button>
  );
}