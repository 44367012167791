import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { handleStateCode } from './socialLogin/SocialLogin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';


function OAuth2Redirect({provider}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const state = searchParams.get('state');
    const code = searchParams.get('code');

    useEffect(()=>{

      handleStateCode(provider, state, code, dispatch, history);

      // window.opener.postMessage(
      //   { state, code },
      //   window.opener.location
      // );

    }, [state, code]);

    return (<></>)
}

export default OAuth2Redirect;
