import axios from "axios";
import {
  CREATE_ARTIST_SUBSCRIPTION_START,
  CREATE_ARTIST_SUBSCRIPTION_SUCCESS,
  CREATE_ARTIST_SUBSCRIPTION_FAIL,
  CREATE_CREATOR_SUBSCRIPTION_START,
  CREATE_CREATOR_SUBSCRIPTION_SUCCESS,
  CREATE_CREATOR_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_LIST_START,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAIL,
  REMOVE_ARTIST_SUBSCRIPTION_START,
  REMOVE_ARTIST_SUBSCRIPTION_SUCCESS,
  REMOVE_ARTIST_SUBSCRIPTION_FAIL,
  REMOVE_CREATOR_SUBSCRIPTION_START,
  REMOVE_CREATOR_SUBSCRIPTION_SUCCESS,
  REMOVE_CREATOR_SUBSCRIPTION_FAIL,
  GET_SUBSCRIBED_ITEMS_START,
  GET_SUBSCRIBED_ITEMS_SUCCESS,
  GET_SUBSCRIBED_ITEMS_FAIL,
  UPDATE_LAST_NOTIFIED_START,
  UPDATE_LAST_NOTIFIED_SUCCESS,
  UPDATE_LAST_NOTIFIED_FAIL,
} from "./ActionType";

export const cretaeArtistSubscription =
  (config, formData) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_ARTIST_SUBSCRIPTION_START });

      await axios
        .post(
          process.env.REACT_APP_BACKEND_URL +
            `/user-subscription/subscribe-artist/`,
          formData,
          config
        )
        .then((res) => {
          dispatch({
            type: CREATE_ARTIST_SUBSCRIPTION_SUCCESS,
            data: res.data,
          });
        });
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      dispatch({
        type: CREATE_ARTIST_SUBSCRIPTION_FAIL,
        data: error,
      });
    }
  };
export const removeArtistSubscription =
  (config, formData) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_ARTIST_SUBSCRIPTION_START });

      await axios
        .post(
          process.env.REACT_APP_BACKEND_URL +
            `/user-subscription/unsubscribe-artist/`,
          formData,
          config
        )
        .then((res) => {
          dispatch({
            type: REMOVE_ARTIST_SUBSCRIPTION_SUCCESS,
            data: res.data,
          });
        });
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      dispatch({
        type: REMOVE_ARTIST_SUBSCRIPTION_FAIL,
        data: error,
      });
    }
  };
export const getSubscriptionList = (config) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBSCRIPTION_LIST_START });

    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL + `/user-subscription/get-list/`,
        config
      )
      .then((res) => {
        dispatch({
          type: GET_SUBSCRIPTION_LIST_SUCCESS,
          data: res.data,
        });
      });
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    dispatch({
      type: GET_SUBSCRIPTION_LIST_FAIL,
      data: error,
    });
  }
};
export const getSubscribedItems = (config) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBSCRIBED_ITEMS_START });

    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL + `/user-subscription/get-items/`,
        config
      )
      .then((res) => {
        dispatch({
          type: GET_SUBSCRIBED_ITEMS_SUCCESS,
          data: res.data,
        });
      });
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    dispatch({
      type: GET_SUBSCRIBED_ITEMS_FAIL,
      data: error,
    });
  }
};
export const cretaeCreatorSubscription =
  (config, formData) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_CREATOR_SUBSCRIPTION_START });

      await axios
        .post(
          process.env.REACT_APP_BACKEND_URL +
            `/user-subscription/subscribe-creator/`,
          formData,
          config
        )
        .then((res) => {
          dispatch({
            type: CREATE_CREATOR_SUBSCRIPTION_SUCCESS,
            data: res.data,
          });
        });
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      dispatch({
        type: CREATE_CREATOR_SUBSCRIPTION_FAIL,
        data: error,
      });
    }
  };
export const removeCreatorSubscription =
  (config, formData) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_CREATOR_SUBSCRIPTION_START });

      await axios
        .post(
          process.env.REACT_APP_BACKEND_URL +
            `/user-subscription/unsubscribe-creator/`,
          formData,
          config
        )
        .then((res) => {
          dispatch({
            type: REMOVE_CREATOR_SUBSCRIPTION_SUCCESS,
            data: res.data,
          });
        });
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      dispatch({
        type: REMOVE_CREATOR_SUBSCRIPTION_FAIL,
        data: error,
      });
    }
  };

export const updateLastNotified = (config, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LAST_NOTIFIED_START });

    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          `/user-subscription/update-last-notified/`,
        formData,
        config
      )
      .then((res) => {
        dispatch({
          type: UPDATE_LAST_NOTIFIED_SUCCESS,
          data: res.data,
        });
      });
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    dispatch({
      type: UPDATE_LAST_NOTIFIED_FAIL,
      data: error,
    });
  }
};
