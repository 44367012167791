import React, { } from "react";
import ChordSwiper from "../chordSwiper/ChordSwiper";
import DetectChordProgressions from "../detectChordProgressions/DetectChordProgressions";


export default function ChordProgressions({ chordData, currentTime, videoDuration, setPlaybackPosition, isMobile, isLightMode, isPortrait, seekToChord, isEditMode }) {
  return (
    chordData
      ? 
      <ChordSwiper
        currentTime={currentTime}
        videoDuration={videoDuration}
        setPlaybackPosition={setPlaybackPosition}
        chordData={chordData}
        isMobile={isMobile}
        isPortrait={isPortrait}
        seekToChord={seekToChord}
        isEditMode={isEditMode}
      />
      :
      <DetectChordProgressions />
  );
}