import axios from 'axios'
import { getAccessToken, updateTokenChecker } from '../../utils';
import {REGISTRATION_START,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAIL,

    START_EMAIL_VARIFICATION, 
    EMAIL_VARIFICATION_SUCCESS, 
    EMAIL_VARIFICATION_FAIL, 

    AUTH_START, 
    AUTH_SUCCESS, 
    AUTH_FAIL, 

    FORGET_PASSWORD_REQUEST_START, 
    FORGET_PASSWORD_REQUEST_SUCCESS, 
    FORGET_PASSWORD_REQUEST_FAIL, 

    FORGET_PASSWORD_START, 
    FORGET_PASSWORD_SUCCESS, 
    FORGET_PASSWORD_FAIL, 
    
    CHANGE_PASSWORD_START, 
    CHANGE_PASSWORD_SUCCESSS, 
    CHANGE_PASSWORD_ERROR,
    SET_MUSIC,
  } from './ActionType'

    import {getUserInfo} from './UserAction'

    const config = {
        headers: {
          "Content-Type": "application/json",
        //   Authorization: "Bearer " + res.data.access,
        },
      };

export const onLogin = (dispatch, history, authData, isRefresh = false, isReloadAfterRefresh = false) => {

  localStorage.setItem('access_token', authData.access);

  if ('is_superuser' in authData) {
    localStorage.setItem('is_superuser', authData.is_superuser);
  }

  const claims = JSON.parse(Buffer.from(authData.access.split('.')[1], 'base64'));
  const now = (new Date()).getTime()
  const expire_at = Math.floor(now + (claims.exp * 1000 - now) * 0.9);

  localStorage.setItem('access_token_exp', expire_at);

  if ('refresh' in authData) {
    localStorage.setItem('refresh_token', authData.refresh);
    const refresh_claims = JSON.parse(Buffer.from(authData.refresh.split('.')[1], 'base64'));
    const refresh_expire_at = Math.floor(now + (refresh_claims.exp * 1000 - now) * 0.9);

    localStorage.setItem('refresh_token_exp', refresh_expire_at);
  }

  if (isReloadAfterRefresh) {
    document.location.reload();
    return;
  }

  updateTokenChecker(dispatch)

  if (!isRefresh) {
    dispatch({
      type:AUTH_SUCCESS,
      data : authData
    })
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAccessToken(),
    },
  };
  dispatch(getUserInfo(config))
  dispatch({type: SET_MUSIC, data: {activeModal: ''}})

  if (!isRefresh) {
    history.push('/');
  }
}

export const login = (fromData, history) => async (dispatch) => {
    try {
        dispatch({type:AUTH_START})
      await axios.post(process.env.REACT_APP_BACKEND_URL+"/api/token/", fromData, config).then((res) => {
          console.log(res.data.access, "userInfos");
          onLogin(dispatch, history, res.data);
        })
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      localStorage.removeItem('access_token')
      localStorage.removeItem('access_token_exp')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('refresh_token_exp')
      dispatch({
        type:AUTH_FAIL,
        data:error
      })
    }

};

export const refresh = (fromData, isReloadAfterRefresh = false) => async (dispatch) => {
  try {
    dispatch({type:AUTH_START})
    await axios.post(process.env.REACT_APP_BACKEND_URL+"/api/token/refresh/", fromData, config).then((res) => {
      onLogin(dispatch, null, res.data, true, isReloadAfterRefresh);
    })
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('refresh_token_exp')
    dispatch({
      type:AUTH_FAIL,
      data:error
    })
    updateTokenChecker(dispatch)
  }
};

export const Registration = (fromData) => async (dispatch) => {
    try {
      dispatch({type:REGISTRATION_START})
      await axios.post(process.env.REACT_APP_BACKEND_URL+"/auth/users/",fromData, config).then((res) => {
          
          dispatch({
            type: REGISTRATION_SUCCESS,
            data:res.data
          });
        
        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"

      dispatch({
        type:REGISTRATION_FAIL,
        data:error
      })
    }

}

export const UserActivation = (formData) => async (dispatch) => {
    try {
      
      dispatch({
        type:START_EMAIL_VARIFICATION
      })
      await axios.post(process.env.REACT_APP_BACKEND_URL+"/auth/users/activation/", formData, config).then((res) => {   
      dispatch({
            type: EMAIL_VARIFICATION_SUCCESS
          })
        
        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:EMAIL_VARIFICATION_FAIL,
        data:error
      })
    }

}

export const changePassord = (formData, config) => async (dispatch) => {
    try {
      dispatch({
        type:CHANGE_PASSWORD_START
      })
  
      await axios.post(process.env.REACT_APP_BACKEND_URL+"/auth/users/set_password/", formData, config).then((res) => {
          dispatch({
            type: CHANGE_PASSWORD_SUCCESSS,
          });
        
        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:CHANGE_PASSWORD_ERROR,
        data:error
      })
    }

}



export const RequestResetPassword = (email) => async (dispatch) => {
    try {
      dispatch({type:FORGET_PASSWORD_REQUEST_START})
      await axios.post(process.env.REACT_APP_BACKEND_URL+"/api/password_reset/", {email}, config).then((res) => {
          
          dispatch({
            type: FORGET_PASSWORD_REQUEST_SUCCESS,
          });
        
        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:FORGET_PASSWORD_REQUEST_FAIL,
        data:error
      })
    }

}

export const ResetPasswordConfirms = (formDate) => async (dispatch) => {
    try {
      dispatch({type:FORGET_PASSWORD_START})

      await axios.post(process.env.REACT_APP_BACKEND_URL+"/api/password_reset/confirm/", formDate, config).then((res) => {
          dispatch({
            type: FORGET_PASSWORD_SUCCESS
          });
        
        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(error);
      dispatch({
        type:FORGET_PASSWORD_FAIL,
        data:error
      })
    }

}

      

