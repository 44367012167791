import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {makeGrid} from "../../utils";
import SongItem from "../songItem/SongItem";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
// import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import {EffectCoverflow, Navigation} from "swiper";
import {getSingeleSong} from "../../store/action/MusicAction";

import styles from "../../container/Slider.module.scss";
import {TutorialSlider} from "../tutorialList/TutorialList";
import FadeoutDiv from "../fadeoutDiv/FadeoutDiv";
import useWindowSize from "../../hooks/useWindowSize";
import SongCard from "../songCard/SongCard";

function SongList({items, requestSong, getSongStatus}) {
  const windowSize = useWindowSize();

  return (
    <>
      {items &&
        makeGrid(items, windowSize.width > 1500 ? 6 : 4).map((row, ridx) => (
          <div key={ridx} style={{ margin: "0 25px" }} className="top-songs">
            <div style={{ justifyContent: "space-around", gap: "10px", flexWrap: "nowrap" }} className="row g-0">
              {row.map((song, index) => (
                <div
                  key={song ? song.id : `null${index}`}
                  className={`col-lg-${windowSize.width > 1500 ? 2 : 3} col-md-3 col-6`}
                >
                  {song &&
                    <SongCard
                      song={song}
                      requestSong={(!song.slug || !song.ch_tutorial_count) ? requestSong : null}
                      getSongStatus={(!song.slug || !song.ch_tutorial_count) ? getSongStatus : null}
                    />
                  }
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
}

function SongSlider({items, requestSong, getSongStatus, loop = true, fadeout = true}) {
  const {getSingleSongSuccess} = useSelector((state) => state.music);
  const {isLightMode} = useSelector((state) => state.toggle);

  const dispatch = useDispatch();
  const [songId, setSongId] = useState(null);
  const [{song, tutorials}, setPageData] = useState({});

  // scroll to the tutorials when song is clicked
  const tutorialsRef = useRef(null);
  const executeScroll = () =>
    tutorialsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  useEffect(() => {
    if (tutorials) executeScroll();
  }, [tutorials]);

  useEffect(() => {
    if (songId) {
      dispatch(getSingeleSong(songId));
    }
  }, [songId, dispatch]);

  useEffect(() => {
    if (getSingleSongSuccess && getSingleSongSuccess.song.id === songId) {
      setPageData(getSingleSongSuccess);
    }
  }, [getSingleSongSuccess, songId]);

  function changeOpacity(e) {
    const allSlides = e.slides;
    allSlides.forEach(slide => {
      const slidePiano = slide.querySelector(".song-img-piano");
      if (slidePiano) {
        slidePiano.style.opacity = 0.5;
      }
    });
    const activeSlide = allSlides[e.activeIndex];
    const activePianoImg = activeSlide?.querySelector(".song-img-piano");
    if (activePianoImg) {
      activePianoImg.style.opacity = 1;
    }
  }

  const swiperButtonPrevRef = useRef(null);
  const swiperButtonNextRef = useRef(null);

  const windowSize = useWindowSize();

  return (
    <div className={`top-songs ${styles.slider}`}>
      {items && items.length > 0 &&
        <Swiper
          grabCursor={false}
          spaceBetween={-50}
          centeredSlides={true}
          effect={"coverflow"}
          slidesPerView={1.5}
          coverflowEffect={{
            rotate: 0,
            stretch: -100,
            depth: 50,
            modifier: 1,
            slideShadows: false,
          }}
          onSlideChange={changeOpacity}
          loop={loop}
          breakpoints={{
            476: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1400: {
              slidesPerView: 6,
            },
            1920: {
              slidesPerView: 7,
            },
          }}
          navigation={{
            nextEl: swiperButtonPrevRef.current,
            prevEl: swiperButtonNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = swiperButtonPrevRef.current;
            swiper.params.navigation.nextEl = swiperButtonNextRef.current;
          }}
          modules={[
            // FreeMode,
            EffectCoverflow,
            // Pagination,
            Navigation
          ]}
        >
        {fadeout && <FadeoutDiv />}
          <div className={"swiper-wrapper"}>
            {items &&
              items.map((song, index) => (
                <SwiperSlide key={song ? song.id : `null${index}`}>
                  {song && (
                    <SongItem
                      onClick={(id) => {
                        setSongId(null);
                        if (id !== songId) {
                          setSongId(id);
                        }
                      }}
                      song={song}
                      requestSong={requestSong}
                      getSongStatus={getSongStatus}
                    />
                  )}
                </SwiperSlide>
              ))}
          </div>
          {windowSize.width >= 576 && <>
            <div ref={swiperButtonPrevRef} className="swiper-button-prev" style={{color: "#938999"}}/>
            <div ref={swiperButtonNextRef} className="swiper-button-next" style={{color: "#938999"}}/>
          </>}
        </Swiper>
      }
      {tutorials && (
        <div className="top-songs" ref={tutorialsRef}>
          <TutorialSlider items={tutorials}/>
        </div>
      )}
    </div>
  );
}

export default SongList;
export {SongSlider};
