import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom';
import { UPDATE_AUTH_STATUS } from '../../../store/action/ActionType';
import { login } from '../../../store/action/AuthAction';
import SocialLogin from '../../socialLogin/SocialLogin';
import MobilePagesFooter from '../../../component/mobilePagesFooter/MobilePagesFooter';
import TitleHelmet from '../../../component/titleHelmet/TitleHelmet';


const Login = () => {

  const dispatch = useDispatch()
  const history = useHistory();
  const [hidePassword, setHidePassword] = useState(true)

  const { authFail } = useSelector(state => state.auth)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    dispatch(login(formData, history));
  }

  useEffect(() => {
    return () => {
        dispatch({ type: UPDATE_AUTH_STATUS })
    };
  }, [dispatch]);

  return (
    <>
      <TitleHelmet title={`Songmode - Login`} />
      <h1>Log in</h1>
      <p className="text-center">Welcome back! Please enter your details.</p>
      <SocialLogin />
      <form onSubmit={handleSubmit}>
          <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
              <input type="email" value={email} onChange={(evt)=>setEmail(evt.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" required/>
              <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{authFail && authFail.data && authFail.data.email && authFail.data.email}</span>
          </div>
          <div className="mb-3 ">
              <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
              <div className="coustom-pass">
                  <input type={hidePassword?'password':'text'} value={password} onChange={(evt)=>setPassword(evt.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Enter password" required/>
                  <button type="button" onClick={()=>setHidePassword(!hidePassword)}><img src={`/img/${hidePassword?'show':'play'}.svg`} alt="img" className="img-fluid" /></button>
              </div>
              <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{authFail && authFail.data && authFail.data.detail}</span>
          <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{authFail && authFail.data && authFail.data.password && authFail.data.password}</span>
          </div>
          <div className="forgot text-end">
              <NavLink to="/reset-password">Forgot password</NavLink>
          </div>
          
          <button type="submit" className="btn">Login</button>
          <p>Not registered on SongMode yet? <NavLink to="/sign-up">Sign Up</NavLink></p>
      </form>
      <MobilePagesFooter />
    </>
  );
};

export default Login;