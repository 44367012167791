import axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Draggable from "react-draggable";
import YouTube from "react-youtube";
import { SET_MUSIC, SET_USER_INFO } from "../../store/action/ActionType";
import { getTutorial } from "../../store/action/MusicAction";
import {
  Viewers,
  getAccessConfig,
  getAccessToken,
  getImageColorInfo,
  getChordData,
  transposeChord,
} from "../../utils";
import VideoComments from "../videoComments/VideoComments";
import useWindowSize from "../../hooks/useWindowSize";
import Difficulty from "../../component/difficulty/Difficulty";
import MobileTutorialHeader from "../../component/mobileTutorialHeader/MobileTutorialHeader";
import styles from "./VideoTutorial.module.scss";
import MobileTutorialFooter from "../../component/mobileTutorialFooter/MobileTutorialFooter";
import RelatedTutorials from "../../component/relatedTutorials/RelatedTutorials";
import BlurredBackgroundImage from "../../component/blurredBackgroundImage/BlurredBackgroundImage";
import ChordProgressionsViewer from "../../component/chordProgressionsViewer/ChordProgressionsViewer";
import SongStatsGrid from "../../component/songStatsGrid/SongStatsGrid";
import ChordProgressionsList from "../../component/chordProgressionsList/ChordProgressionsList";
import TutorialSections from "../../component/tutorialSections/TutorialSections";

async function flushEducation(videoId, { time, score }, action) {
  try {
    time = time || 0;
    score = score || 0;
    const url = `${process.env.REACT_APP_BACKEND_URL}/tutorials/education/flush/`;
    const ret = await axios.post(
      url,
      { video: videoId, time, score, action },
      getAccessConfig()
    );
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(error);
    return false;
  }
}

function _getUnlockedPercent(totalTime) {
  const ret = Math.min((totalTime * 100) / 5 / 6000, 100.0);
  return ret;
}

class EducationData {
  videoId = "";

  isPlaying = false;
  isEducating = false;
  beforeSessionTime = 0;
  sessionStartedAt = 0;
  totalTime = 0;

  totalFlushed = 0;
  flushedAt = 0;

  onUnlockedPercentChange = null;

  constructor(videoId, timeSpent, onUnlockedPercentChange) {
    this.videoId = videoId;
    this.onUnlockedPercentChange = onUnlockedPercentChange;

    this.totalTime = timeSpent;
    this.totalFlushed = timeSpent;
    this.beforeSessionTime = timeSpent;
  }

  process(newIsPlaying, isVisible, forceFlush) {
    if (newIsPlaying === null) {
      newIsPlaying = this.isPlaying;
    } else {
      this.isPlaying = newIsPlaying;
    }
    const newIsEducating = newIsPlaying && isVisible;
    if (!this.isEducating && !newIsEducating) {
      this.flush(forceFlush);
      return;
    }

    if (this.isEducating) {
      this.totalTime =
        this.beforeSessionTime + (new Date().getTime() - this.sessionStartedAt);
      this.onUnlockedPercentChange &&
        this.onUnlockedPercentChange(_getUnlockedPercent(this.totalTime));
    } else {
      //start Playing
      this.sessionStartedAt = new Date().getTime();
      if (!this.flushedAt) {
        //first time set flushed to start of the session (flushed time = 0)
        this.flushedAt = this.sessionStartedAt;
      }
    }

    if (!newIsEducating) {
      this.beforeSessionTime = this.totalTime;
    }
    this.isEducating = newIsEducating;

    this.flush(forceFlush);
  }

  flush(force) {
    if (!this.totalTime) {
      return;
    }
    if (this.totalFlushed === this.totalTime) {
      this.flushedAt = new Date().getTime();
      return;
    }
    if (
      force ||
      this.totalTime - this.totalFlushed > 15000 ||
      new Date().getTime() - this.flushedAt > 45000
    ) {
      getAccessToken() &&
        flushEducation(this.videoId, { time: this.totalTime }).then((ret) => {
          if (ret.education.time_spent > this.totalFlushed) {
            this.totalFlushed = ret.education.time_spent;
            this.flushedAt = new Date().getTime();
            // TODO: update totalTime if received time is greater
          }
        });
    }
  }
}

function getChordsForDescription(tutorial) {
    if (!tutorial || !tutorial.unique_progressions || !tutorial.unique_progressions.length) {
      return '';
    }

    if (tutorial.unique_progressions[0].length > 40) {
      return '';
    }

    return ` Chord Progession "${tutorial.unique_progressions[0].replaceAll(',', ', ')}".`;
}

function getHooksForDescription(tutorial) {
    if (!tutorial || !tutorial.sections || !tutorial.sections.length) {
      return '';
    }
    const hooks = Math.max(...tutorial.sections.map(xx => xx.group));
    return ` "${hooks}" Hooks.`;
}

function VideoTutorial() {
  const { slug } = useParams();
  const id = slug ? +slug.split("-").pop() : "";

  const { getTutorialSuccess } = useSelector((state) => state.music);
  const song = getTutorialSuccess && getTutorialSuccess.song;
  const tutorial = getTutorialSuccess && getTutorialSuccess.tutorial;
  const tutorials = getTutorialSuccess && getTutorialSuccess.tutorials;

  const dispatch = useDispatch();
  const history = useHistory();
  const { isLightMode } = useSelector((state) => state.toggle);

  useEffect(() => {
    if (getTutorialSuccess && getTutorialSuccess.tutorial.id === id) {
      setPageData(getTutorialSuccess);
      const timeSpent =
        (getTutorialSuccess.education &&
          getTutorialSuccess.education.time_spent) ||
        0;
      setUnlockedPercent(_getUnlockedPercent(timeSpent));

      const score =
        (getTutorialSuccess.education && getTutorialSuccess.education.score) ||
        0;
      setEarnedPoints(score);
    } else {
      setPageData(null);
      dispatch(getTutorial(id, getAccessConfig()));
    }
  }, [id, getTutorialSuccess, dispatch]);

  const [hideHeaderId, setHideHeaderId] = useState(0);

  const [progress, setProgress] = useState([0.0, 0.0]);
  const [[educationData, player, avPlaybackRates], setPlayerData] = useState([
    null,
    null,
    [],
  ]);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const isPausedCommented = false;
  //const [isPaused, setIsPaused] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [unlockedPercent, setUnlockedPercent] = useState(0);

  const [earnedPoints, setEarnedPoints] = useState(0);

  useEffect(() => {
    const checkEducationTime = () => {
      educationData && educationData.process(null, !document.hidden, false);
    };

    document.addEventListener("visibilitychange", checkEducationTime, false);
    window.addEventListener("focus", checkEducationTime, false);
    window.addEventListener("blur", checkEducationTime, false);

    let watchTimer = null;
    const watcher = () => {
      checkEducationTime();
      watchTimer = setTimeout(watcher, 1000);
    };
    watcher();

    let progressWatcher = setInterval(() => {
      if (player) {
        setProgress([player.getCurrentTime(), player.getDuration()]);
      }
    }, 100);

    return () => {
      progressWatcher && clearInterval(progressWatcher);
      progressWatcher = null;

      window.removeEventListener("blur", checkEducationTime, false);
      window.removeEventListener("focus", checkEducationTime, false);
      document.removeEventListener(
        "visibilitychange",
        checkEducationTime,
        false
      );
      watchTimer && clearTimeout(watchTimer);
      watchTimer = null;
    };
  }, [educationData, player]);

  const onYoutubeReady = (evt) => {
    //To get player instance before play
    if (!tutorial || player === evt.target) {
      return;
    }
    const nplayer = evt.target;
    const videoData = nplayer.getVideoData();
    const videoId = videoData.video_id;
    if (videoId === tutorial.videoId) {
      const pbrates = nplayer.getAvailablePlaybackRates();
      setPlayerData([educationData, nplayer, pbrates]);
      setPlaybackRate(nplayer.getPlaybackRate());
    }
  };

  const videoStateChange = (evt) => {
    const stateCode = evt.data;
    const videoId = evt.target.getVideoData().video_id;

    let ed = educationData;

    if (ed && ed.videoId !== videoId) {
      ed.process(false, !document.hidden, true);
    }
    if (!ed || ed.videoId !== videoId) {
      const oldEdData = getTutorialSuccess.education;
      const timeSpent =
        (getTutorialSuccess.tutorial.videoId === videoId &&
          oldEdData &&
          oldEdData.time_spent) ||
        0;
      ed = new EducationData(videoId, timeSpent, (pp) =>
        setUnlockedPercent(pp)
      );
    }

    // -1 (unstarted)
    // 0 (ended)
    // 1 (playing)
    // 2 (paused)
    // 3 (buffering)
    // 5 (video cued).
    const isVideoPlaying = stateCode === 1;
    ed.process(isVideoPlaying, !document.hidden, false);

    const pl = tutorial && videoId === tutorial.videoId ? evt.target : null;

    if (pl !== player || ed !== educationData) {
      setPlayerData([ed, pl, avPlaybackRates]);
    }

    if (hideHeaderId !== tutorial.id && isVideoPlaying) {
      setHideHeaderId(tutorial.id);
    }

    //const isPaused = stateCode === 2;
    //setIsPaused(isPaused);
  };

  const setPlaybackPosition = (sec) => {
    if (player) {
      player.seekTo(sec, true);
    }
  };

  const toggleLearned = async () => {
    if (!getAccessToken()) {
      history.push("/login");
      return;
    }
    const action = earnedPoints > 0 ? "subtract" : "add";
    const ret = await flushEducation(tutorial.videoId, {
      score: tutorial.score,
    }, action);
    setEarnedPoints(ret.education.score);
    dispatch({
      type: SET_USER_INFO,
      data: { total_score: ret.user.total_score },
    });
  };

  const onPlaybackRateChange = (pbrate) => {
    if (player) {
      setPlaybackRate(pbrate);
      player.setPlaybackRate(pbrate);
    }
  };

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const isPortrait = windowSize.width < windowSize.height;
  const imageColorInfo = useRef({ primary: null, secondary: null });
  function handleSongImageMount(node) {
    if (node == null) return;
    const { primary, secondary } = getImageColorInfo(node);
    imageColorInfo.current.primary = `rgb(${primary?.r}, ${primary?.g}, ${primary?.b})`;
    imageColorInfo.current.secondary = `rgb(${secondary?.r}, ${secondary?.g}, ${secondary?.b})`;
  }

  const [commentSectionOpen, setCommentSectionOpen] = useState(false);
  const [relatedTutorialsOpen, setRelatedTutorialsOpen] = useState(false);

  const videoWrapperRef = useRef(null);
  const videoBottomOffset = videoWrapperRef.current?.offsetTop + videoWrapperRef.current?.offsetHeight;

  const endOfChordProgRef = useRef(null);
  useEffect(() => {
    if (isMobile && !isPortrait && endOfChordProgRef.current) {
      endOfChordProgRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endOfChordProgRef.current]);

  const { comments } = useSelector((state) => state.music);

  async function toggleUnfinished(tutorialId, isUnfinished) {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/tutorials/unfinished/toggle/`;
      await axios.post(
        url,
        { tutorial: tutorialId, unfinished: !!isUnfinished },
        getAccessConfig()
      );
      return true;
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      console.log(error);
      console.log(err);
      return false;
    }
  }
  
  const toggleBookmark = async () => {
    if (!getAccessToken()) {
      history.push("/login");
      return;
    }
    const newUnfinished = !comments.unfinished;
    const success = await toggleUnfinished(tutorial.id, newUnfinished);
    success &&
      dispatch({
        type: SET_MUSIC,
        data: { comments: { ...comments, unfinished: newUnfinished } },
      });
  };

  const [selectedSection, setSelectedSection] = useState("info");
  const [showTooltip, setShowTooltip] = useState({ learn: false, bookmark: false });
  const musicVideoRef = useRef(null);

  function onVideoPlay(isTutorialVideo) {
    if (isTutorialVideo) {
      musicVideoRef.current?.pauseVideo();
    } else {
      player.pauseVideo();
    }
  }

  const [loopLowValue, setLoopLowValue] = useState(0);
	const [loopHighValue, setLoopHighValue] = useState(progress[1]);

  const [isDragging, setIsDragging] = useState(false);
  const [tutorialButtonsVisible, setTutorialButtonsVisible] = useState(false);
  function showTutorialButtons() {
    if (!isDragging) {
      setTutorialButtonsVisible((prev) => !prev)
    }
  }

  const [tutUniqueProgs, setTutUniqueProgs] = useState();
  const [tutorialInfoVisible, setTutorialInfoVisible,] = useState(false);

  const [tutorialKey, setTutorialKey] = useState();
  // Using song key temporarily while we get tutorial key
  useEffect(() => {
    if (tutorial?.videoSongKey) {
      setTutorialKey(tutorial?.videoSongKey);
    }
  }, [tutorial?.videoSongKey])

  function changeChordProgsKey(chordProgressions, direction) {
    const newChordProgressions = [];
    for (let i = 0; i < chordProgressions.length; i++) {
      const newChordProgression = chordProgressions[i].map((chord) => {
        return transposeChord(chord, direction === "up" ? 1 : -1);
      });
      newChordProgressions.push(newChordProgression);
    }
    return newChordProgressions;
  }
  
  const [chordData, setChordData] = useState();
  function changeKey(direction) {
		setTutorialKey((prevKey) => transposeChord(prevKey, direction === "up" ? 1 : -1));
    if (chordData?.chordProgressions) {
      setChordData((prevData) => ({
        ...prevData,
        chordProgressions: changeChordProgsKey(prevData.chordProgressions, direction),
      }));
    }
    if (tutUniqueProgs) {
      setTutUniqueProgs(changeChordProgsKey(tutUniqueProgs, direction));
    }
	}

  const videoDuration = progress[1];
  useEffect(() => {
    if (tutorial?.chords?.progressions && videoDuration != null) {
      setChordData(getChordData(tutorial.chords.progressions, videoDuration));
    }
    if (tutorial?.unique_progressions) {
      setTutUniqueProgs(tutorial.unique_progressions.map((prog) => prog.split(',')));
    }
  }, [tutorial?.chords?.progressions, tutorial?.unique_progressions, videoDuration]);

  const chordForDescription = getChordsForDescription(tutorial);
  const hooksForDescription = getHooksForDescription(tutorial);

  return (
    <>
      {tutorial && <Helmet>
        <title>{`Chords, Song Key, Bpm, Hooks for "${tutorial.song.name}" by ${tutorial.song.artists[0].name} - Songmode`}</title>
        <meta
          name="description"
          content={`"${tutorial.song.name}" by ${tutorial.song.artists[0].name}. in the key of "${tutorial.videoSongKey || tutorial.song.songKey}". "${tutorial.song.tempo}" BPM.${chordForDescription}${hooksForDescription}`}
        />
      </Helmet>}
      <div className={`Video-Tutorial ${styles.tutorialPage} ${isLightMode ? styles.lightMode : ''}`}>
        {!!pageData && (
          <>
          {isMobile && <>
            <BlurredBackgroundImage imageURL={song.artwork_medium} />
            {isPortrait && <>
              <MobileTutorialHeader
                tutorial={tutorial}
                song={song}
                secondaryColor={imageColorInfo.current.secondary}
                showHeader={hideHeaderId!==tutorial.id}
              />
              <MobileTutorialFooter
                tutorial={tutorial}
                earnedPoints={earnedPoints}
                unlockedPercent={unlockedPercent}
                unlock={toggleLearned}
              />
              {/* Floating buttons */}
              <div className={styles.floatingButtonsContainer}>
                <button
                  onClick={() => setTutorialInfoVisible(prev => !prev)}
                  className={`${styles.floatingButton} ${styles.tutorialInfoButton}`}
                />
                {tutorials?.length > 0 && <button onClick={() => setRelatedTutorialsOpen(true)} className={`${styles.floatingButton} ${styles.listButton}`} />}
                <button onClick={() => setCommentSectionOpen(true)} className={`${styles.floatingButton} ${styles.commentsButton}`} />
                <button
                  className={`${styles.floatingButton} ${styles.bookmarkButton}`}
                  onClick={toggleBookmark}
                >
                  <img
                    src={`/icons/bookmark-song${comments.unfinished ? '' : "-unfill"}.svg`}
                    alt="Bookmark song"
                  />
                </button>
              </div>
              {/* Tutorial info section */}
              <section
                style={{
                  top: tutorialInfoVisible ? `${videoBottomOffset}px` : "100%",
                  height: `calc(100vh - ${videoBottomOffset + 50}px)`
                }}
                className={styles.popoutMenu}
              >
                <div className={styles.menuHeader}>
                  <span className={styles.menuLabel}>Tutorial info</span>
                  <button onClick={() => setTutorialInfoVisible(false)} className={styles.closeMenuButton} />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <SongStatsGrid 
                    song={song}
                    tutUniqueProgsCount={tutUniqueProgs?.length}
                    isRootNotes={tutorial?.chords?.type === 'root_note'}
                    setUniqueProgsVisible={setTutorialInfoVisible}
                    tutorialKey={tutorialKey}
                    changeKey={changeKey}
                    headerLevel={3}
                  />
                  {tutUniqueProgs && <>
                    <p style={{ margin: 0, width: "100%", textAlign: "center", padding: 10 }} className={styles.menuLabel}>Unique chord progressions:</p>
                    <ChordProgressionsList style={{ marginBottom: 10 }} chordProgStyle={{ borderColor: "#444" }} chordProgressions={tutUniqueProgs} />
                  </>}
                </div>
              </section>
              {/* Related tutorials section */}
              <section style={{ left: relatedTutorialsOpen ? 0 : "200%"}} className={styles.popoutMenu}>
                <div className={styles.menuHeader}>
                  <span className={styles.menuLabel}>Related Tutorials</span>
                  <button onClick={() => setRelatedTutorialsOpen(false)} className={styles.closeMenuButton} />
                </div>
                <RelatedTutorials
                  tutorials={tutorials}
                  song={song}
                  setRelatedTutorialsOpen={setRelatedTutorialsOpen}
                  isMobile={isMobile}
                />
              </section>
              {/* Comment section */}
              <section
                style={{
                  top: commentSectionOpen ? `${videoBottomOffset}px` : "100%",
                  height: `calc(100vh - ${videoBottomOffset + 50}px)`
                }}
                className={styles.popoutMenu}
              >
                <div className={styles.menuHeader}>
                  <span className={styles.menuLabel}>Comments</span>
                  <button onClick={() => setCommentSectionOpen(false)} className={styles.closeMenuButton} />
                </div>
                <VideoComments
                  tutorial={tutorial}
                  setLoopLowValue={setLoopLowValue}
                  setLoopHighValue={setLoopHighValue}
                  setPlaybackPosition={setPlaybackPosition}
                  min={0}
                  max={progress[1]}
                />
              </section>
            </>}
          </>}
          <div className="row">
            {/* single item start */}
            <div className="col-lg-8">
              <div className="video-tutorial-left pr-lg-3">
                <div ref={videoWrapperRef} className={`video_play ${styles.videoWrapper}`}>
                  {/* <iframe onStateChange={videoStateChange} src={`https://www.youtube.com/embed/BuTEvtokxug?&autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                  <YouTube
                    className={
                      isPausedCommented
                        ? `${styles.responsiveYoutube} ${styles.paused}`
                        : styles.responsiveYoutube
                    }
                    videoId={tutorial.videoId}
                    onReady={onYoutubeReady}
                    onStateChange={videoStateChange}
                    onPlaybackRateChange={(e) => setPlaybackRate(e.data)}
                    onPlay={() => onVideoPlay(true)}
                    opts={{
                      width: 640,
                      height: isPausedCommented ? 700 : 360,
                      playerVars: { rel: 0, modestbranding: 1, showinfo: 0 },
                    }}
                  />
                  {/* background shadow */}
                  <div
                    style={{boxShadow: isMobile ? `0 0 1000px 100px ${imageColorInfo.current.primary}` : ''}}
                    className={styles.backgroundShadow}
                  />
                </div>
                {!!progress[1] && <>
                  <TutorialSections
                    sections={tutorial?.sections}
                    currentTime={progress[0]}
                    tutorialDuration={progress[1]}
                    setPlaybackPosition={setPlaybackPosition}
                    artworkUrl={song.artwork_medium}

                    speed={playbackRate}
                    speedOptions={avPlaybackRates}
                    onSpeedChange={onPlaybackRateChange}
                  />
                  <ChordProgressionsViewer
                    tutorial={tutorial}
                    chordData={chordData}
                    currentTime={progress[0]}
                    videoDuration={progress[1]}
                    setPlaybackPosition={setPlaybackPosition}
                    isMobile={isMobile}
                    isPortrait={isPortrait}
                    isLightMode={isLightMode}
                  />
                </>}
                <div ref={endOfChordProgRef} />
              </div>
            </div>
            {/* single item end */}
            {/* single item start */}
            {!isMobile && <>
            <div className="col-lg-4">
              <div className="lock-right-item">
                <div className="Blinding-Lights">
                  {/* row start */}
                  <div className="row">
                    {/* single item start */}
                    <div style={{ position: "relative" }} className="col-12">
                      <div className={styles.sectionPickerButtonContainer}>
                        <button
                          onClick={() => setSelectedSection("info")}
                          className={selectedSection === "info" ? styles.activeSection : ''}
                        >
                          <span>Tutorial info</span>
                        </button>
                        <button
                          onClick={() => setSelectedSection("comments")}
                          className={selectedSection === "comments" ? styles.activeSection : ''}
                        >
                          <span>Comments</span>
                        </button>
                        {
                          tutorials?.length > 0 && (
                            <button
                              onClick={() => setSelectedSection("relatedTutorials")}
                              className={selectedSection === "relatedTutorials" ? styles.activeSection : ''}
                            >
                              <span>Related tutorials</span>
                            </button>
                          )
                        }
                      </div>
                      <div className={styles.sectionContainer} style={{paddingBottom:0}}>
                        {selectedSection === "info" && <>
                          <h1>{tutorial.pageHeader}</h1>
                          <div className="row">
                            <div ref={videoWrapperRef} className={`video_play ${styles.videoWrapper}`} style={{ margin: 0 }}>
                              <YouTube
                                className={
                                  isPausedCommented
                                    ? `${styles.responsiveYoutube} ${styles.paused}`
                                    : styles.responsiveYoutube
                                }
                                onReady={(e) => musicVideoRef.current = e.target}
                                onPlay={() => onVideoPlay(false)}
                                videoId={song.youtube_id}
                                opts={{
                                  width: 640,
                                  height: isPausedCommented ? 700 : 360,
                                  playerVars: { rel: 0, modestbranding: 1, showinfo: 0 },
                                }}
                              />
                            </div>
                            <div className="Blinding-left">
                              <div className="slider active" style={{paddingBottom: 0}}>
                                <span style={{ display: "flex", gap: 20, alignItems: "center", justifyContent: 'space-between' }} className="ex-a">
                                  <span className="title-item d-block" style={{paddingTop:10}}>
                                    <h2><NavLink
                                      to={`/songs/${song.slug}-${song.id}`}
                                      className="songstitle d-block"
                                    >
                                      {song.name}
                                    </NavLink></h2>
                                    <h3 className="singer-name d-block">
                                      {song.artists.map((aa, aaidx) => (
                                        <Fragment key={aa.id}>
                                          {aaidx > 0 && ", "}
                                          <NavLink to={`/artists/${aa.slug}`}>
                                            {aa.name}
                                          </NavLink>
                                        </Fragment>
                                      ))}
                                    </h3>
                                    <NavLink
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        whiteSpace: "nowrap",
                                      }}
                                      to={`/creators/${tutorial.channelSlug}`}
                                    >
                                      <img
                                        src={tutorial.channelArtworkSmall}
                                        alt="img"
                                        className="img-fluid profile-images"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          backgroundColor: "#2C1C37",
                                          borderRadius: "50%",
                                          marginRight: '5px',
                                        }}
                                        onError={({currentTarget}) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = '/img/user.svg';
                                        }}
                                      />
                                      {tutorial.channelName}
                                    </NavLink>
                                  </span>
                                  <span className="title-item d-block">
                                    <div className="viewers-holder" style={{marginBottom:0}}>
                                      <div>
                                        <div style={{marginTop:18, marginBottom: 19}}><Difficulty level={tutorial.level}/></div>
                                        {/* <div style={{textAlign: 'center'}}><div style={{display:'inline-block'}}><Viewers count={tutorial.views} /></div></div> */}
                                        <div style={{paddingRight: 12}}><Viewers count={tutorial.views} /></div>
                                      </div>
                                    </div>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <SongStatsGrid
                              song={song}
                              tutUniqueProgsCount={tutUniqueProgs?.length}
                              isRootNotes={tutorial?.chords?.type === 'root_note'}
                              setUniqueProgsVisible={setTutorialInfoVisible}
                              tutorialKey={tutorialKey}
                              changeKey={changeKey}
                              headerLevel={3}
                            />
                          </div>
                          {tutorialInfoVisible && <>
                            <p style={{ fontWeight: 100, letterSpacing: 0.5, textAlign: "center" }}>Unique chord progressions:</p>
                            <ChordProgressionsList chordProgressions={tutUniqueProgs} />
                          </>}
                        </>}
                        {selectedSection === "comments" && (
                          <VideoComments
                            tutorial={tutorial}
                            setLoopLowValue={setLoopLowValue}
                            setLoopHighValue={setLoopHighValue}
                            setPlaybackPosition={setPlaybackPosition}
                            min={0}
                            max={progress[1]}
                          />
                        )}
                        {selectedSection === "relatedTutorials" && (
                          <RelatedTutorials
                            tutorials={tutorials}
                            song={song}
                            setRelatedTutorialsOpen={setRelatedTutorialsOpen}
                            isMobile={isMobile}
                          />
                        )}
                      </div>
                    </div>
                    {/* single item end */}
                  </div>
                  {/* row end */}
                </div>
              </div>
            </div>
            </>}
            {/* single item end */}
          </div>
          <img
            ref={handleSongImageMount}
            src={song.artwork_medium}
            alt="img"
            style={{ display: "none" }}
          />
          </>
        )}
        {/* row end */}
        <Draggable
          onDrag={() => setIsDragging(true)}
          onStop={() => setTimeout(() => setIsDragging(false), 0)}
        >
          <div className={styles.tutorialButtonsContainer}>
            <button
              onClick={showTutorialButtons}
              onTouchEnd={showTutorialButtons}
              className={styles.primary}
              style={{ cursor: isDragging && "grabbing" }}
            >
              <img src="/img/song.svg" alt="Song" />
            </button>
            {tutorialButtonsVisible && <>
              <button
                onClick={toggleBookmark}
                onTouchEnd={toggleBookmark}
                onMouseEnter={() => setShowTooltip((prev) => ({ ...prev, bookmark: true }))}
                onMouseLeave={() => setShowTooltip((prev) => ({ ...prev, bookmark: false }))}
                className={`${styles.secondary} ${styles.bookmarkedDesktopButton}`}
              >
                <img src={`/icons/bookmark-song${comments.unfinished ? '' : "-unfill"}.svg`} alt="Bookmark song" />
              </button>
              <button
                onClick={toggleLearned}
                onTouchEnd={toggleLearned}
                onMouseEnter={() => setShowTooltip((prev) => ({ ...prev, learn: true }))}
                onMouseLeave={() => setShowTooltip((prev) => ({ ...prev, learn: false }))}
                className={`${styles.secondary} ${styles.learnedDesktopButton}`}
              >
                <img src={`/icons/circle-check${earnedPoints > 0 ? "-filled" : ''}.svg`} alt="Learn song" />
              </button>
            </>}
            {showTooltip.bookmark &&
              <span className={`${styles.tooltip} ${styles.bookmarkedTooltip}`}>Bookmark tutorial</span>
            }
            {showTooltip.learn &&
              <span className={`${styles.tooltip} ${styles.learnedTooltip}`}>Mark as learned</span>
            }
          </div>
        </Draggable>
      </div>
    </>
  );
}

export default VideoTutorial;
