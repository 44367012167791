import React from 'react';
import MidiModal from './MidiModal';


const ModalMessage = ({ header, children, onHide, isSuccess }) => {

  return (
    <MidiModal
      show={true}
      onHide={onHide}

      header={header}
    >
        <div style={{padding:'30px 50px 40px', fontSize: 15, color: isSuccess?'#FFC300':'#FF5733'}}>
          {children}
        </div>
    </MidiModal>
  );
};

export default ModalMessage