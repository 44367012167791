import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { getAccessConfig } from "../../utils";

import styles from "./Landing.module.scss";
import SongCard from "../../component/songCard/SongCard";
import TitleHelmet from "../../component/titleHelmet/TitleHelmet";


function Landing() {

  const [{songs, artists}, setData] = useState({ songs: [], artists: [] });

  useEffect(() => {
    async function getTopSongs() {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/songs/landing/`;
        const ret = await axios.get(url, {
          ...getAccessConfig(),
          params: {
            genre: null,
            time: null,
          },
        });
        setData({ songs: ret.data.songs, artists: ret.data.artists });
      } catch (err) {
        const error = err.response ? err.response : "networkError";
        console.log(err, error);
        return false;
      }
    }
    getTopSongs();
  }, []);

  return (
    <>
      <TitleHelmet title={`Songmode - Unlock the Piano Maestro in You!`} />

      <div className={styles.landing}>
        <div className={styles.inner}>
          <div className="row">
            <div className="col-lg-8">
            <div class="relative">
                
                <div className={styles.textBlock}>
                  <h4>Unlock the Piano Maestro in You!</h4>
                  <h1>Learn Thousands of Songs Effortlessly with Songmode</h1>
                  <Link className={styles.exploreBtn} to={`/`}>Explore Tutorials</Link>
                </div>
                
                {/* <div class="2xl:hidden block mt-[62px] mx-auto max-w-[90%]">
                  <img src="img/mobile.png" alt="img" class="m-auto shadow-boxshadow lg:rounded-[67.912px] rounded-[50px]"/>
                </div> */}
                
                <div className={styles.cards}>
                  {songs.map(song => (<div key={song.id} className={styles.cardWrapper}>
                    <SongCard song={song}/>
                  </div>))}
                </div>

                <div className={styles.artists}>
                  {artists.map(artist => (
                    <Link key={artist.id} to={`/artists/${artist.slug}`}>
                      <img src={artist.artwork_medium} alt="" />
                      <span>
                        {artist.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.mobile}>
                <img src="/img/mobile.min.png" alt="img"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
