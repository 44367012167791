import React, {Fragment} from "react";
import {useSelector} from "react-redux";

import {NavLink} from "react-router-dom";
// import {useSwiperSlide} from 'swiper/react';
import SongCard from "../songCard/SongCard";


function SongItem({song, onClick, requestSong, getSongStatus}) {
  const {isLightMode} = useSelector((state) => state.toggle);
  // const swiperSlide = useSwiperSlide();
  if (onClick) {
    return (
      <div className="swiper-slide">
        <SongCard song={song} requestSong={requestSong} getSongStatus={getSongStatus} />
      </div>
    );
  }

  return (
    <div className="slider">
      <div className="ex-a d-block">
        <NavLink
          to={`/songs/${song.slug}-${song.id}`}
          className="box-hover d-block"
        >
          <span className="overlay d-block"></span>
          <img
            src={song.artwork_medium}
            alt={song.name}
            className="img-fluid shadow d-block"
          />
          <span className="piano-shadow d-block">
            <img
              src={isLightMode ? "/img/Piano-light.svg" : "/img/keyboard.png"}
              alt=""
              className="img-fluid d-block"
            />
          </span>
        </NavLink>
        <span className="title-item d-block">
          <NavLink
            to={`/songs/${song.slug}-${song.id}`}
            className="songstitle d-block"
          >
            {song.name}
          </NavLink>
          <span className="singer-name d-block">
            {song.artists.map((aa, aaidx) => (
              <Fragment key={aa.id}>
                {aaidx > 0 && ", "}
                <NavLink to={`/artists/${aa.slug}`}>{aa.name}</NavLink>
              </Fragment>
            ))}
          </span>
        </span>
      </div>
    </div>
  );
}

export default SongItem;
