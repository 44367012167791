import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import { getAccessConfig } from "../../../utils";
import { changePassord } from "../../../store/action/AuthAction";

import { useHistory } from "react-router-dom";
import { UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS } from "../../../store/action/ActionType";

function ChangePassword() {
  const dispatch = useDispatch();
  const hisotry = useHistory();
  const { isLightMode } = useSelector((state) => state.toggle);

  const { changePasswordSuccess, changePasswordFail } = useSelector(
    (state) => state.auth
  );

  const [currentPassword, setCurrentPassword] = useState("");
  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);

  const [newPassword, setNewPassword] = useState("");
  const [hideNewPassword, setHideNewPassword] = useState(true);

  const [isProgress, setIsProgress] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("current_password", currentPassword);
    formData.append("new_password", newPassword);
    formData.append("re_new_password", newPassword);
    setIsProgress(true);
    dispatch(changePassord(formData, getAccessConfig()));
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      setCurrentPassword("");
      setNewPassword("");
      setIsProgress(false);
      dispatch({ type: UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS });
    }
  }, [changePasswordSuccess, dispatch]);

  useEffect(() => {
    if (changePasswordFail) {
      setIsProgress(false);
    }
  }, [changePasswordFail]);

  return (
    <div className="Edit-Profile">
      <div className="Edit-top">
        <h2>
          <a href="#0" onClick={hisotry.goBack}>
            <img
              src={isLightMode ? "/img/back-light.svg" : "/icons/arrow-left.svg"}
              alt="img"
              className="img-fluid"
            />
          </a>{" "}
          Change Password
        </h2>
      </div>
      <div className="Avatar">
        <div className="Login">
          <div className="box">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 ">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Current Password
                </label>
                <div className="coustom-pass">
                  <input
                    type={hideCurrentPassword ? "password" : "text"}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter your current password"
                  />
                  <button
                    type="button"
                    onClick={() => setHideCurrentPassword(!hideCurrentPassword)}
                  >
                    <img
                      src={`/img/${hideCurrentPassword ? "show" : "play"}.svg`}
                      alt="img"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <span style={{ color: "red", float: "left" }}>
                  {changePasswordFail &&
                    changePasswordFail.data.current_password}
                </span>
              </div>

              <div className="mb-3 ">
                <label htmlFor="exampleInputPassword2" className="form-label">
                  New Password
                </label>
                <div className="coustom-pass">
                  <input
                    type={hideNewPassword ? "password" : "text"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword2"
                    placeholder="Enter your new password"
                  />
                  <button
                    type="button"
                    onClick={() => setHideNewPassword(!hideNewPassword)}
                  >
                    <img
                      src={`/img/${hideNewPassword ? "show" : "play"}.svg`}
                      alt="img"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <span style={{ color: "red", float: "left" }}>
                  {changePasswordFail && changePasswordFail.data.new_password}
                </span>
              </div>

              <div className="text-end">
                <button type="submit" className="btn w-auto">
                  {isProgress && (
                    <Spinner
                      animation="border"
                      variant="warning"
                      size="sm"
                      style={{ marginLeft: -26, marginRight: 10 }}
                    />
                  )}
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
