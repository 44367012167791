import React from "react";
import SongmodeHeader from "../header/SongmodeHeader";
import SongmodeSidebar from "../../component/sidebar/SongmodeSidebar";
import { useSelector } from "react-redux";
import SongmodeFooter from "../footer/SongmodeFooter";
import BottomNavbar from "../bottomNavbar/BottomNavbar";

import styles from "./TutorialLayout.module.scss";

function TutorialLayout({ children, isTutorial=false, isHome=false, isLanding=false }) {
  const { isMenuExpanded, isTutorialMenuExpanded, isMobileExpanded } =
    useSelector((state) => state.music);
  const isSidebarExpanded = isTutorial
    ? isTutorialMenuExpanded
    : isMenuExpanded;

  return (
    <div
      className={
        isSidebarExpanded || isMobileExpanded ? "" : "collapses-left-item"
      }
    >
      <BottomNavbar isTutorial={isTutorial} />

      <div className={`wrapper ${isLanding?styles.landing:''}`}>
        <div style={{padding: isTutorial && 0}} className="container-fluid">
          {!isLanding && <SongmodeSidebar />}
          <div className="mide-item">
            <SongmodeHeader
              isTutorial={isTutorial}
              isHome={isHome||isLanding}
              noSearchBar={false}
            />
            {children}
          </div>
        </div>
        <SongmodeFooter isTutorial={isTutorial} />
      </div>
    </div>
  );
}

export default TutorialLayout;
