import { useSelector, useDispatch } from "react-redux";
import { setViewPreference } from "../../store/action/ActionType";

import styles from "./ViewPreferenceSwitch.module.scss";

export default function ViewPreferenceSwitch({ style }) {
  const dispatch = useDispatch();

  function changeViewPreference(e) {
    const view = e.target.checked ? "list" : "carousel";
    dispatch(setViewPreference(view));
  };

  const { isLightMode } = useSelector((state) => state.toggle);
  const viewPreference = useSelector((state) => state.viewPreference);

  return (
    <div style={style} className={styles.switchListCarousel}>
      <img style={{ filter: isLightMode ? "invert(1)" : '' }} src="/icons/carousel-horizontal.svg" alt="Carousel view" />
      <label>
        <input
          type="checkbox"
          onChange={changeViewPreference}
          checked={viewPreference === "list"}
        />
        <span />
      </label>
      <img style={{ filter: isLightMode ? "invert(1)" : '' }} src="/icons/list-details.svg" alt="List view" />
    </div>
  );
}