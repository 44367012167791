import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./MobileSearchHeader.module.scss";
import ViewPreferenceSwitch from "../viewPreferenceSwitch/ViewPreferenceSwitch";

export default function MobileSearchHeader({ hasBoxShadow = true }) {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const { isLightMode } = useSelector((state) => state.toggle);

  const handleSearch = (e) => {
    searchText &&
    history.push("/search/tutorials/" + encodeURIComponent(searchText));
  };

  return (
    <div style={{ boxShadow: hasBoxShadow && "0 40px 30px 0px #171a1fb0", backgroundColor: isLightMode ? "rgba(227, 227, 231)" : '' }} className={styles.mobileHomeHeader}>
      <div className={styles.searchBarContainer}>
        <div className={`search-bar ${styles.searchBar}`}>
          <form className="d-inline-block" onSubmit={handleSearch}>
            <input
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className="form-control"
              type="search"
              placeholder="Search songs and artists"
              aria-label="Search"
            />
            <button className={styles.searchBarButton} type="submit">
              <img
                src="/img/search.png"
                alt="img"
                className="img-fluid"
              />
            </button>
          </form>
        </div>
        <ViewPreferenceSwitch />
      </div>
    </div>
	);
}