import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { formatDuration } from "../../utils";
import styles from "./TutorialSections.module.scss";

export default function TutorialSections({ sections, currentTime, tutorialDuration, setPlaybackPosition, speed, speedOptions, onSpeedChange }) {
  if (!sections || sections.length === 0) sections = [{ start: 0, end: tutorialDuration, group: 1 }];
  currentTime *= 1000;
  tutorialDuration *= 1000;
  sections[sections.length - 1].end = tutorialDuration;
  tutorialDuration -= sections[0].start;
  
  const { isLightMode } = useSelector((state) => state.toggle);
  const tutorialSections = useRef(null);
  const isLoopMode = useRef(false);
  
  const holdTimer = useRef(null);
  const selectedSections = useRef([]);

  const [{isLoopModeSt, selectedSectionsSt}, setLoopModeSt] = useState({isLoopModeSt: isLoopMode.current, selectedSectionsSt: selectedSections.current});

  const progressIndicatorRef = useRef(null);
  const isDraggingRef = useRef(false);
  const [progressIndicatorPosition, setProgressIndicatorPosition] = useState(0);
  
  // Disable context menu in this component
  useEffect(() => {
    if (!tutorialSections.current) return;
    const listener = tutorialSections.current.addEventListener("contextmenu", (e) => e.preventDefault());
    if (!listener) return;
    return () => listener.removeEventListener("contextmenu", (e) => e.preventDefault());
  }, [tutorialSections]);
  
  useEffect(() => {
    const loopStart = sections[selectedSections.current[0]]?.start;
    const loopEnd = sections[selectedSections.current[selectedSections.current.length - 1]]?.end;
    if (isLoopMode.current && (currentTime <= loopStart - 1 || currentTime >= loopEnd + 1)) {
      setPlaybackPosition(loopStart / 1000);
    }
    if (isDraggingRef.current) return;
    const isInRange = currentTime >= sections[0].start && currentTime <= sections[sections.length - 1].end;
    if (isInRange) {
      setProgressIndicatorPosition(100 * (currentTime - sections[0].start) / tutorialDuration)
    } else {
      setProgressIndicatorPosition(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  useEffect(() => {
    const progressIndicatorElement = progressIndicatorRef.current;

    function handleDragStart() {
      isDraggingRef.current = true;
    }

    function handleDragMove(e) {
      if (!isDraggingRef.current) return;
      const progressBar = tutorialSections.current;
      const progressBarRect = progressBar.getBoundingClientRect();
      const clientX = e.clientX || e.touches[0].clientX;
      const offsetX = clientX - progressBarRect.left;
      let newPosition = offsetX / progressBarRect.width * 100;
      newPosition = Math.min(Math.max(0, newPosition), 100);
      setProgressIndicatorPosition(newPosition);
      setPlaybackPosition(tutorialDuration * newPosition / 100 / 1000);
    }

    function handleDragEnd() {
      if (isDraggingRef.current) {
        const newPlaybackPosition = tutorialDuration * progressIndicatorPosition / 100 / 1000;
        setPlaybackPosition(newPlaybackPosition);
        isDraggingRef.current = false;
      }
    }

    progressIndicatorElement.addEventListener("mousedown", handleDragStart);
    document.addEventListener("mousemove", handleDragMove);
    document.addEventListener("mouseup", handleDragEnd);

    progressIndicatorElement.addEventListener("touchstart", handleDragStart);
    document.addEventListener("touchmove", handleDragMove);
    document.addEventListener("touchend", handleDragEnd);

    return () => {
      progressIndicatorElement.removeEventListener("mousedown", handleDragStart);
      document.removeEventListener("mousemove", handleDragMove);
      document.removeEventListener("mouseup", handleDragEnd);

      progressIndicatorElement.removeEventListener("touchstart", handleDragStart);
      document.removeEventListener("touchmove", handleDragMove);
      document.removeEventListener("touchend", handleDragEnd);
    };
  }, [tutorialDuration, setPlaybackPosition, progressIndicatorPosition]);
  
  function setLoop(sectionIdx) {
    if (selectedSections.current.includes(sectionIdx)) return;
    // Only allow adjacent sections to be added to the loop
    const isAdjacent = sectionIdx === selectedSections.current[selectedSections.current.length - 1] + 1 || sectionIdx === selectedSections.current[0] - 1;
    if (!isAdjacent && selectedSections.current.length > 0) return;
    selectedSections.current.push(sectionIdx);
    selectedSections.current.sort((a, b) => a - b);
    setPlaybackPosition(sections[selectedSections.current[0]].start / 1000);
    isLoopMode.current = true;
    setLoopModeSt({isLoopModeSt: isLoopMode.current, selectedSectionsSt: [...selectedSections.current]});
    navigator.vibrate(100);
  }

  function handleTouchStart(sectionIdx) {
    if (holdTimer.current) return;
    holdTimer.current = setTimeout(() => setLoop(sectionIdx), 500);
  }

  function handleTouchEnd() {
    if (!holdTimer.current) return;
    clearTimeout(holdTimer.current);
    holdTimer.current = null;
  }

  function handleClick(e, sectionIdx) {
    if (sections.length === 1) {
      // If there's only one section, calculate the playback position based on the click position
      const progressBar = tutorialSections.current;
      const progressBarRect = progressBar.getBoundingClientRect();
      const clientX = e.clientX || e.touches[0].clientX;
      const offsetX = clientX - progressBarRect.left;
      const newPosition = (offsetX / progressBarRect.width) * 100;
      const newPlaybackPosition = (tutorialDuration * newPosition) / 100 / 1000;
      setPlaybackPosition(newPlaybackPosition);
    } else if (isLoopMode.current) {
      // Remove section from loop only if it's the first or last section
      if (sectionIdx === selectedSections.current[0] || sectionIdx === selectedSections.current[selectedSections.current.length - 1]) {
        selectedSections.current = selectedSections.current.filter((idx) => idx !== sectionIdx);
        if (selectedSections.current.length === 0) {
          isLoopMode.current = false;
        } else {
          setPlaybackPosition(sections[selectedSections.current[0]].start / 1000);
        }
        setLoopModeSt({isLoopModeSt: isLoopMode.current, selectedSectionsSt: [...selectedSections.current]});
      } else {
        setLoop(sectionIdx);
      }
    } else {
      setPlaybackPosition(sections[sectionIdx].start / 1000);
    }
  }

  function toggleLoopMode() {
    isLoopMode.current = !isLoopMode.current;
    if (!isLoopMode.current) {
      selectedSections.current = [];
    }
    setLoopModeSt({isLoopModeSt: isLoopMode.current, selectedSectionsSt: [...selectedSections.current]});
  }

  return <>
    <div ref={tutorialSections} className={`${styles.tutorialsSections} ${isLightMode ? styles.lightMode : ''}`}>
      {sections.map((section, index) => (
        <div
          key={index}
          className={styles.section}
          style={{
            width: `calc(${(section.end - section.start) / tutorialDuration * 100}% ${sections.length !== index + 1 ? "- 2px" : ''})`,
          }}
        >
          <div
            style={{ boxShadow: selectedSectionsSt.includes(index) && "0 0 1px 1px white" }}
            className={styles.bar}
            onTouchStart={() => handleTouchStart(index)}
            onTouchEnd={handleTouchEnd}
            onClick={(e) => handleClick(e, index)}
          />
          <div className={styles.tooltip}>
            <span>Hook {section.group}</span>
            <span>({formatDuration(section.start)} - {formatDuration(section.end)})</span>
          </div>
        </div>
      ))}
      <div
        style={{
          left: `calc(${progressIndicatorPosition}% - 3px)`,
        }}
        ref={progressIndicatorRef}
        className={styles.progressIndicator}
      />
    </div>
    <div className={styles.playbackSpeedControl} style={{float: 'right', marginTop: 2}}>
      {speedOptions.length > 0 && <>
        <span className={styles.speedTooltip} title="Playback Rate">✕</span>
        {/* <span className={styles.speedTooltip} title="Playback Rate">Playback Rate</span> */}
        <select
          className={styles.playbackRateSelect}
          value={speed}
          onChange={e => onSpeedChange(parseFloat(e.target.value))}
        >
          {speedOptions.map((pbrate, i )=> (
            <option key={i} value={pbrate}>
              {pbrate}
            </option>
          ))}
        </select>
      </>}
    </div>
    <span onClick={toggleLoopMode} className={styles.loopLabel} style={{opacity:0.5, display:isLoopModeSt?'none':''}}><img src="/icons/loop.svg" alt="Loop" /><span style={{opacity:0.0}}>Loop mode</span></span>
    <span onClick={toggleLoopMode} className={styles.loopLabel} style={{opacity:1.0, display:isLoopModeSt?'':'none'}}><img src="/icons/loop.svg" alt="Loop" /><span style={{opacity:1.0}}>Loop mode</span></span>
  </>
}