import React, {useState} from 'react'
import {ResetPasswordConfirms} from '../store/action/AuthAction'
import { useDispatch, useSelector } from 'react-redux'
import {NavLink, useHistory, useParams } from "react-router-dom";
import { Button, Col, Form, Spinner } from 'react-bootstrap';

function ResetPasswordConfirm() {

    const dispatch = useDispatch()
    const history = useHistory()

    const { token } = useParams()

    const { accessToken, startForgetPassword, forgetPasswordSuccess, forgetPasswordFail } = useSelector(state => state.auth)
    const [restPasswordInfo, setRestPasswordInfo] = useState({
        password:'',
        re_password:''
    })

    const {password} = restPasswordInfo

    const handleInput = (e) =>{

        setRestPasswordInfo(prevState=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))

    }

    const handleSubmint = (e) =>{
        e.preventDefault()
        const formData = new FormData()
        formData.append('token', token)
        formData.append('password', password)
        dispatch(ResetPasswordConfirms(formData))
    }

    if (accessToken) {
      history.push('/');
      return (<></>)
    }

    return (
        <>
          <h4 style={{textAlign: "center", paddingTop: "1.5rem", marginTop: 5}}>Reset Password</h4>

          {forgetPasswordSuccess
            ? 
            <p style={{color:'#007bff', textAlign:'center', paddingTop: '1.5rem'}}>Password Reset Successfully, try to <NavLink to="/login">login</NavLink> again</p>
            : 
            <Form style={{margin:'auto'}} className="login" onSubmit={handleSubmint} >
              <Col style={{maxWidth: 300, margin: '0 auto'}}>
                <div className="login__field" style={{ marginTop: 12 }}>
                    {/* <i className="login__icon fas fa-lock"></i> */}
                    <Form.Control name="password" value={password} onChange={handleInput}  className="login__input" type="password" placeholder="New Password" id="password" />
                    <span style={{color:'red', float:'left'}}>{forgetPasswordFail && forgetPasswordFail.data && forgetPasswordFail.data.password}</span>
                    <span style={{color:'red', float:'left'}}>{forgetPasswordFail && forgetPasswordFail.data && forgetPasswordFail.data.detail}</span>
                </div>
                <div style={{ marginTop: 12, textAlign: 'center' }}>
                  {startForgetPassword
                    ? 
                    <Spinner animation="grow" variant="info"/>
                    :
                    <Button  onClick={handleSubmint} className="button login__submit" variant="primary">
                      <span className="button__text">Save New Password</span>
                    </Button>
                  }
                </div>
              </Col>
            </Form>
          }
        </>
    )
}

export default ResetPasswordConfirm
