import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./Modals.module.scss";
import useWindowSize from "../../hooks/useWindowSize";

export default function PWAInstallModal() {
  const [showModal, setShowModal] = useState(false);
  const [relatedAppsSupported, setRelatedAppsSupported] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const { isLightMode } = useSelector((state) => state.toggle);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  
  let os = null;
  if (isMobile) {
    const userAgent = navigator.userAgent;
    if (/iPhone|iPad/.test(userAgent)) {
      os = "iOS";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (navigator.getInstalledRelatedApps) {
        navigator.getInstalledRelatedApps().then(apps => {
          if (apps.length < 1) setShowModal(true);
        });
      } 
      else {
        setRelatedAppsSupported(false);
        setShowModal(true);
      }
    }, 10000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleBeforeInstallPrompt(e) {
      e.preventDefault();
      setDeferredPrompt(e);
    }
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  async function install() {
    deferredPrompt.prompt();
    // const { outcome } = await deferredPrompt.userChoice;
    // if (outcome === "accepted") {
    //   console.log("User accepted the install prompt");
    // } else {
    //   console.log("User dismissed the install prompt");
    // }
    setDeferredPrompt(null);
    setShowModal(false);
  }

  return <>
    {os && showModal &&
      <div style={{ backgroundColor: isLightMode ? "var(--text-primary)" : '' }} className={styles.pwaInstallModal}>
        <img alt="Songmode logo" src="/img/songmode-logo-small.png" className={styles.logo} />
        <span className={styles.message}>{
          os === "iOS" && <>
            Install this webapp on your iPhone in a Safari browser: Tap <img style={{ filter: isLightMode ? "invert(1)" : '' }} className={styles.optionsIcon} src="icons/iphone-options.svg" alt="iPhone options" /> and then Add to Home Screen
          </>}{
          os === "Android" && (
            (relatedAppsSupported && deferredPrompt) ? <>
              Tap to install this webapp on your phone
            </> : <>
              Install this webapp on your phone: Tap <img style={{ filter: isLightMode ? "invert(1)" : '' }} className={styles.optionsIcon} src="icons/android-options.svg" alt="Android options" /> and then Add to Home Screen
            </>
          )
        }</span>
        {os === "Android" && relatedAppsSupported && deferredPrompt && (
          <button onClick={install} className={styles.installButton}>Install</button>
        )}
        <button style={{ filter: isLightMode ? "invert(1)" : '' }} className={styles.closeButton} onClick={() => setShowModal(false)} />
      </div>
    }
  </>
}