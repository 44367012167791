import React from 'react';
import { Modal } from 'react-bootstrap';

import styles from './Modals.module.scss'


const MidiModal = ({ header, children, className, closeButton=true, onCloseButton=null, ...props }) => {

  const headerProps = {}
  if (onCloseButton) {
    headerProps['onHide'] = onCloseButton;
  }

  return (
    <Modal
      className={className ? `${styles.modal} ${className}` : styles.modal}
      
      centered
      width="auto"

      {...props}
    >
      <Modal.Header  style={{borderBottom: 0}} closeButton={closeButton} {...headerProps}>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{ textAlign: "center" }}>{header}</h4>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default MidiModal