import React, { useEffect, useState } from "react";

import axios from "axios";
import { getAccessConfig } from "../../utils";
import FilterButton from "../../component/filterButton/FilterButton";

import styles from "./TopSongs.module.scss";
import SongsTable from "../../component/songsTable/SongsTable";
import { useSelector } from "react-redux";
import TitleHelmet from "../../component/titleHelmet/TitleHelmet";


function TopSongs() {
  const { isLightMode } = useSelector((state) => state.toggle);
  const [top, setTop] = useState([]);

  const [genreFilter, setGenreFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('week');

  useEffect(() => {
    async function getTopSongs() {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/songs/topsongs/`;
        const ret = await axios.get(url, {
          ...getAccessConfig(),
          params: {
            genre: genreFilter || null,
            time: timeFilter || null,
          },
        });
        setTop(ret.data.topSongs);
      } catch (err) {
        const error = err.response ? err.response : "networkError";
        console.log(err, error);
        return false;
      }
    }
    getTopSongs();
  }, [genreFilter, timeFilter]);

  const toggleGenreFilter = (value) => {
    const newVal = value === genreFilter ? '' : value;
    setGenreFilter(newVal);
  };

  const toggleTimeFilter = (value) => {
    const newVal = value === timeFilter ? '' : value;
    setTimeFilter(newVal);
  }

  return (
    <>
      <TitleHelmet title={`Songmode - Top Sogs`} />
      <div className={`top-songs-body ${styles.topSongs}`}>
        <h1 className={styles.title}><img style={{ filter: isLightMode ? "invert(1)" : '' }} src="/icons/fire.svg" alt="img" className="img-fluid" width={30} />Top Songs</h1>
        <FilterButton className={styles.filter}
          genreFilter={genreFilter} toggleGenreFilter={toggleGenreFilter}
          timeFilter={timeFilter} toggleTimeFilter={toggleTimeFilter}
        />
        <SongsTable songs={top} isRanked />
      </div>
    </>
  );
}

export default TopSongs;
