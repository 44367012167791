// Authentication
export const REGISTRATION_START = "REGISTRATION_START";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";
export const UPDATE_REGISTRATION_STATUS = "UPDATE_REGISTRATION_STATUS";

export const START_EMAIL_VARIFICATION = "START_EMAIL_VARIFICATION";
export const EMAIL_VARIFICATION_SUCCESS = "EMAIL_VARIFICATION_SUCCESS";
export const EMAIL_VARIFICATION_FAIL = "EMAIL_VARIFICATION_FAIL";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPDATE_AUTH_STATUS = "UPDATE_AUTH_STATUS";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESSS = "CHANGE_PASSWORD_SUCCESSS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS =
  "UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS";

export const FORGET_PASSWORD_REQUEST_START = "FORGET_PASSWORD_REQUEST_START";
export const FORGET_PASSWORD_REQUEST_SUCCESS =
  "FORGET_PASSWORD_REQUEST_SUCCESS";
export const FORGET_PASSWORD_REQUEST_FAIL = "FORGET_PASSWORD_REQUEST_FAIL";
export const UPDATE_FORGET_PASSWORD_REQUEST_STATUS =
  "UPDATE_FORGET_PASSWORD_REQUEST_STATUS";

export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

// end Authentication

// Music

export const GET_TOP_LIST_START = "GET_TOP_LIST_START";
export const GET_TOP_LIST_SUCCESS = "GET_TOP_LIST_SUCCESS";
export const GET_TOP_LIST_FAIL = "GET_TOP_LIST_FAIL";

export const GET_LATEST_MUSIC_START = "GET_LATEST_MUSIC_START";
export const GET_LATEST_MUSIC_SUCCESS = "GET_LATEST_MUSIC_SUCCESS";
export const GET_LATEST_MUSIC_FAIL = "GET_LATEST_MUSIC_FAIL";

export const GET_ALL_MUSIC_START = "GET_ALL_MUSIC_START";
export const GET_ALL_MUSIC_SUCCESS = "GET_ALL_MUSIC_SUCCESS";
export const GET_ALL_MUSIC_FAIL = "GET_ALL_MUSIC_FAIL";

export const ADD_MUSIC_TO_CART_START = "ADD_MUSIC_TO_CART_START";
export const ADD_MUSIC_TO_CART_SUCCESS = "ADD_MUSIC_TO_CART_SUCCESS";
export const ADD_MUSIC_TO_CART_FAIL = "ADD_MUSIC_TO_CART_FAIL";
export const UPDATE_MUSIC_TO_CART_SUCCESS_STATUS =
  "UPDATE_MUSIC_TO_CART_SUCCESS_STATUS";

export const COUNT_CARTED_MUSIC_START = "COUNT_CARTED_MUSIC_START";
export const COUNT_CARTED_MUSIC_SUCCESS = "COUNT_CARTED_MUSIC_SUCCESS";
export const COUNT_CARTED_MUSIC_FAIL = "COUNT_CARTED_MUSIC_FAIL";

export const GET_CART_MUSIC_LIST_START = "GET_CART_MUSIC_LIST_START";
export const GET_CART_MUSIC_LIST_SUCCESS = "GET_CART_MUSIC_LIST_SUCCESS";
export const GET_CART_MUSIC_LIST_FAIL = "GET_CART_MUSIC_LIST_FAIL";

export const GET_PERCHESED_MUSIC_START = "GET_PERCHESED_MUSIC_START";
export const GET_PERCHESEMUSIC_SUCCESS = "GET_PERCHESEDUSIC_SUCCESS";
export const GET_PERCHESED_MUSIC_FAIL = "GET_PERCHESED_MUSIC_FAIL";

export const GET_PERCHESED_MUSIC_LIST_START = "GET_PERCHESED_MUSIC_LIST_START";
export const GET_PERCHESEDUSIC_LIST_SUCCESS = "GET_PERCHESEDUSIC_LIST_SUCCESS";
export const GET_PERCHESED_MUSIC_LIST_FAIL = "GET_PERCHESED_MUSIC_LIST_FAIL";

export const SET_MUSIC = "SET_MUSIC";
export const SET_REQUESTED_STATUS = "SET_REQUESTED_STATUS";

// User Info

export const GET_USER_INFO_START = "GET_USER_INFO_START";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";

export const UPDATE_USER_INFO_START = "UPDATE_USER_INFO_START";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_SUCCESS_STATUS =
  "UPDATE_USER_INFO_SUCCESS_STATUS";
export const UPDATE_USER_INFO_FAIL = "UPDATE_USER_INFO_FAIL";

export const SET_USER = "SET_USER";
export const SET_USER_INFO = "SET_USER_INFO";

// admin

export const DELETE_LIST_OF_MUSIC_START = "DELETE_LIST_OF_MUSIC_START";
export const DELETE_LIST_OF_MUSIC_SUCCESS = "DELETE_LIST_OF_MUSIC_SUCCESS";
export const UPDATE_DELETE_LIST_OF_MUSIC_SUCCESS =
  "UPDATE_DELETE_LIST_OF_MUSIC_SUCCESS";
export const DELETE_LIST_OF_MUSIC_FAIL = "DELETE_LIST_OF_MUSIC_FAIL";

export const GET_SINGLE_SONG_START = "GET_SINGLE_SONG_START";
export const GET_SINGLE_SONG_SUCCESS = "GET_SINGLE_SONG_SUCCESS";
// export const UPDATE_DELETE_LIST_OF_MUSIC_SUCCESS = 'UPDATE_DELETE_LIST_OF_MUSIC_SUCCESS'
export const GET_SINGLE_SONG_FAIL = "GET_SINGLE_SONG_FAIL";

export const UPDATE_SINGLE_SONG_START = "UPDATE_SINGLE_SONG_START";
export const UPDATE_SINGLE_SONG_SUCCESS = "UPDATE_SINGLE_SONG_SUCCESS";
export const UPDATE_SINGLE_SONG_SUCCESS_STATUS =
  "UPDATE_SINGLE_SONG_SUCCESS_STATUS";
export const UPDATE_SINGLE_SONG_FAIL = "UPDATE_SINGLE_SONG_FAIL";

// upload file
export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const CLEAN_UPLOAD_FILE_STATUS = "CLEAN_UPLOAD_FILE_STATUS";

export const UPLOAD_FILE_MENUALLY_START = "UPLOAD_FILE_MENUALLY_START";
export const UPLOAD_FILE_MENUALLY_SUCCESS = "UPLOAD_FILE_MENUALLY_SUCCESS";
export const UPLOAD_FILE_MENUALLY_FAIL = "UPLOAD_FILE_MENUALLY_FAIL";
export const CLEAN_UPLOAD_MENUALLY_FILE_STATUS =
  "CLEAN_UPLOAD_MENUALLY_FILE_STATUS";

// sold Music history
export const GET_SOLDMUSIC_HISTORY_START = "GET_SOLDMUSIC_HISTORY_START";
export const GET_SOLDMUSIC_HISTORY_SUCCESS = "GET_SOLDMUSIC_HISTORY_SUCCESS";
export const GET_SOLDMUSIC_HISTORY_FAIL = "GET_SOLDMUSIC_HISTORY_FAIL";

// delete sold histoy

export const DELETE_SOLD_HISTOYR_START = "DELETE_SOLD_HISTOYR_START";
export const DELETE_SOLD_HISTOYR_SUCCESS = "DELETE_SOLD_HISTOYR_SUCCESS";
export const UPDATE_DELETE_SOLD_HISTOYR_SUCCESS =
  "UPDATE_DELETE_SOLD_HISTOYR_SUCCESS";
export const DELETE_SOLD_HISTOYR_FAIL = "DELETE_SOLD_HISTOYR_FAIL";

// top List

export const GET_MUSIC_FOR_TOP_LIST_START = "GET_MUSIC_FOR_TOP_LIST_START";
export const GET_MUSIC_FOR_TOP_LIST_SUCCESS = "GET_MUSIC_FOR_TOP_LIST_SUCCESS";
export const GET_MUSIC_FOR_TOP_LIST_FAIL = "GET_MUSIC_FOR_TOP_LIST_FAIL";

export const UPDATE_TOP_LIST_START = "UPDATE_TOP_LIST_START";
export const UPDATE_TOP_LIST_SUCCESS = "UPDATE_TOP_LIST_SUCCESS";
export const UPDATE_TOP_LIST_FAIL = "UPDATE_TOP_LIST_FAIL";
export const UPDATE_TOP_LIST_SUCCESS_STATUS = "UPDATE_TOP_LIST_SUCCESS_STATUS";

// requested music

export const GET_REQUESTED_MUSIC_LIST_START = "GET_REQUESTED_MUSIC_LIST_START";
export const GET_REQUESTED_MUSIC_LIST_SUCCESS =
  "GET_REQUESTED_MUSIC_LIST_SUCCESS";
export const GET_REQUESTED_MUSIC_LIST_FAIL = "GET_REQUESTED_MUSIC_LIST_FAIL";

export const DELETE_REQUESTED_MUSIC_LIST_START =
  "DELETE_REQUESTED_MUSIC_LIST_START";
export const DELETE_REQUESTED_MUSIC_LIST_SUCCESS =
  "DELETE_REQUESTED_MUSIC_LIST_SUCCESS";
export const DELETE_REQUESTED_MUSIC_LIST_FAIL =
  "DELETE_REQUESTED_MUSIC_LIST_FAIL";
export const UPDATE_DELETE_REQUESTED_MUSIC_LIST_SUCCESS =
  "UPDATE_DELETE_REQUESTED_MUSIC_LIST_SUCCESS";

// adds

export const GET_ADDS_START = "GET_ADDS_START";
export const GET_ADDS_SUCCESS = "GET_ADDS_SUCCESS";
export const GET_ADDS_FAIL = "GET_ADDS_FAIL";

export const UPDATE_ADD_START = "UPDATE_ADD_START";
export const UPDATE_ADD_SUCCESS = "UPDATE_ADD_SUCCESS";
export const UPDATE_ADD_FAIL = "UPDATE_ADD_FAIL";

// searchMusic

export const SEARCH_MUSIC_START = "SEARCH_MUSIC_START";
export const SEARCH_MUSIC_SUCCESS = "SEARCH_MUSIC_SUCCESS";
export const SEARCH_MUSIC_FAIL = "SEARCH_MUSIC_FAIL";

export const REQUEST_MUSIC_START = "REQUEST_MUSIC_START";
export const REQUEST_MUSIC_SUCCESS = "REQUEST_MUSIC_SUCCESS";
export const REQUEST_MUSIC_FAIL = "REQUEST_MUSIC_FAIL";

export const GET_SELL_RPORT_START = "GET_SELL_RPORT_START";
export const GET_SELL_RPORT_SUCCESS = "GET_SELL_RPORT_SUCCESS";
export const GET_SELL_RPORT_FAIL = "GET_SELL_RPORT_FAIL";

export const GET_SELL_RPORT_WITH_GIVEN_DATE_START =
  "GET_SELL_RPORT_WITH_GIVEN_DATE_START";
export const GET_SELL_RPORT_WITH_GIVEN_DATE_SUCCESS =
  "GET_SELL_RPORT_WITH_GIVEN_DATE_SUCCESS";
export const GET_SELL_RPORT_WITH_GIVEN_DATE_FAIL =
  "GET_SELL_RPORT_WITH_GIVEN_DATE_FAIL";

// get Single sing

export const GET_SINGELAGE_SONGE_START = "GET_SINGELAGE_SONGE_START";
export const GET_SINGELAGE_SONGE_SUCCESS = "GET_SINGELAGE_SONGE_SUCCESS";
export const GET_SINGELAGE_SONGE_FAIL = "GET_SINGELAGE_SONGE_FAIL";

// get Tutorial sing

export const GET_TUTORIAL_START = "GET_TUTORIAL_START";
export const GET_TUTORIAL_SUCCESS = "GET_TUTORIAL_SUCCESS";
export const GET_TUTORIAL_FAIL = "GET_TUTORIAL_FAIL";

// Extra info

export const GET_EXTRA_INFO_START = "GET_EXTRA_INFO_START";
export const GET_EXTRA_INFO_SUCCESS = "GET_EXTRA_INFO_SUCCESS";
export const GET_EXTRA_INFO_FAIL = "GET_EXTRA_INFO_FAIL";

export const CREATE_EXTRA_INFO_START = "CREATE_EXTRA_INFO_START";
export const CREATE_EXTRA_INFO_SUCCESS = "CREATE_EXTRA_INFO_SUCCESS";
export const CREATE_EXTRA_INFO_FAIL = "CREATE_EXTRA_INFO_FAIL";
export const UPDATE_CREATE_EXTRA_INFO_SUCCESS_STATIS =
  "UPDATE_CREATE_EXTRA_INFO_SUCCESS_STATIS";

// Subscription

export const CREATE_ARTIST_SUBSCRIPTION_START =
  "CREATE_ARTIST_SUBSCRIPTION_START";
export const CREATE_ARTIST_SUBSCRIPTION_SUCCESS =
  "CREATE_ARTIST_SUBSCRIPTION_SUCCESS";
export const CREATE_ARTIST_SUBSCRIPTION_FAIL =
  "CREATE_ARTIST_SUBSCRIPTION_FAIL";

export const CREATE_CREATOR_SUBSCRIPTION_START =
  "CREATE_CREATOR_SUBSCRIPTION_START";
export const CREATE_CREATOR_SUBSCRIPTION_SUCCESS =
  "CREATE_CREATOR_SUBSCRIPTION_SUCCESS";
export const CREATE_CREATOR_SUBSCRIPTION_FAIL =
  "CREATE_CREATOR_SUBSCRIPTION_FAIL";

export const REMOVE_ARTIST_SUBSCRIPTION_START =
  "REMOVE_ARTIST_SUBSCRIPTION_START";
export const REMOVE_ARTIST_SUBSCRIPTION_SUCCESS =
  "REMOVE_ARTIST_SUBSCRIPTION_SUCCESS";
export const REMOVE_ARTIST_SUBSCRIPTION_FAIL =
  "REMOVE_ARTIST_SUBSCRIPTION_FAIL";

export const REMOVE_CREATOR_SUBSCRIPTION_START =
  "REMOVE_CREATOR_SUBSCRIPTION_START";
export const REMOVE_CREATOR_SUBSCRIPTION_SUCCESS =
  "REMOVE_CREATOR_SUBSCRIPTION_SUCCESS";
export const REMOVE_CREATOR_SUBSCRIPTION_FAIL =
  "REMOVE_CREATOR_SUBSCRIPTION_FAIL";

export const GET_SUBSCRIPTION_LIST_START = "GET_SUBSCRIPTION_LIST_START";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_FAIL = "GET_SUBSCRIPTION_LIST_FAIL";

export const GET_SUBSCRIBED_ITEMS_START = "GET_SUBSCRIBED_ITEMS_START";
export const GET_SUBSCRIBED_ITEMS_SUCCESS = "GET_SUBSCRIBED_ITEMS_SUCCESS";
export const GET_SUBSCRIBED_ITEMS_FAIL = "GET_SUBSCRIBED_ITEMS_FAIL";

export const UPDATE_LAST_NOTIFIED_START = "UPDATE_LAST_NOTIFIED_START";
export const UPDATE_LAST_NOTIFIED_SUCCESS = "UPDATE_LAST_NOTIFIED_SUCCESS";
export const UPDATE_LAST_NOTIFIED_FAIL = "UPDATE_LAST_NOTIFIED_FAIL";

// Theme
export const TOGGLE_THEME = "TOGGLE_THEME";

// View preference
export function setViewPreference(view) {
  localStorage.setItem("viewPreference", view);
  return {
    type: 'SET_VIEW_PREFERENCE',
    payload: view,
  };
}
