import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getExtraInfo} from '../../store/action/AdminAction'

import styles from './TermsAndCondition.module.scss'
import TitleHelmet from '../titleHelmet/TitleHelmet'


function TermsAndCondition({ page }) {

    const dispatch = useDispatch()
    const { getExtraInfosuccess } = useSelector(state => state.admin)
    
    useEffect(() => {
        dispatch(getExtraInfo())
    }, [])

    const titles = {
      contactUs: 'Contact Us',
      aboutUs: 'About Us',
      legal: 'Legal',
      termsAndCondition: 'Terms and Conditions',
      privacyPolicy: 'Privacy Policy',
    }

    return (
      <>
        <TitleHelmet title={`Songmode - ${titles[page]}`} />
        <div className={styles.main}>
          <section className={styles.mainSection}>
            <article className="page type-page status-publish hentry">
              {titles[page] !== "Contact Us" && (
                <h1 className={styles.title}>{titles[page]}</h1>
              )}
              <div className="entry">
                <div dangerouslySetInnerHTML={{__html: getExtraInfosuccess && getExtraInfosuccess[page]}} />        
              </div>
            </article>
          </section>
        </div>
      </>
    )
}

export default TermsAndCondition
