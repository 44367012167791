import { getTheme } from "../../utils";
import { TOGGLE_THEME } from "../action/ActionType";
const curTheme = getTheme();

const initialState = {
  isLightMode: curTheme === "light",
};

const ToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return { ...state, isLightMode: !state.isLightMode };
    default:
      return state;
  }
};

export default ToggleReducer;
