import axios from 'axios'
import { fillUserFields } from '../../utils';
import {
    GET_USER_INFO_START,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAIL,

    UPDATE_USER_INFO_START,
    UPDATE_USER_INFO_SUCCESS,
    UPDATE_USER_INFO_FAIL,

} from './ActionType'


export const getUserInfo = (config) => async (dispatch) => {
  try {
    dispatch({type:GET_USER_INFO_START})

    await axios.get(process.env.REACT_APP_BACKEND_URL+"/user/userinfo/", config).then((res) => {
      const user = res.data;
      fillUserFields(user);
      dispatch({
        type:GET_USER_INFO_SUCCESS,
        data : user
      })
    });
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    dispatch({
      type:GET_USER_INFO_FAIL,
      data:error
    })
  }
};


export const updateUserInfo = (id, formData, config) => async (dispatch) => {
   
    try {
        dispatch({type:UPDATE_USER_INFO_START})

            await axios.patch(process.env.REACT_APP_BACKEND_URL+`/user/${id}/`,formData, config).then((res) => {
                console.log('updated');
                dispatch({
                    type:UPDATE_USER_INFO_SUCCESS,
                })
                dispatch(getUserInfo(config))
      
              })
        
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:UPDATE_USER_INFO_FAIL,
        data:error
      })
    }

};

