import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { SET_MUSIC } from "../../store/action/ActionType";
import {
  getDefaultAvatar,
  fillUserFields,
  getAccessConfig,
  getAccessToken,
} from "../../utils";

import Comment from "../../component/comment/Comment";
import styles from "./VideoComments.module.scss";

async function getComments(tutorialId) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/tutorials/comments/list/${tutorialId}/`;
    const ret = await axios.get(url, getAccessConfig());
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(error);
    return false;
  }
}

async function createComment(tutorialId, parentId, message, category) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/tutorials/comments/create/`;
    const ret = await axios.post(
      url,
      { tutorial: tutorialId, parent: parentId || 0, category, message },
      getAccessConfig()
    );
    return ret.data.comment;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(error);
    return false;
  }
}

async function toggleLiked(tutorialId, commentId, liked) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/tutorials/comments/like/`;
    const ret = await axios.post(
      url,
      { tutorial: tutorialId, comment: commentId, liked: !!liked },
      getAccessConfig()
    );
    return ret.data.comment;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(error);
    return false;
  }
}

function updateComments(data, tabIndex, comId, newCom) {
  const ntabs = [...data.tabs];
  ntabs[tabIndex] = {
    ...ntabs[tabIndex],
    comments: [...ntabs[tabIndex].comments],
  };
  const idx1 = ntabs[tabIndex].comments.findIndex((com) => com.id === comId);
  if (idx1 > -1) {
    ntabs[tabIndex].comments[idx1] = newCom;
    return { ...data, tabs: ntabs };
  }
  for (let pidx in ntabs[tabIndex].comments) {
    const parent = ntabs[tabIndex].comments[pidx];
    if (parent.children) {
      const idx2 = parent.children.findIndex((com) => com.id === comId);
      if (idx2 > -1) {
        const nchildren = [...parent.children];
        nchildren[idx2] = newCom;
        ntabs[tabIndex].comments[pidx] = { ...parent, children: nchildren };
        return { ...data, tabs: ntabs };
      }
    }
  }
  return null;
}

// const CategorySelect = ({ optionsList, onSelect }) => {
//   const [isOptionsOpen, setIsOptionsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(0);
//   useEffect(() => {
//     onSelect(selectedOption);
//   }, [onSelect, selectedOption]);

//   const toggleOptions = () => {
//     setIsOptionsOpen(!isOptionsOpen);
//   };

//   const setSelectedThenCloseDropdown = (index) => {
//     setSelectedOption(index);
//     setIsOptionsOpen(false);
//   };

//   return (
//     <div
//       style={{ display: "flex", flexDirection: "column", alignItems: "end" }}
//     >
//       <button
//         type="button"
//         aria-haspopup="listbox"
//         aria-expanded={isOptionsOpen}
//         className={`${styles.selectButton} ${isOptionsOpen ? "expanded" : ""}`}
//         onClick={toggleOptions}
//       >
//         <span style={{ display: "flex", justifyContent: "space-between" }}>
//           <span>{optionsList[selectedOption]}</span>
//           <span>{isOptionsOpen ? "▲" : "▼"}</span>
//         </span>
//       </button>
//       <ul
//         className={`${styles.selectList} ${
//           isOptionsOpen ? styles.selectListShow : ""
//         }`}
//         role="listbox"
//         aria-activedescendant={optionsList[selectedOption]}
//         tabIndex={-1}
//       >
//         {optionsList?.map((option, index) => (
//           <li
//             id={option}
//             role="option"
//             className={styles.selectListItem}
//             aria-selected={selectedOption == index}
//             tabIndex={0}
//             onClick={() => {
//               setSelectedThenCloseDropdown(index);
//             }}
//           >
//             {option}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

function CommentForm({
  user,
  parent,
  isActive,
  value,
  onOpen,
  onUpdate,
  onSave,
  onClose,
  children,
}) {
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // const handleTabSelection = (idx) => {
  //   setSelectedTabIndex(idx);
  // };
  // const handleLineAdd = (e) => {
  //   e.target.style.height = "inherit";
  //   e.target.style.height = `${e.target.scrollHeight + 16}px`;
  // };
  if (!user) return null;
  return (
    <div className="add-comment">
      <form>
        <div className="media">
          <img
            src={user.avatar || user.animated_avatar || getDefaultAvatar(user.email)}
            alt="img"
            className="img-fluid"
          />
          <div className="media-body">
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="comment"
                value={value}
                onChange={(evt) => onUpdate(evt.target.value)}
                placeholder="Add comment"
                onFocus={() => !isActive && onOpen(parent)}
                autoComplete="off"
              />
              {/* Keeping this comment here because may have to implement this way later */}
              {/* <div
                className={styles.commentWrapper}
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.8);",
                }}
              >
                <textarea
                  draggable={false}
                  onKeyDown={handleLineAdd}
                  rows={3}
                  style={{
                    background: "transparent",
                    color: "#fff",
                    overflow: "hidden",
                    resize: "none",
                  }}
                  type="text"
                  className={styles.commentInput}
                  id="comment"
                  value={value}
                  onChange={(evt) => onUpdate(evt.target.value)}
                  placeholder="Add comment"
                  onFocus={() => !isActive && onOpen(parent)}
                />
                {isActive && (
                  <div className={styles.commentTag}>
                    {tabs.map((tb, tbIdx) => (
                      <button
                        className={`btn btn-default ${
                          selectedTabIndex === tbIdx ? "active" : ""
                        }`}
                        onClick={(e) => {
                          handleTabSelection(tbIdx);
                          e.preventDefault();
                        }}
                      >
                        {tb.label}
                      </button>
                    ))}
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
        {isActive && (
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div className="text-end  comment-button comment-button1">
              {children}
            </div>
            <div className="text-end  comment-button comment-button1">
              <button
                type="button"
                className="btn btn-primary"
                id="Close"
                onClick={onClose}
                style={{ marginRight: 5 }}
              >
                Close
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                onClick={(evt) => {
                  evt.preventDefault();
                  onSave(parent, value);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

function formatAgo(com, now) {
  const dif = now - new Date(com.created).getTime();
  const mm = Math.floor(dif / 60000 + 0.1);
  if (mm <= 1) {
    return "< 1m";
  }
  if (mm < 60) {
    return `${mm}m`;
  }
  const hh = Math.floor(mm / 60 + 0.1);
  if (hh < 24) {
    return `${hh}h`;
  }
  const dd = Math.floor(hh / 24 + 0.1);
  if (dd < 31) {
    return dd < 2 ? `a day` : `${dd} days`;
  }
  const mnth = Math.floor(dd / 30.5 + 0.1);
  return mnth < 2 ? `a month` : `${mnth} months`;
}

function VideoComments({ tutorial, setLoopLowValue, setLoopHighValue, setPlaybackPosition, min, max }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { comments } = useSelector((state) => state.music);

  const { get_user_info_success: user } = useSelector((state) => state.user);
  const { isLightMode } = useSelector((state) => state.toggle);

  const [focusedId, setFocusedId] = useState(0);
  const [newComment, setNewComment] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [lastCommentId, setLastCommentId] = useState(0);

  useEffect(() => {
    let firstTab =
      comments?.tabs?.map((e) => e.comments.length > 0)?.indexOf(true) ?? 0;
    firstTab = firstTab < 0 ? 0 : firstTab;
    setTabIndex(firstTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments?.tabs?.length]);
  useEffect(() => {
    async function _getComments() {
      const data = await getComments(tutorial.id);

      data.contributors = data.contributors.reduce((acc, user) => {
        fillUserFields(user);
        acc[user.id] = user;
        return acc;
      }, {});

      const now = new Date();

      for (let tab of data.tabs) {
        for (let com of tab.comments) {
          com.ago = formatAgo(com, now);
          com.user = data.contributors[com.user_id];
          if (com.children) {
            for (let ccom of com.children) {
              ccom.ago = formatAgo(ccom, now);
              ccom.user = data.contributors[ccom.user_id];
            }
          }
        }
      }

      dispatch({ type: SET_MUSIC, data: { comments: data } });
    }
    _getComments();
  }, [tutorial, dispatch]);

  const openNewComment = (parent) => {
    setFocusedId((parent && parent.id) || -1);
  };

  const onSaveComment = async (parent, value) => {
    if (value) {
      const newCom = await createComment(
        tutorial.id,
        parent && parent.id,
        value,
        parent
          ? comments.tabs[tabIndex].category
          : comments.tabs[selectedTabIndex].category
      );
      if (newCom) {
        newCom.user = user;
        newCom.ago = formatAgo(newCom, new Date());
        closeNewComment();
        const ntab = { ...comments.tabs[parent ? tabIndex : selectedTabIndex] };
        ntab.comments = [...ntab.comments];
        if (parent) {
          const pidx = ntab.comments.findIndex((com) => com === parent);
          ntab.comments[pidx] = { ...ntab.comments[pidx] };
          ntab.comments[pidx].children = ntab.comments[pidx].children
            ? [...ntab.comments[pidx].children]
            : [];
          ntab.comments[pidx].children.unshift(newCom);
        } else {
          ntab.comments.unshift(newCom);
          setTabIndex(selectedTabIndex);
        }
        const ntabs = [...comments.tabs];
        ntabs[parent ? tabIndex : selectedTabIndex] = ntab;
        dispatch({
          type: SET_MUSIC,
          data: { comments: { ...comments, tabs: ntabs } },
        });
        setLastCommentId(newCom.id);
      }
    }
  };

  const closeNewComment = () => {
    setFocusedId(0);
    setNewComment("");
  };

  const onLikeComment = async (evt, com) => {
    evt.preventDefault();
    if (!getAccessToken()) {
      history.push("/login");
      return;
    }
    if (!com.liked && user && user.id !== com.user.id) {
      const data = await toggleLiked(tutorial.id, com.id, true);
      const ncomments = updateComments(comments, tabIndex, com.id, {
        ...com,
        like_count: data.like_count,
        liked: data.liked,
      });
      data && dispatch({ type: SET_MUSIC, data: { comments: ncomments } });
    }
  };

  const [sortType, setSortType] = useState("newest");

  if (!tutorial || tutorial.id !== comments.tutorial) return null;

  return (
    <div className={`${isLightMode ? styles.lightMode : ''} nav_body profile-body`}>
      <CommentForm
        user={user}
        parent={null}
        isActive={focusedId === -1}
        value={newComment}
        onOpen={openNewComment}
        onUpdate={setNewComment}
        onClose={closeNewComment}
        onSave={onSaveComment}
      >
        {/* Comments tabs feature is disabled for now */}
        {/* <CategorySelect
          optionsList={comments.tabs.map((e) => e.label)}
          onSelect={(idx) => {
            setSelectedTabIndex(idx);
          }}
        /> */}
      </CommentForm>
      <button
        className={styles.sortButton}
        onClick={() => setSortType(sortType === "newest" ? "top" : "newest")}
      >
        <img src="/icons/sort-descending.svg" alt="Sort comments" />
        <span>Sorted by {sortType === "newest" ? "newest first" : "top comments"}</span>
      </button>
      {/* Comments tabs feature is disabled for now */}
      {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {comments.tabs.map((tb, tbIdx) => (
          <li
            style={{
              display: tb.comments.length > 0 ? "block" : "none",
            }}
            className="nav-item"
            role="presentation"
            key={tb.category}
          >
            <button
              onClick={() => setTabIndex(tbIdx)}
              className={"nav-link" + (tbIdx === tabIndex ? " active" : "")}
              type="button"
            >
              {tb.label}
            </button>
          </li>
        ))}
      </ul> */}
      <div className="tab-content">
        <div className={`tab-pane fade show active ${styles.commentsContainer}`}>
          {comments.tabs[tabIndex].comments.sort((a, b) => {
            if (sortType === "newest") {
              return new Date(b.created) - new Date(a.created);
            } else {
              return b.like_count - a.like_count;
            }
          }).map((com) => (
            <div
              className="user-comment"
              key={com.id}
            >
              <div className="media">
                {com.user && <NavLink to={com.user.url} className="user-img">
                  <img
                    src={com.user.avatar || com.user.animated_avatar || getDefaultAvatar(com.user.email)}
                    alt="img"
                    className="img-fluid"
                  />
                </NavLink>}
                {!com.user && <span className="user-img">
                  <img
                    src={com.authorAvatar}
                    alt="img"
                    className="img-fluid"
                  />
                </span>}
                <div style={{width: "85%"}} className="media-body">
                  <div className="user-body">
                    <h4>
                      {com.user && <NavLink to={com.user.url}>
                        {com.user.name} <span>. {com.ago} ago</span>
                      </NavLink>}
                      {!com.user && <span>
                        {com.authorName} <span>. {com.ago} ago</span>
                      </span>}
                    </h4>
                    <Comment
                      message={com.message}
                      setLoopLowValue={setLoopLowValue}
                      setLoopHighValue={setLoopHighValue}
                      setPlaybackPosition={setPlaybackPosition}
                      min={min}
                      max={max}
                      isSafe={com.is_safe}
                      isImported={com.is_imported}
                    />
                    <ul>
                      <li>
                        <a href="#0" onClick={(e) => onLikeComment(e, com)}>
                          <img
                            src={
                              com.liked
                                ? "/img/love-c.svg"
                                : isLightMode
                                ? "/img/love-light.svg"
                                : "/img/love-dark.svg"
                            }
                            alt="img"
                            className="img-fluid"
                          />
                          {com.like_count ? com.like_count : ""}
                        </a>
                      </li>
                      {user && (
                        <li>
                          <button
                            id="replay"
                            onClick={() => openNewComment(com)}
                          >
                            Reply
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* Add comment start */}
                  {focusedId === com.id && (
                    <CommentForm
                      user={user}
                      parent={com}
                      isActive={true}
                      value={newComment}
                      onUpdate={setNewComment}
                      onClose={closeNewComment}
                      onSave={onSaveComment}
                    />
                  )}

                  {com.children &&
                    com.children.map((ccom, ccomIdx) => (
                      <div
                        className="user-comment"
                        key={ccom.id}
                        style={{ marginTop: ccomIdx === 0 ? 25 : "" }}
                      >
                        <div className="media">
                          {com.user && <NavLink to={ccom.user.url} className="user-img">
                            <img
                              src={ccom.user.avatar || ccom.user.animated_avatar || getDefaultAvatar(ccom.user.email)}
                              alt="img"
                              className="img-fluid"
                            />
                          </NavLink>}
                          {!com.user && <span className="user-img">
                            <img
                              src={ccom.authorAvatar}
                              alt="img"
                              className="img-fluid"
                            />
                          </span>}
                          <div className="media-body">
                            <div className="user-body">
                              <h4>
                                {com.user && <NavLink
                                  to={ccom.user.url}
                                  className="user-img"
                                >
                                  {ccom.user.name} <span>. {ccom.ago} ago</span>
                                </NavLink>}
                                {!com.user && <span
                                  className="user-img"
                                >
                                  {ccom.authorName} <span>. {ccom.ago} ago</span>
                                </span>}
                              </h4>
                              <Comment
                                message={com.message}
                                setLoopLowValue={setLoopLowValue}
                                setLoopHighValue={setLoopHighValue}
                                setPlaybackPosition={setPlaybackPosition}
                                min={min}
                                max={max}
                                isSafe={com.is_safe}
                                isImported={com.is_imported}
                              />
                              <ul>
                                <li>
                                  <a
                                    href="#0"
                                    onClick={(e) => onLikeComment(e, ccom)}
                                  >
                                    <img
                                      src={
                                        ccom.liked
                                          ? "/img/love-c.svg"
                                          : isLightMode
                                          ? "/img/love-light.svg"
                                          : "/img/love-dark.svg"
                                      }
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    {ccom.like_count ? ccom.like_count : ""}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideoComments;
