import "./css/index.css";

import "./css/stars.scss";
import "./css/viewers.scss";

import "./css/media.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import AuthReducer from "./store/reducer/AuthReducer";
import MusicReducer from "./store/reducer/MusicReducer";
import UserReducer from "./store/reducer/UserReducer";
import AdminActionReducer from "./store/reducer/AdminActionReducer";
import ToggleReducer from "./store/reducer/ToggleReducer";
import SubscribeReducer from "./store/reducer/SubscribeReducer";
import ViewPreferenceReducer from "./store/reducer/ViewPreferenceReducer";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";


const RootReducer = combineReducers({
  auth: AuthReducer,
  music: MusicReducer,
  user: UserReducer,
  admin: AdminActionReducer,
  toggle: ToggleReducer,
  subscriptions: SubscribeReducer,
  viewPreference: ViewPreferenceReducer,
});

const logger = (store) => {
  return (next) => {
    return (action) => {
      // console.log("[middleware] dipatching", action);
      const result = next(action);
      // console.log("[middleware] next state", store.getState());
      return result;
    };
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(logger, thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading... </div>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

window.songmodeCloseSplashScreen();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
