import React, { useState, useEffect, useRef } from 'react'
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
import { NavLink, useLocation } from 'react-router-dom';
import GoTopOnAll from '../../component/goTopOnAll/GoTopOnAll';

import styles from './ArtistList.module.scss'
import { makeGrid } from '../../utils';
import TitleHelmet from '../../component/titleHelmet/TitleHelmet';

async function queryItems(searchParams, page) {
  try {

    let url = `${process.env.REACT_APP_BACKEND_URL}/songs/artists/${page}/`;

    if (searchParams.genre) {
      url += `?genre=${searchParams.genre}`;
    }

    if (searchParams.country) {
      const join = searchParams.genre ? '&' : '?';
      url += `${join}country=${searchParams.country}`;
    }

    if (searchParams.city) {
      url += `&city=${searchParams.city}`;
    }

    const ret = await axios.get(url);
    const data = ret.data;
    return { data: data.results, hasNext: !!data.next, count: data.count, name: data.name };
  } catch(err) {
    const error =  err.response ? err.response  : "networkError"
    console.log(err);
    return { data: [], hasNext: false, name: '' };
  }
}

function ArtistList() {

  const location = useLocation();
  const searchParamsObj = new URLSearchParams(location.search);
  const searchParams = {
    genre: searchParamsObj.get('genre'),
    country: searchParamsObj.get('country'),
    city: searchParamsObj.get('city'),
  };

  const [page, setPage] = useState(0)
  const [searchNext, setSearchNext] = useState(true)
  const [items, setItems] = useState([])
  const [header, setHeader] = useState('')

  const fetchStateRef = useRef({ isLoading: false, session: 0 })

  const reset = () => {
    setPage(0);
    setSearchNext(true);
    setItems([]);

    fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session + 1 }
  }

  const fetchData = async (searchParams, curPage, curItems) => {
    if (fetchStateRef.current.isLoading) {
      return;
    }
    
    fetchStateRef.current = { isLoading: true, session: fetchStateRef.current.session }
    const session = fetchStateRef.current.session;

    const ret = await queryItems(searchParams, curPage + 1);

    if (fetchStateRef.current.session !== session) {
      return;
    }
    fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session }
    
    if (!ret.hasNext) {
        setSearchNext(false)
    }
    if (ret.data.length) {
      setPage(curPage + 1)
      setItems([...curItems, ...ret.data]);
      if (header !== ret.name) {
        setHeader(ret.name);
      }
    }
  }

  useEffect(() => {
    reset();
    fetchData({ genre: searchParams.genre, country: searchParams.country, city: searchParams.city }, 0, []);
  }, [searchParams.genre, searchParams.country, searchParams.city])

  return (
    <>
      <TitleHelmet title={`Songmode - Top Artists`} />
      <div className="Top-Artists" style={{marginTop: 0, paddingTop: 0}}>
        <h4 style={{marginBottom: 20}} className={styles.header}>{header}</h4>
        <InfiniteScroll
          dataLength={items && items.length ? items.length : 0} //This is important field to render the next data
          next={() => fetchData(searchParams, page, items)}
          hasMore={searchNext}
          loader={<div style={{textAlign: 'center', marginTop: 25, marginBottom: 20}}><Spinner animation="border" variant="primary"/></div>}
          endMessage={page>1 &&
            <GoTopOnAll />
          }
          scrollableTarget=""
        >
          {items && makeGrid(items, 8).map((row, ridx) => (
            <div key={ridx} class={`top-artist-banner top-slide-1 row g-0 justify-content-center ${styles.row}`}>
              {row.map((aa, index) => (
                <div key={aa?aa.id:`null${index}`} className={'col-lg col-sm-4 col-6'+(index>1?' mt-lg-0 mt-4':'')}>
                  {aa ? <div className="banner-item text-center">
                    <NavLink to={`/artists/${aa.slug}`} className="d-block">
                      <span className="box d-block">
                        <img src="/img/green-check.svg" alt="img" className="img-check"/>
                        <img src={aa.artwork_medium} alt={aa.name} className="img-fluid"/>
                      </span>
                      <span className="p mb-0 d-block">{aa.name}</span>
                    </NavLink>
                  </div> : <></>}
                </div>
              ))}
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </>
  )
}

export default ArtistList
