import { useSelector } from "react-redux";
import styles from './Difficulty.module.scss';

const levels = {
  level_10: "Beginner",
  level_20: "Easy",
  level_25: "Shorts",
  level_30: "Medium",
  level_40: "Hard",
};

const levelOptions = [10, 20, 25, 30, 40].map(level => ({ value: level, label: levels[`level_${level}`] }));

export default function Difficulty({ level, secondaryColor }) {
  const { isLightMode } = useSelector((state) => state.toggle);
  const difficulty = levels[`level_${level}`] || "Easy";

  return (
    <span
      style={{border: secondaryColor && `solid 2px ${secondaryColor}`}}
      className={`${styles.difficultyWrapper} ${isLightMode ? styles.lightMode: ''}`}
    >
      {difficulty}
    </span>
  );
}

export { levelOptions };