import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {Link, NavLink, useHistory, useParams} from "react-router-dom";
import ModalMessage from "./modals/ModalMessage";
import {Button, Spinner} from "react-bootstrap";
import {SET_MUSIC} from "../store/action/ActionType";
import {useDispatch, useSelector} from "react-redux";
import {SongSlider} from "../component/songList/SongList";
import {TutorialSlider} from "../component/tutorialList/TutorialList";
import {getAccessToken, updateTokenChecker, makeNumberSmall, getAccessConfig} from "../utils";
import MobileSearchHeader from "../component/mobileSearchHeader/MobileSearchHeader";
import useWindowSize from "../hooks/useWindowSize";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
//import "swiper/css/navigation";
import {FreeMode} from "swiper";

import styles from "./HomePage.module.scss";
import sliderStyles from "./Slider.module.scss";
import {
  getLastNotified,
  updateLastNotified,
} from "../store/action/SubscribeAction";
import PlaylistIcon from "../component/playlistIcon/PlaylistIcon";
import TutorialsTable from "../component/tutorialsTable/TutorialsTable";
import SongsTable from "../component/songsTable/SongsTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import TitleHelmet from "../component/titleHelmet/TitleHelmet";


async function requestActivate(uid, code) {
  const formData = new URLSearchParams();
  formData.append("uid", uid);
  formData.append("token", code);
  try {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      //withCredentials: true,
    };

    const ret = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/users/activation/`,
      formData,
      config
    );
    return [true, null];
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    return [false, error];
  }
}

async function queryGenresLists() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/songs/playlists-overview/`;
    const ret = await axios.get(url, getAccessConfig());
    const data = ret.data;
    return { data };
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(error);
    return { data: [], hasNext: false, name: "" };
  }
}

function transformGenresLists(respData) {
    const songsMap = respData.songs.reduce((acc, song) => { acc[song.id]=song; return acc; }, {});

    if (respData.saved) {
      respData.results.splice(0, 0, {
        id: 'saved', name: 'Saved', slug: 'saved',
        songs_ids: respData.saved.songs_ids,
      });
    }

    respData.results.forEach(it => {
      it.songs = it.songs_ids.map(sid => songsMap[sid]);
      const itSongsSet = {};
      it.songs.forEach(song => {
        song.genres = song.genres || [];
        song.genres.push(it.id);
        itSongsSet[song.id] = true;
      });
      //filter all songs to keep order
      it.songs = respData.songs.filter(song => itSongsSet[song.id]);
    });

    return { lists: respData.results, songs: respData.songs };
}

function HomePage() {
  const songsRef = useRef();
  const songsListRef = useRef();
  const tutorialsRef = useRef();
  const tutorialsListRef = useRef();
  const dispatch = useDispatch();
  const updateSongSeen = () => {
    const curToken = getAccessToken(true);

    if (curToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      };
      const formData = new FormData();
      formData.append("update_type", "notified_songs_at");
      dispatch(updateLastNotified(config, formData));
    }

    updateTokenChecker(dispatch, false, !curToken);
  };

  const onSetSelectedTab = (tab) => {
    history.push(tab === "following" ? "/following" : "/");
  }

  const [selectedTab, setSelectedTab] = useState("discover");
  const [selectedSubscription, setSelectedSubscription] = useState("artists");
  useEffect(() => {
    const songsObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          updateSongSeen();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    if (songsRef.current) {
      songsObserver.observe(songsRef.current);
    }
    if (songsListRef.current) {
      songsObserver.observe(songsListRef.current);
    }
    const _songsRef = songsRef.current;
    const _songsListRef = songsListRef.current;
    return () => {
      if (_songsRef) {
        songsObserver.unobserve(_songsRef);
      }
      if (_songsListRef) {
        songsObserver.unobserve(_songsListRef);
      }
    }
  }, [dispatch, songsRef.current, songsListRef.current, selectedTab, selectedSubscription]);

  const location = useLocation();
  useEffect(() => {
      const newSelectedTab = location.pathname === "/following" ? "following" : "discover";
      if (newSelectedTab !== selectedTab) {
        setSelectedTab(newSelectedTab);
      }
  }, [location]);

  const updateTutorialSeen = () => {
    const curToken = getAccessToken(true);

    if (curToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      };
      const formData = new FormData();
      formData.append("update_type", "notified_tutorials_at");
      dispatch(updateLastNotified(config, formData));
    }

    updateTokenChecker(dispatch, false, !curToken);
  };
  useEffect(() => {
    const tutorialsObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          updateTutorialSeen();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    if (tutorialsRef.current) {
      tutorialsObserver.observe(tutorialsRef.current);
    }
    if (tutorialsListRef.current) {
      tutorialsObserver.observe(tutorialsListRef.current);
    }
    const _tutorialsRef = tutorialsRef.current;
    const _tutorialsListRef = tutorialsListRef.current;
    return () => {
      if (_tutorialsRef) {
        tutorialsObserver.unobserve(_tutorialsRef);
      }
      if (_tutorialsListRef) {
        tutorialsObserver.unobserve(_tutorialsListRef);
      }
    };
  }, [dispatch, tutorialsRef.current, tutorialsListRef.current, selectedTab, selectedSubscription]);
  const {uid, code} = useParams();
  const [activatingStatus, setActivatingStatus] = useState("activating");
  const history = useHistory();

  const {isLightMode} = useSelector((state) => state.toggle);
  const {
    subscriptionSongs,
    subscriptionTutorials,
    newSongsCount,
    newTutorialsCount,
  } = useSelector((state) => state.subscriptions);

  const closeActivating = () => {
    history.push("/");
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = (evt) => {
    const scrollPos = (evt.currentTarget === window) ? window.scrollY : evt.currentTarget.scrollTop;
    const threshold = isMobile ? 0 : 160;
    if (scrollPos > threshold) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // async function setTutorialData() {
    //   try {
    //     await axios
    //       .get(process.env.REACT_APP_BACKEND_URL + "/tutorials/home/items")
    //       .then((res) => {
    //         setPageData(res.data);
    //       });
    //   } catch (err) {
    //     const error = err.response ? err.response : "networkError";
    //     console.log(error)
    //   }
    // }
    // setTutorialData();
    onGenresTabClick(true);
  }, []);

  useEffect(() => {
    async function activate() {
      if (uid && code) {
        setActivatingStatus("activating");
        const [is_ok, error] = await requestActivate(uid, code);
        if (is_ok) {
          setActivatingStatus("activated");
        } else if (error.detail === "Stale token for given user.") {
          setActivatingStatus("expired");
        } else {
          setActivatingStatus("error");
        }
      }
    }
    activate();
  }, [uid, code]);

  const handleLogin = () => {
    closeActivating();
    history.push("/login");
  };
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    searchText &&
    history.push("/search/tutorials/" + encodeURIComponent(searchText));
  };

  const [ genresLists, setGenresLists ] = useState(null);
  const [ allSongs, setAllSongs ] = useState([]);
  const [ selectedGenresSongs, setSelectedGenresSongs ] = useState([]);

  const onGenresTabClick = async (isPageLoad) => {
    onSetSelectedTab('discover');
    const resp = await queryGenresLists();
    const respData = transformGenresLists(resp.data);
    setAllSongs(respData.songs);
    setGenresLists(respData.lists);

    if (isPageLoad) {
      const defaultGenres = localStorage.getItem('defaultGenres_1');
      if (defaultGenres) {
        onSetSelectedGenre(null, JSON.parse(defaultGenres), { genresLists: respData.lists, allSongs: respData.songs });
      }
    }
  }
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const viewPreference = useSelector((state) => state.viewPreference);

  const [selectedGenre, setSelectedGenre] = useState({});

  useEffect(() => {
    if (genresLists) {
      setSelectedGenre({});
      setSelectedGenresSongs(allSongs);
    }
  }, [genresLists]);

  const swiperRef = useRef(null);
  const setGenresSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const onSetSelectedGenre = (plist, genres, newState) => {

    const glist = (newState && newState.genresLists) || genresLists;

    genres = genres || { ...selectedGenre, [plist.id]: !selectedGenre[plist.id] };
    const selGenresObjects = glist.filter(gg => genres[gg.id]);

    if (selGenresObjects.length < 1) {
      //don't allow to unselect all genres
      genres = {};
    } else if (Object.keys(genres).length !== selGenresObjects.length) {
      genres = selGenresObjects.reduce((acc, gg) => { acc[gg.id] = true; return acc; }, {});
    }

    setSelectedGenre(genres);

    localStorage.setItem('defaultGenres_1', JSON.stringify(genres));

    if (selGenresObjects.length === 1) {
      setSelectedGenresSongs(selGenresObjects[0].songs);
    } else {
      const songs = (newState && newState.allSongs) || allSongs;
      if (selGenresObjects.length < 1) {
        setSelectedGenresSongs(songs);
      } else {
        setSelectedGenresSongs(songs.filter(song => song.genres.find(ggid => genres[ggid])));
      }
    }

    swiperRef.current?.slideTo(0, 500);
  };

  return (
    <>
      <TitleHelmet title="Songmode - Chord Progressions, Song Key, BPM, Hooks" />
      {uid && code && (
        <ModalMessage
          header="Account Activation"
          onHide={closeActivating}
          isSuccess={activatingStatus === "activated"}
        >
          <p>
            {activatingStatus === "activating" && (
              <Spinner animation="grow" variant="info"/>
            )}
            {activatingStatus === "activated" && (
              <>
                Your account has been activated and is ready to use! Please{" "}
                <a href="#" onClick={handleLogin}>
                  Login
                </a>
                .
              </>
            )}
            {activatingStatus === "expired" && (
              <>
                Link is used or expired! Try to register, login or reset
                password.
              </>
            )}
            {activatingStatus === "error" && (
              <>Unexpected error, please try to refresh the page!</>
            )}
          </p>

          {activatingStatus !== "activating" && (
            <div style={{textAlign: "center", paddingTop: 20}}>
              <Button
                variant="primary"
                onClick={
                  activatingStatus === "activated"
                    ? handleLogin
                    : closeActivating
                }
              >
                {activatingStatus === "activated" ? "OK" : "Close"}
              </Button>
            </div>
          )}
        </ModalMessage>
      )}
      <section className={`${styles.homePage} Homepage ${isLightMode ? styles.lightMode : ''}`}>
        <div className={styles.homePageBgContainer}>
          <img className={styles.homePageBg} src="/img/home-background.jpg" alt="Background" />
        </div>
        {isMobile ?
          <MobileSearchHeader hasBoxShadow={false} />
          :
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "16px",
            }}
          >
            <div className="big-search">
              <div className="search-bar">
                <form className="d-inline-block" onSubmit={handleSearch}>
                  <input
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className="form-control"
                    type="search"
                    placeholder="Search thousands of songs and artists"
                    aria-label="Search"
                  />
                  <button type="submit">
                    <img
                      src="/img/search.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        }
        <div className={`container-fluid tab-buttons ${styles.tabButtons}`}>
          <button
            onClick={() => {
              onSetSelectedTab("following");
            }}
            className={selectedTab === "following" ? "active" : ""}
          >
            {(newSongsCount + newTutorialsCount) > 0 && (
              <div className="badge">
                {(newSongsCount + newTutorialsCount) > 9 ? "9+" : (newSongsCount + newTutorialsCount)}
              </div>
            )}
            Following
          </button>
          <span>&bull;</span>
          <button
            onClick={()=>onGenresTabClick()}
            className={selectedTab === "discover" ? "active" : ""}
          >
            Discover
          </button>
          {/* <span>&bull;</span>
          <button
            onClick={() => {
              setSelectedTab("creators");
            }}
            className={selectedTab === "creators" ? "active" : ""}
          >
            {newTutorialsCount > 0 && (
              <div className="badge">
                {newTutorialsCount > 9 ? "9+" : newTutorialsCount}
              </div>
            )}
            Creators
          </button> */}
        </div>
        {selectedTab === "following" &&
          <div className={styles.slidesContainer}>
          {(viewPreference === "carousel" && isMobile) && (subscriptionSongs.length > 0 && subscriptionTutorials.length > 0 ? (
            <div style={{ marginTop: isMobile && -60 }} className={styles.slidesContainer}>
              {subscriptionSongs.length > 0 && (
                <div className={`top-songs ${styles.sectionSlide}`}>
                  <h4>
                    From Artists Subscriptions{" "}
                    <img
                      src="/img/emojione_fire.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </h4>
                  <div ref={songsRef}>
                    <SongSlider items={subscriptionSongs} fadeout={!isMobile} />
                  </div>
                  <hr className={styles.separator} />
                </div>
              )}
              {subscriptionTutorials.length > 0 && (
                <div className={`top-songs ${styles.sectionSlide}`}>
                  <h4>
                    From Channel Subscriptions{" "}
                    <img
                      src="/img/emojione_fire.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </h4>
                  <div ref={tutorialsRef}>
                    <TutorialSlider items={subscriptionTutorials}/>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p className={styles.noSubsMessage}>You have no subscriptions yet</p>
          ))}
          {(viewPreference === "list" || !isMobile) && (
            <div className={`top-songs ${styles.listPicker}`}>
              <h4>Subscriptions:</h4>
              <div className={`${styles.listPickerButtonContainer} ${styles.hovButtons}`}>
                <button
                  onClick={() => setSelectedSubscription("artists")}
                  className={selectedSubscription === "artists" ? styles.activeList : ''}
                >
                  <span>Artists</span>
                </button>
                <button
                  onClick={() => setSelectedSubscription("channels")}
                  className={selectedSubscription === "channels" ? styles.activeList : ''}
                >
                  <span>Channels</span>
                </button>
              </div>
              {selectedSubscription === "artists" && (subscriptionSongs.length > 0 ? (
                <div ref={songsListRef} className={`top-songs ${styles.currentListSection}`}>
                  <SongsTable songs={subscriptionSongs} />
                </div>
              ) : (
                <p className={styles.noSubsMessage}>You have no artists subscriptions</p>
              ))}
              {selectedSubscription === "channels" && (subscriptionTutorials.length > 0 ? (
                <div ref={tutorialsListRef}>
                  <TutorialsTable tutorials={subscriptionTutorials} />
                </div>
              ) : (
                <p className={styles.noSubsMessage}>You have no channels subscriptions</p>
              ))}
            </div>
          )}
        </div>}
        {selectedTab === "discover" && (
          genresLists
          ?
          <div className={styles.slidesContainer} onScroll={handleScroll}>
            {(viewPreference === "carousel" && isMobile) &&
              genresLists.map(plist => (
                <div key={plist.slug} className={`top-songs ${styles.sectionSlide}`}>
                  <Link to={`/lists/${plist.slug}`} className={styles.plistName}>
                    {plist.name}
                    <PlaylistIcon playlist={plist} isLightMode={isLightMode} />
                  </Link>
                  <SongSlider items={plist.songs} fadeout={!isMobile} />
                  <hr className={styles.separator} />
                </div>
              ))
            }
            {(viewPreference === "list" || !isMobile) &&
              <div className={`top-songs ${styles.listPicker}`}>
                {/* <h4>Pick a Genre</h4> */}
                <div className={`${styles.genresSwiper} ${styles.hovButtons}`} {...(isScrolled?{'data-scrolled': ''}:{})}>
                  <div className={styles.genresSwiperInner}>
                    <Swiper
                      onSwiper={setGenresSwiper}
                      slidesPerView="auto"
                      loop={false}
                      modules={[FreeMode]}
                      freeMode={true}
                      centeredSlidesBounds={true}
                      spaceBetween={10}
                      centeredSlides={false}
                      className={styles.listPickerSwiper}
                    >
                      <SwiperSlide style={{ width: "fit-content" }}>
                          <button
                            onClick={() => onSetSelectedGenre(null, {})}
                            className={Object.keys(selectedGenre).length === 0 ? styles.activeList : ''}
                          >
                            <span>All</span>
                          </button>
                      </SwiperSlide>
                      {[genresLists.filter(plist => selectedGenre[plist.id]), genresLists.filter(plist => !selectedGenre[plist.id])].map(lists => lists.map(plist => (
                        <SwiperSlide style={{ width: "fit-content" }} key={plist.id}>
                          <button
                            onClick={() => onSetSelectedGenre(plist)}
                            className={selectedGenre[plist.id] ? styles.activeList : ''}
                          >
                            <span>{plist.name}</span>
                            {plist.slug !== 'saved' && <PlaylistIcon playlist={plist} isLightMode={isLightMode} />}
                          </button>
                        </SwiperSlide>
                      )))}
                    </Swiper>
                  </div>
                </div>
                <div className={`top-songs ${styles.currentListSection}`}>
                  <SongsTable songs={selectedGenresSongs} />
                </div>
              </div>
            }
          </div>
          :
          <div style={{textAlign: 'center', margin: "150px 0"}}><Spinner animation="grow" variant="info"/></div>
        )}
      </section>
    </>
  );
}

export default HomePage;
