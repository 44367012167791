import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// import Switch from "../../component/switch/Switch";
import { SET_MUSIC, TOGGLE_THEME } from "../../store/action/ActionType";
import DropdownMenu from "../../component/dropdownMenu/DropdownMenu";

const SearchBar = React.lazy(() =>
  import("../../component/searchbar/SearchBar")
);

function SongmodeHeader({ noSearchBar, isProfile, isTutorial, isHome }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const dispatch = useDispatch();

  const { isMenuExpanded, isTutorialMenuExpanded, isMobileExpanded } =
    useSelector((state) => state.music);
  const { isLightMode } = useSelector((state) => state.toggle);

  const toggleNav = () => {
    const newIsExpanded = !(isTutorial
      ? isTutorialMenuExpanded
      : isMenuExpanded);
    const data =
      !isTutorial && newIsExpanded
        ? { isMenuExpanded: true }
        : {
            isMenuExpanded: newIsExpanded,
            isTutorialMenuExpanded: newIsExpanded,
          };
    dispatch({ type: SET_MUSIC, data: data });
  };

  const toggleMobileNav = () => {
    dispatch({
      type: SET_MUSIC,
      data: { isMobileExpanded: !isMobileExpanded },
    });
  };

  const { is_superUser } = useSelector((state) => state.auth);
  const isSU = is_superUser === "true";
  const { get_user_info_success: user } = useSelector((state) => state.user);
  const userId = user && user.id;

  const setLightMode = () => dispatch({ type: TOGGLE_THEME });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  // close dropdown when clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleScroll = () => {
      if (!isHome) return;
      const scrollPos = window.scrollY;
      if (scrollPos > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHome]);

  return <>
    <header
      style={(!isHome || isScrolled) ? {
        backgroundColor: "rgba(17, 20, 23, 0.9)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(40px)",
      } : {}}
      className="fixed-top"
    >
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg ">
          {/* <div className="arrow-show-hide-toggoler d-lg-block d-none">
            <button onClick={toggleNav}>
              <svg
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
                className="style-scope yt-icon"
                style={{
                  pointerEvents: "none",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <g className="style-scope yt-icon">
                  <path
                    d="M21,6H3V5h18V6z M21,11H3v1h18V11z M21,17H3v1h18V17z"
                    className="style-scope yt-icon"
                  ></path>
                </g>
              </svg>
            </button>
          </div> */}
          <a
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
            className="navbar-brand"
            href="/"
          >
            <img
              src={isLightMode ? "/img/mmlogo.png" : "/img/mmlogo.png"}
              alt="img"
              className="img-fluid"
              style={{ height: 35, filter: isLightMode ? "invert(1)" : "invert(0)" }}
            />
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileNav}
          >
            <span className="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill={isLightMode ? "#000" : "#fff"}
              >
                <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
              </svg>
            </span>
          </button> */}
          <div
            className={
              "collapse navbar-collapse" + (isProfile ? "" : " nav-drop")
            }
            id="navbarSupportedContent"
          >
            {!isProfile && !noSearchBar && (
              <SearchBar isLightMode={isLightMode} />
            )}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6524 14.5113C16.1308 14.0464 15.6742 13.5133 15.2948 12.9266C14.8806 12.1167 14.6324 11.2322 14.5647 10.325V7.6532C14.5625 7.32865 14.5335 7.00485 14.4781 6.68506C14.0524 6.59996 13.6433 6.44654 13.2666 6.23073C13.4102 6.69309 13.4831 7.17447 13.4829 7.65861V10.3305C13.5492 11.4371 13.8536 12.5163 14.3754 13.4945C14.7488 14.0862 15.1918 14.6309 15.6951 15.1171H1.84362C2.34684 14.6309 2.78991 14.0862 3.16332 13.4945C3.68504 12.5163 3.98944 11.4371 4.05574 10.3305V7.6532C4.05289 7.02725 4.17354 6.40689 4.41078 5.82764C4.64802 5.24839 4.99719 4.72162 5.43829 4.27751C5.8794 3.83339 6.40377 3.48064 6.9814 3.23946C7.55902 2.99828 8.17854 2.87341 8.80449 2.87199C9.72058 2.87272 10.6161 3.14364 11.379 3.65083C11.2949 3.34199 11.2477 3.02427 11.2384 2.70432V2.36358C10.6736 2.08577 10.0669 1.90303 9.44271 1.82272V1.14124C9.44271 0.949739 9.36663 0.766083 9.23122 0.630673C9.09581 0.495262 8.91216 0.419189 8.72066 0.419189C8.52916 0.419189 8.3455 0.495262 8.21009 0.630673C8.07468 0.766083 7.99861 0.949739 7.99861 1.14124V1.84977C6.60087 2.04694 5.32166 2.74313 4.39714 3.80981C3.47262 4.87649 2.96521 6.24163 2.96861 7.6532V10.325C2.90087 11.2322 2.65262 12.1167 2.23845 12.9266C1.86573 13.512 1.41644 14.0449 0.902521 14.5113C0.844828 14.562 0.798589 14.6244 0.766882 14.6943C0.735175 14.7643 0.718725 14.8402 0.718628 14.917V15.6525C0.718628 15.796 0.775611 15.9335 0.877042 16.035C0.978473 16.1364 1.11604 16.1934 1.25949 16.1934H16.2954C16.4389 16.1934 16.5764 16.1364 16.6779 16.035C16.7793 15.9335 16.8363 15.796 16.8363 15.6525V14.917C16.8362 14.8402 16.8197 14.7643 16.788 14.6943C16.7563 14.6244 16.7101 14.562 16.6524 14.5113Z" fill="white"/>
                        </svg>
                        <span className="dot-cuarcale"></span>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <div className="Notifications">
                            <div className="top-noti">
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h4>Notifications</h4>
                                    </div>
                                    <div className="col-6 text-end align-self-center">
                                        <a href=""> <img src="img/dubbole-check.png" alt="img" className="img-fluid" /> Mark as read</a>
                                    </div>
                                </div>
                            </div>
                            <h3>TODAY</h3>
                            <div className="item">
                                <a href="" className="d-block">
                                    <span className="media">
                                        <span className="box-img d-block">
                                            <img src="img/Image.png" alt="img" className="img-fluid" />
                                            <span className="hash-number">#8</span>
                                        </span>
                                        <span className="media-body">
                                            <h6>Your rank is down!</h6>
                                            <p><strong>Cindy</strong> shifted your rank on the leaderboard.</p>
                                            <small>1h ago</small>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="item item2">
                                <a href="" className="d-block">
                                    <span className="media">
                                        <span className="box-img2 d-block">
                                            <img src="img/img1.png" alt="img" className="img-fluid d-block" />
                                            <img src="img/Piano.svg" alt="img" className="img-fluid d-block" />
                                        </span>
                                        <span className="media-body">
                                            <h6>Your rank is down!</h6>
                                            <p><strong>Cindy</strong> shifted your rank on the leaderboard.</p>
                                            <small>4h ago</small>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="item-all">
                                <a href="#">View all notifications</a>
                            </div>
                            
                        </div>
                    </div>
                </li> */}
              {!userId && (
                <>
                  <li className="nav-item button1">
                    <NavLink className="nav-link " to="/login">
                      Login
                    </NavLink>
                  </li>
                  <li className="nav-item button2">
                    <NavLink className="nav-link " to="/sign-up">
                      Sign Up
                    </NavLink>
                  </li>
                </>
              )}
              {userId && (
                <li className="nav-item button3">
                  <div
                    className="nav-link "
                    onClick={() => setIsDropdownOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={isLightMode ? "/img/user-new.svg" : "/img/user.svg"}
                      alt="img"
                      className="img-fluid"
                    />
                    <div ref={dropdownRef}>
                      <DropdownMenu
                        submenus={[
                          {
                            title: "View profile",
                            url: `/users/${user.username}`,
                            icon: (
                              <img
                                src={
                                  isLightMode
                                    ? "/img/user-new.svg"
                                    : "/img/user.svg"
                                }
                                alt="img"
                                className="img-fluid"
                              />
                            ),
                          },
                          {
                            title: "Edit Profile",
                            url: "/profile/edit",
                            icon: (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 20.0001H8L18.5 9.50006C19.0304 8.96963 19.3284 8.2502 19.3284 7.50006C19.3284 6.74991 19.0304 6.03049 18.5 5.50006C17.9696 4.96963 17.2501 4.67163 16.5 4.67163C15.7499 4.67163 15.0304 4.96963 14.5 5.50006L4 16.0001V20.0001Z"
                                  stroke={isLightMode ? "black" : "white"}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.5 6.5L17.5 10.5"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ),
                          },
                          {
                            title: "Security",
                            url: "/profile/password",
                            icon: (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z"
                                  stroke={isLightMode ? "black" : "white"}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                                  stroke={isLightMode ? "black" : "white"}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11"
                                  stroke={isLightMode ? "black" : "white"}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ),
                          },
                        ]}
                        isOpen={isDropdownOpen}
                        top={"59px"}
                        right={"5px"}
                        user={user}
                      />
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </>
}

export default SongmodeHeader;
