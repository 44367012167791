import styles from './BlurredBackgroundImage.module.scss';

export default function BlurredBackgroundImage({ imageURL, style = {} }) {
  return (
		<div
			style={{
				backgroundImage: `url(${imageURL})`,
				...style,
			}}
			className={styles.blurredBackgroundImage}
		/>
	);
}