export default function Comment({ message, setLoopLowValue, setLoopHighValue, setPlaybackPosition, min, max, isSafe, isImported}) {
  const regex = /((?:\d{1,2}:\d{2}(?::\d{2})?)\s*-\s*(?:\d{1,2}:\d{2}(?::\d{2})?)|(?:\d{1,2}:\d{2}(?::\d{2})?))/g;
  const parts = isImported ? null : message.split(regex);

  function handleClick(e) {
    const [start, end] = e.target.innerText.split('-');

    const [startMinutes, startSeconds] = start.split(':');
    let startSecondsTotal = Number(startMinutes) * 60 + Number(startSeconds);
    if (!end) {
      setPlaybackPosition(startSecondsTotal);
      return;
    }

    const [endMinutes, endSeconds] = end.split(':');
    let endSecondsTotal = Number(endMinutes) * 60 + Number(endSeconds);

    if (startSecondsTotal >= endSecondsTotal) {
      return;
    }
    if (startSecondsTotal < min) {
      startSecondsTotal = min;
    }
    if (endSecondsTotal > max) {
      endSecondsTotal = max;
    }

    setPlaybackPosition(startSecondsTotal);
    setLoopLowValue(startSecondsTotal);
    setLoopHighValue(endSecondsTotal);
  }

  return (
    <>
      {!isImported && <p>
        {parts.map((part, i) =>
          regex.test(part)
            ? <span
                key={i}
                onClick={handleClick}
                style={{ cursor: "pointer" }}
                className="link-primary"
              >
                {part}
              </span>
            : part
        )}
      </p>}
      {!!isImported && !!isSafe && <p dangerouslySetInnerHTML={{__html: message}} />}
    </>
  );
}