import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styles from "./Creator.module.scss";

import TutorialList, { TutorialSlider } from "../../component/tutorialList/TutorialList";
import {
  cretaeCreatorSubscription,
  removeCreatorSubscription,
} from "../../store/action/SubscribeAction";
import { getAccessToken } from "../../utils";
import TitleHelmet from "../../component/titleHelmet/TitleHelmet";

const guestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
async function queryItems(slug) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const curToken = getAccessToken(true);

  if (curToken) {
    config.headers.Authorization = "Bearer " + getAccessToken();
  }
  try {
    const ret = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/tutorials/creator/${slug}/`,
      config
    );
    const data = ret.data;
    return {
      data: data.results,
      count: data.count,
      creator: data.creator,
      learnedSongs: data.learned_songs ?? [],
    };
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err, error);
    return { data: [], artist: {}, count: 0 };
  }
}

function Creator() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { creatorSlug } = useParams();

  const [items, setItems] = useState([]);
  const [learnedSongs, setLearnedSongs] = useState([]);
  const [artist, setCreator] = useState({});
  const [selectedTab, setSelectedTab] = useState("All");
  const fetchStateRef = useRef({ isLoading: false, session: 0 });
  const { isLightMode } = useSelector((state) => state.toggle);
  const { subscribedCreators } = useSelector((state) => state.subscriptions);
  const [searchText, setSearchText] = useState("");
  async function searchItems(query, slug) {
    let url = `${process.env.REACT_APP_BACKEND_URL}/search/creatorsongs/`;
    try {
      const ret = await axios.post(url, { query, slug }, guestConfig);
      const data = ret.data;
      return { data: data.results, hasNext: !!data.next, count: data.count };
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      console.log(err, error);
      return { data: [], hasNext: false };
    }
  }
  const handleSearch = (e) => {
    setSelectedTab("All");
    if (searchText) {
      searchItems(searchText, creatorSlug).then((res) => setItems(res.data));
    }
    e.preventDefault();
    e.stopPropagation();
  };
  const reset = () => {
    setItems([]);
    fetchStateRef.current = {
      isLoading: false,
      session: fetchStateRef.current.session + 1,
    };
  };

  const fetchData = async (slug) => {
    if (fetchStateRef.current.isLoading) {
      return;
    }

    fetchStateRef.current = {
      isLoading: true,
      session: fetchStateRef.current.session,
    };
    const session = fetchStateRef.current.session;

    const ret = await queryItems(slug);

    if (fetchStateRef.current.session !== session) {
      return;
    }
    fetchStateRef.current = {
      isLoading: false,
      session: fetchStateRef.current.session,
    };

    if (ret.creator.name) {
      setItems(ret.data);
      setCreator(ret.creator);
      setLearnedSongs(ret.learnedSongs);
    }
  };

  useEffect(() => {
    reset();
    fetchData(creatorSlug);
  }, [creatorSlug]);

  return (
    <>
      {artist && artist.name && <TitleHelmet title={`Songmode - ${artist.name} Tutorials`} />}

      <div style={{float: "left"}} className="arrow-left">
        <a href="#0" onClick={history.goBack}>
          <img
            src={isLightMode ? "/img/back-light.svg" : "/icons/arrow-left.svg"}
            alt="img"
            className="img-fluid"
          />
        </a>
      </div>
      <div style={{marginTop: "10px"}} className="profile-item">
        <div className="media">
          {artist.artwork_medium && (
            <img
              src={artist.artwork_medium}
              alt="img"
              className="img-fluid profile-images"
            />
          )}
          <div className="media-body">
            <h2 className={styles.creatorName}>
              {artist.name}{" "}
              <img src="/img/green-check.png" alt="img" title="" />
            </h2>
            {artist.subscriber_cnt > 0 && (
              <p>{artist.subscriber_cnt} subscribers</p>
            )}
            <div className="button-item">
              <ul>
                <li>
                  {subscribedCreators.some((e) => e.id === artist.id) ? (
                    <button
                      className="subscribed"
                      onClick={() => {
                        const config = {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getAccessToken(),
                          },
                        };
                        const formData = new FormData();
                        formData.append("channelId", artist.id);
                        dispatch(removeCreatorSubscription(config, formData));
                      }}
                    >
                      Subscribed
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        const config = {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getAccessToken(),
                          },
                        };
                        const formData = new FormData();
                        formData.append("channelId", artist.id);
                        dispatch(cretaeCreatorSubscription(config, formData));
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="nav_body">
        <div className={`${styles.searchBar} search-bar`}>
          <form className="d-inline-block" onSubmit={handleSearch}>
            <input
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value.length < 1) {
                  fetchData(creatorSlug);
                }
              }}
              className="form-control"
              type="search"
              placeholder="Search songs by creator"
              aria-label="Search"
            />
            <button type="submit">
              <img
                src={
                  isLightMode ? "/img/search-light.png" : "/img/search.png"
                }
                alt="img"
                className="img-fluid"
              />
            </button>
          </form>
        </div>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setSelectedTab("All")}
            >
              All Songs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={() => setSelectedTab("Learned")}
            >
              Learned Songs
            </button>
          </li>
        </ul>
        <div className="tab-content tab-content-coustom" id="pills-tabContent">
          <div style={{margin: "0 -12px"}} className="tab-pane fade show active">
            {selectedTab === "All" && <TutorialSlider items={items} />}
            {selectedTab === "Learned" && <TutorialList items={learnedSongs} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Creator;
