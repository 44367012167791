import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import styles from "./BottomNavbar.module.scss";


function BottomNavbar({ style, isTutorial = false }) {
  const {get_user_info_success: user} = useSelector((state) => state.user);
  const {newSongsCount, newTutorialsCount } = useSelector((state) => state.subscriptions);

  return (
    <div style={style} className={`bottom-navbar ${styles.bottomNavbar}`} data-is-tutorial={isTutorial}>
      <ul className="container-flex nav-list">
        <li className="nav-item">
          <NavLink className="nav-link" to="/">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 14.9998H13M15 18.9998H5C3.93913 18.9998 2.92172 18.5784 2.17157 17.8282C1.42143 17.0781 1 16.0607 1 14.9998V8.70782C0.999986 8.02003 1.17732 7.34386 1.51487 6.74461C1.85242 6.14536 2.33879 5.64326 2.927 5.28682L7.927 2.25682C8.55211 1.87801 9.26907 1.67773 10 1.67773C10.7309 1.67773 11.4479 1.87801 12.073 2.25682L17.073 5.28682C17.6611 5.64317 18.1473 6.14511 18.4849 6.74417C18.8224 7.34324 18.9998 8.0192 19 8.70682V14.9998C19 16.0607 18.5786 17.0781 17.8284 17.8282C17.0783 18.5784 16.0609 18.9998 15 18.9998Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        </li>
        <li className="nav-item">
          <Link to={`/topsongs`} className="nav-link">
            <img
              src="/icons/fire.svg"
              alt="img"
              className="img-fluid"
            />
          </Link>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link " to="/following">
            <img
              src="/icons/bell.svg"
              alt="img"
              className="img-fluid"
            />{" "}
            {(newSongsCount + newTutorialsCount) > 0 && (
              <span className="text-white">{(newSongsCount + newTutorialsCount) > 9 ? "9+" : (newSongsCount + newTutorialsCount)}</span>
            )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link " to="/">
            <img
              src="/icons/music.svg"
              alt="img"
              className="img-fluid"
            />{" "}
            {/* <span className="text-white">{user?.like_count ?? 0}</span> */}
          </NavLink>
        </li>
        <li className="nav-item">
          {/* <NavLink className="nav-link " to={user ? "/profile/edit" : "/login"}> */}
          <NavLink className="nav-link " to={user ? `/users/${user.username}` : "/login"}>
            <img
              src="/img/user.svg"
              alt="img"
              className="img-fluid"
            />
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default BottomNavbar;
