import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { AUTH_SUCCESS } from '../../store/action/ActionType';
import { onLogin } from '../../store/action/AuthAction';
import { CartItems, CountCartedMusic } from '../../store/action/MusicAction';
import { getUserInfo } from '../../store/action/UserAction';

async function queryAuthUrl(provider) {
  try {
      const config = {
        params: {
          redirect_uri: window.location.origin + '/accounts/google/login/callback/'
        },
        withCredentials: true,
      }
      const ret = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/o/${provider}/`, config);
      const auth_url = ret.data.authorization_url;
      return auth_url;
  } catch(err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(err);
      return '';
  }
}

//const provider = 'google-oauth2';

async function postCode(provider, state, code, data) {
  try {
      const formData = new URLSearchParams()
      formData.append('state', state)
      formData.append('code', code)

      data = data || {};

      if ('username' in data) {
        formData.append('username', data.username);
      }

      if ('is_agreed' in data) {
        formData.append('is_agreed', data.is_agreed);
      }
      
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      };

      let url = `${process.env.REACT_APP_BACKEND_URL}/auth/o/${provider}/`;
      if ('partial_token' in data) {
        //formData.append('partial_token', data.partial_token);
        url += `?partial_token=${data.partial_token}`;
      } 

      const ret = await axios.post(url, formData, config);
      console.log(ret.data);
      return ret.data;
  } catch(err) {
      const error =  err.response ? err.response.data  : "networkError"
      console.log(err);
      return { error };
  }
}

// const popup = (url) => {
//   const windowArea = {
//     width: Math.floor(window.outerWidth * 0.8),
//     height: Math.floor(window.outerHeight * 0.5),
//   };

//   if (windowArea.width < 1000) { windowArea.width = 1000; }
//   if (windowArea.height < 630) { windowArea.height = 630; }
//   windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2));
//   windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8));

//   const sep = (url.indexOf('?') !== -1) ? '&' : '?';
//   url = `${url}${sep}`;
//   const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
//     width=${windowArea.width},height=${windowArea.height},
//     left=${windowArea.left},top=${windowArea.top}`;

//   const authWindow = window.open(url, 'producthuntPopup', windowOpts);
//   // Create IE + others compatible event handler
//   const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
//   const eventer = window[eventMethod];
//   const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

//   // Listen to message from child window
//   const authPromise = new Promise((resolve, reject) => {
//     eventer(messageEvent, (e) => {
//       if (e.origin !== window.location.origin) {
//         return;
//       }

//       if (e.data.error) {
//         reject('Unauthorised');
//       } else {
//         resolve(e.data);
//       }

//       authWindow.close();

//     }, false);
//   });

//   return authPromise;
// };

async function handleStateCode(provider, state, code, dispatch, history) {

  const data = await postCode(provider, state, code);
    
  if (data.access) {
    onLogin(dispatch, history, { ...data, email: data.user, is_superuser: false });
  } else if (data.partial_token) {
    window.socialAuth = { provider, state, code, ...data };
    history.push('/complete-sign-up');
  } else {
    //TODO: Show error snack bar
  }
}


function SocialLogin() {

  

  const handleLogin = async (provider) => {
    let authUrl = await queryAuthUrl(provider);

    const sep = (authUrl.indexOf('?') !== -1) ? '&' : '?';
    authUrl = `${authUrl}${sep}`;

    window.location = authUrl;

    // const { state, code } = await popup(authUrl);
    ////...
    ////handleStateCode(provider, state, code, dispatch, history);
  }

  return (
    <div style={{margin: '0 -5px 32px'}}>
      <GoogleLoginButton text="Log in with Google" align="center" style={{height: 56, fontWeight: 600, fontSize: 16, padding: '0 50px 0 24px', borderRadius: 8 }} onClick={e=>handleLogin('google-oauth2')} size={36} />
      {/* <FacebookLoginButton onClick={() => alert("Hello")} size={36} /> */}
    </div>
  )
}

export default SocialLogin
export { handleStateCode, postCode }