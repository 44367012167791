import {
  CREATE_ARTIST_SUBSCRIPTION_START,
  CREATE_ARTIST_SUBSCRIPTION_SUCCESS,
  CREATE_ARTIST_SUBSCRIPTION_FAIL,
  CREATE_CREATOR_SUBSCRIPTION_START,
  CREATE_CREATOR_SUBSCRIPTION_SUCCESS,
  CREATE_CREATOR_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_LIST_START,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAIL,
  REMOVE_ARTIST_SUBSCRIPTION_START,
  REMOVE_ARTIST_SUBSCRIPTION_SUCCESS,
  REMOVE_ARTIST_SUBSCRIPTION_FAIL,
  REMOVE_CREATOR_SUBSCRIPTION_START,
  REMOVE_CREATOR_SUBSCRIPTION_SUCCESS,
  REMOVE_CREATOR_SUBSCRIPTION_FAIL,
  GET_SUBSCRIBED_ITEMS_START,
  GET_SUBSCRIBED_ITEMS_SUCCESS,
  GET_SUBSCRIBED_ITEMS_FAIL,
  UPDATE_LAST_NOTIFIED_START,
  UPDATE_LAST_NOTIFIED_SUCCESS,
  UPDATE_LAST_NOTIFIED_FAIL,
} from "../action/ActionType";

const initialState = {
  subscriberListLoading: false,
  subscribeLoading: false,
  subscribedCountLoading: false,
  subscribedCreators: [],
  subscribedArtists: [],
  subscriptionSongs: [],
  subscriptionTutorials: [],
  subscribedItemsListLoading: false,
  newSongsCount: 0,
  newTutorialsCount: 0,
};

const SubscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LAST_NOTIFIED_START:
      return { ...state, subscribedCountLoading: true };
    case UPDATE_LAST_NOTIFIED_SUCCESS:
      // console.log(action.data);
      if (action.data.updated_type === "notified_tutorials_at") {
        return {
          ...state,
          newTutorialsCount: 0,
          subscribedCountLoading: false,
        };
      }
      if (action.data.updated_type === "notified_songs_at") {
        return {
          ...state,
          newSongsCount: 0,

          subscribedCountLoading: false,
        };
      }
      return {
        ...state,
        subscribedCountLoading: false,
      };
    case UPDATE_LAST_NOTIFIED_FAIL:
      return {
        ...state,
        subscribedCountLoading: false,
      };
    case GET_SUBSCRIPTION_LIST_START:
      return { ...state, subscriberListLoading: true };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      // console.log(action.data);
      return {
        ...state,
        subscribedCreators: action.data.creator_subscription,
        subscribedArtists: action.data.artist_subscription,
        subscriberListLoading: false,
      };
    case GET_SUBSCRIPTION_LIST_FAIL:
      return {
        ...state,
        subscriberListLoading: false,
      };
    case GET_SUBSCRIBED_ITEMS_START:
      return { ...state, subscribedItemsListLoading: true };
    case GET_SUBSCRIBED_ITEMS_SUCCESS:
      // console.log(action.data);
      return {
        ...state,
        subscriptionSongs: action.data.subscribed_songs,
        subscriptionTutorials: action.data.subscribed_turorials,
        newSongsCount: action.data.song_count,
        newTutorialsCount: action.data.tutorial_count,
        subscribedItemsListLoading: false,
      };
    case GET_SUBSCRIBED_ITEMS_FAIL:
      return {
        ...state,
        subscribedItemsListLoading: false,
      };
    case CREATE_ARTIST_SUBSCRIPTION_START:
      return { ...state, subscribeLoading: true };
    case CREATE_ARTIST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscribedArtists: [...state.subscribedArtists, action.data.artist],
        subscribeLoading: false,
      };
    case CREATE_ARTIST_SUBSCRIPTION_FAIL:
      return {
        ...state,
        subscribeLoading: false,
      };
    case CREATE_CREATOR_SUBSCRIPTION_START:
      return { ...state, subscribeLoading: true };
    case CREATE_CREATOR_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscribedCreators: [...state.subscribedCreators, action.data.creator],
        subscribeLoading: false,
      };
    case CREATE_CREATOR_SUBSCRIPTION_FAIL:
      return {
        ...state,
        subscribeLoading: false,
      };
    case REMOVE_ARTIST_SUBSCRIPTION_START:
      return { ...state, subscribeLoading: true };
    case REMOVE_ARTIST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscribedArtists: state.subscribedArtists.filter(
          (e) => e.id != action.data.artist_id
        ),
        subscribeLoading: false,
      };
    case REMOVE_ARTIST_SUBSCRIPTION_FAIL:
      return {
        ...state,
        subscribeLoading: false,
      };
    case REMOVE_CREATOR_SUBSCRIPTION_START:
      return { ...state, subscribeLoading: true };
    case REMOVE_CREATOR_SUBSCRIPTION_SUCCESS:
      // console.log(action.data);
      return {
        ...state,
        subscribedCreators: state.subscribedCreators.filter(
          (e) => e.id != action.data.channel_id
        ),
        subscribeLoading: false,
      };
    case REMOVE_CREATOR_SUBSCRIPTION_FAIL:
      return {
        ...state,
        subscribeLoading: false,
      };
    default:
      return state;
  }
};

export default SubscribeReducer;
