import React, {Fragment} from "react";

import {NavLink} from "react-router-dom";
import Difficulty from "../difficulty/Difficulty";
import {Viewers} from "../../utils";

import styles from "./TutorialItem.module.scss";
import {useSelector} from "react-redux";

function TutorialItem({tutorial}) {
  const {isLightMode} = useSelector((state) => state.toggle);

  return (
    <div className="slider">
      <div className="ex-a d-block">
        <NavLink
          to={`/tutorials/${tutorial.slug}-${tutorial.id}`}
          className="box-hover d-block"
        >
          <span className="overlay d-block"></span>
          <img
            src={tutorial.thumbnail}
            alt={tutorial.videoTitle}
            className="img-fluid shadow d-block"
          />
          <span className="piano-shadow d-block">
            {/* <img src="/img/Piano.svg" alt="" className="img-fluid d-block" /> */}
          </span>
        </NavLink>
        <span className="title-item d-block">
          <NavLink
            to={`/tutorials/${tutorial.slug}-${tutorial.id}`}
            className="songstitle d-block"
          >
            {/* {formatTitle(tutorial.videoTitle)[0]}
            <br/>
            {formatTitle(tutorial.videoTitle)[1]} */}
            {tutorial.song?.name}
          </NavLink>
          <span className="singer-name d-block">
            {tutorial.song?.artists.map((aa, aaidx) => (
              <Fragment key={aa.id}>
                {aaidx > 0 && ", "}
                <NavLink to={`/artists/${aa.slug}`}>{aa.name}</NavLink>
              </Fragment>
            ))}
          </span>
          <span className={`singer-name d-block ${styles.channelName}`}>
            <NavLink to={`/creators/${tutorial.channelSlug}`}>
                <img
                  src={tutorial.channelArtworkSmall}
                  alt="img"
                  className="img-fluid profile-images"
                  style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: isLightMode ? "#E9E9ED" : "#2C1C37",
                    borderRadius: "50%",
                    marginRight: '5px',
                  }}
                  onError={({currentTarget}) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = isLightMode ? '/img/user-new.svg' : '/img/user.svg';
                  }}
                />
              {tutorial.channelName}
            </NavLink>
          </span>
          <div className="viewers-holder">
            <Viewers count={tutorial.views}/>
            {/*<Stars level={tutorial.level} isLightMode={isLightMode} />*/}
          </div>
          <Difficulty level={tutorial.level} />
        </span>
      </div>
      {/* <ul>
          <li><a href="button">Jazz</a></li>
        </ul> */}
    </div>
  );
}

export default TutorialItem;
