import * as PropTypes from "prop-types";
import React from "react";
import {useSelector} from "react-redux";

const FadeoutDiv = props => {
  const {isLightMode} = useSelector((state) => state.toggle);
  return <div className={"fadeout"} style={{
    backgroundImage: `linear-gradient(to right, ${isLightMode ? "#FFFFFF" : "#171a1f"}, transparent, transparent, transparent, transparent, ${isLightMode ? "#FFFFFF" : "#171a1f"})`,
    position: "absolute",
    height: "100%",
    width: "calc(100% + 10px)",
    zIndex: 2,
    left: -5,
    top: 0,
    pointerEvents: "none",
    ...props.style,
  }}/>
};

export default FadeoutDiv;

FadeoutDiv.propTypes = {};