const initialState = localStorage.getItem("viewPreference") || "carousel";

function ViewPreferenceReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_VIEW_PREFERENCE':
      return action.payload;
    default:
      return state;
  }
};

export default ViewPreferenceReducer;
