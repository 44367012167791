import React, { lazy, useEffect } from "react";
import HomePage from "./container/HomePage";
//import EmailVarification from './container/EmailVarification'
import ResetPasswordConfirm from "./container/ResetPasswordConfirm";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "./store/action/UserAction";
import { AuthenticRoute, IsAdminRoute } from "./PrivateRoute";
import TermsAndCondition from "./component/termsAndCondition/TermsAndCondition";
import ScrollToTop from "./ScrollToTop";
import { Helmet } from "react-helmet";
import Playlist from "./component/playlist/Playlist";
import OAuth2Redirect from "./container/OAuth2Redirect";
import Artist from "./container/artist/Artist";
import {
  getAccessToken,
  setDarkTheme,
  setLightTheme,
  updateTokenChecker,
} from "./utils";
import TutorialLayout from "./layouts/tutorial/TutorialLayout";
import VideoTutorial from "./container/videoTutorial/VideoTutorial";
import AuthLayout from "./layouts/auth/AuthLayout";
import SignUp from "./container/auth/signUp/SignUp";
import Login from "./container/auth/login/Login";
import ResetPassword from "./container/auth/resetPassword/ResetPassword";
import SpotifySong from "./container/spotifySong/SpotifySong";
import ProfileLayout from "./layouts/profile/ProfileLayout";
import EditProfile from "./container/profile/editProfile/EditProfile";
import ChangePassword from "./container/profile/changePassword/ChangePassword";
import ArtistList from "./container/artistList/ArtistList";
import SearchTutorials from "./container/searchSong/SearchSong";
import ViewProfile from "./container/profile/viewProfile/ViewProfile";
import CompleteSignUp from "./container/auth/completeSignUp/CompleteSignUp";
import Creator from "./container/creator/Creator";
import {
  getSubscribedItems,
  getSubscriptionList,
} from "./store/action/SubscribeAction";
import TopSongs from "./container/topSongs/TopSongs";
import Landing from "./container/landing/Landing";

import styles from "./App.module.scss";
import AllArtists from "./container/allArtists/AllArtists";
import PWAInstallModal from "./container/modals/PWAInstallModal";


const Admin = lazy(() => import("./container/admin/Admin"));
const MusicList = lazy(() => import("./container/MusicList"));
const UpdateMusic = lazy(() => import("./container/updateMusic/UpdateMusic"));
const UploadMusicMenually = lazy(() =>
  import("./container/uploadMusicMenually/UploadMusicMenually")
);
const CreateTermsAndCondition = lazy(() =>
  import("./container/createTermsAndCondition/CreateTermsAndCondition")
);

const CreateAdd = lazy(() => import("./container/createAdd/CreateAdd"));

const ChordsEditor = lazy(() => import("./admin/chordsEditor/ChordsEditor"));
const Reconciliation = lazy(() => import("./admin/reconciliation/Reconciliation"));
const MatchingReconciliation = lazy(() => import("./admin/reconciliation/MatchingReconciliation"));

function App() {
  const dispatch = useDispatch();
  const { isLightMode } = useSelector((state) => state.toggle);

  useEffect(() => {
    const curToken = getAccessToken(true);

    if (curToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      };
      dispatch(getUserInfo(config));
      dispatch(getSubscriptionList(config));
      dispatch(getSubscribedItems(config));
    }

    updateTokenChecker(dispatch, false, !curToken);
  }, []);
  useEffect(() => {
    if (isLightMode) {
      setLightTheme();
    } else {
      setDarkTheme();
    }
  }, [isLightMode]);

  return (
    <div className="App">
      <div className={styles.primbg}></div>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login">
            <AuthLayout>
              <Login />
            </AuthLayout>
          </Route>
          <Route exact path="/sign-up">
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          </Route>
          <Route exact path="/complete-sign-up">
            <AuthLayout>
              <CompleteSignUp />
            </AuthLayout>
          </Route>
          <Route exact path="/reset-password">
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          </Route>
          <Route exact path="/profile/edit">
            <ProfileLayout>
              <EditProfile />
            </ProfileLayout>
          </Route>
          <Route exact path="/profile/password">
            <ProfileLayout>
              <ChangePassword />
            </ProfileLayout>
          </Route>
          <Route exact path="/users/:uname">
            <ProfileLayout>
              <ViewProfile />
            </ProfileLayout>
          </Route>
          <IsAdminRoute
            exact
            path="/uploadsong"
            component={UploadMusicMenually}
          />
          <IsAdminRoute
            exact
            path="/createabout"
            component={CreateTermsAndCondition}
          />
          <IsAdminRoute exact path="/add" component={CreateAdd} />
          <IsAdminRoute exact path="/admin" component={Admin} />
          <IsAdminRoute exact path="/musiclist" component={MusicList} />
          <IsAdminRoute
            exact
            path="/updatemusic/:songId"
            component={UpdateMusic}
          />
          <IsAdminRoute exact path="/admin/chords-editor/:slug" component={ChordsEditor} />
          <IsAdminRoute exact path="/admin/reconciliation" component={Reconciliation} />
          <IsAdminRoute exact path="/admin/matching-reconciliation" component={MatchingReconciliation} />
          {/* <Route exact path='/activate/:uid/:token' component={EmailVarification} /> */}
          <Route exact path="/lists/:slug">
            <TutorialLayout>
              <Playlist infinite={true} />
            </TutorialLayout>
          </Route>
          <Route exact path="/landing">
            <TutorialLayout isLanding={true}>
              <Landing />
            </TutorialLayout>
          </Route>
          <Route exact path="/topsongs">
            <TutorialLayout>
              <TopSongs />
            </TutorialLayout>
          </Route>
          <Route exact path="/songs/:slug">
            <TutorialLayout>
              <SpotifySong />
            </TutorialLayout>
          </Route>
          <Route exact path="/tutorials/:slug">
            <TutorialLayout isTutorial={true}>
              <VideoTutorial />
            </TutorialLayout>
          </Route>
          <Route exact path="/all-artists">
            <Redirect to="/all-artists/A" />
          </Route>
          <Route exact path="/all-artists/:letter">
            <TutorialLayout>
              <AllArtists />
            </TutorialLayout>
          </Route>
          <Route exact path="/artists">
            <TutorialLayout>
              <ArtistList />
            </TutorialLayout>
          </Route>
          <Route exact path="/artists/:artistSlug">
            <TutorialLayout>
              <Artist />
            </TutorialLayout>
          </Route>
          <Route exact path="/creators/:creatorSlug">
            <TutorialLayout>
              <Creator />
            </TutorialLayout>
          </Route>
          <Route exact path="/contactus">
            <TutorialLayout>
              <TermsAndCondition page="contactUs" />
            </TutorialLayout>
          </Route>
          <Route exact path="/">
            <TutorialLayout isHome={true}>
              <HomePage />
            </TutorialLayout>
          </Route>
          <Route exact path="/following">
            <TutorialLayout isHome={true}>
              <HomePage />
            </TutorialLayout>
          </Route>
          <Route exact path="/activate/:uid/:code">
            <TutorialLayout>
              <HomePage />
            </TutorialLayout>
          </Route>
          <Route exact path="/accounts/google/login/callback/">
            <OAuth2Redirect provider="google-oauth2"/>
          </Route>
          <Route exact path="/search/tutorials/:query">
            <TutorialLayout>
              <SearchTutorials />
            </TutorialLayout>
          </Route>
          <Route exact path="/about">
            <TutorialLayout showSynthesia={true}>
              <TermsAndCondition page="aboutUs" />
            </TutorialLayout>
          </Route>
          <Route exact path="/legal">
            <TutorialLayout showSynthesia={true}>
              <TermsAndCondition page="legal" />
            </TutorialLayout>
          </Route>
          <Route exact path="/termsandcondition">
            <TutorialLayout showSynthesia={true}>
              <TermsAndCondition page="termsAndCondition" />
            </TutorialLayout>
          </Route>
          <Route exact path="/privacypolicy">
            <TutorialLayout showSynthesia={true}>
              <TermsAndCondition page="privacyPolicy" />
            </TutorialLayout>
          </Route>
          <Route exact path="/password/reset/confirm/:token">
            <TutorialLayout>
              <ResetPasswordConfirm />
            </TutorialLayout>
          </Route>
        </Switch>
      </Router>
      <PWAInstallModal />
    </div>
  );
}

export default App;
