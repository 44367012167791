import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  GET_USER_INFO_SUCCESS,
  SET_MUSIC,
} from "../../store/action/ActionType";
import { updateTokenChecker } from "../../utils";

//import styles from './TutorialLayout.module.scss'
import SongmodeHeader from "../header/SongmodeHeader";
import SideFooter from "../sideFooter/SideFooter";

function ProfileLayout({ children }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isMenuExpanded: isSidebarExpanded, isMobileExpanded } = useSelector(
    (state) => state.music
  );
  const { isLightMode } = useSelector((state) => state.toggle);

  const collapseSidebar = () => {
    if (isMobileExpanded) {
      dispatch({ type: SET_MUSIC, data: { isMobileExpanded: false } });
    }
  };

  const handleLogout = (evt) => {
    evt.preventDefault();

    updateTokenChecker(dispatch, true);

    dispatch({
      type: GET_USER_INFO_SUCCESS,
      data: null,
    });

    goToHome();
  };
  async function goToHome() {
    await new Promise((resolve) =>
      setTimeout(() => {
        const access_token = localStorage.getItem("access_token");
        if (!access_token) {
          history.push("/");
        }
      }, 1000)
    );
  }

  useEffect(() => {
    collapseSidebar();
  }, [location.pathname, location.search]);

  return (
    <div
      className={
        isSidebarExpanded || isMobileExpanded ? "" : "collapses-left-item"
      }
    >
      <SongmodeHeader isProfile={true} />
      <div className="wrapper item-edit-profile-left">
        <div className="container-fluid">
          <div
            id="mySidenav"
            className="sidenav "
            style={{ width: isMobileExpanded ? "100%" : "" }}
          >
            <button
              className="closebtn d-lg-none d-inline-block"
              onClick={collapseSidebar}
            >
              &times;
            </button>
            <div className="left-item ">
              <div className="inner-left-item">
                <div className="item1 ">
                  <ul>
                    <li>
                      <a href="#0" onClick={handleLogout}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.4">
                            <path
                              d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z"
                              stroke={isLightMode ? "black" : "white"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                              stroke={isLightMode ? "black" : "white"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11"
                              stroke={isLightMode ? "black" : "white"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                        <span className="hide-leftText">Logout</span>
                      </a>
                    </li>
                    <li>
                      <NavLink to="/profile/edit">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 20.0001H8L18.5 9.50006C19.0304 8.96963 19.3284 8.2502 19.3284 7.50006C19.3284 6.74991 19.0304 6.03049 18.5 5.50006C17.9696 4.96963 17.2501 4.67163 16.5 4.67163C15.7499 4.67163 15.0304 4.96963 14.5 5.50006L4 16.0001V20.0001Z"
                            stroke={isLightMode ? "black" : "white"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.5 6.5L17.5 10.5"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="hide-leftText">Edit Profile</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/profile/password">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.4">
                            <path
                              d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z"
                              stroke={isLightMode ? "black" : "white"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                              stroke={isLightMode ? "black" : "white"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11"
                              stroke={isLightMode ? "black" : "white"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>

                        <span className="hide-leftText">Change Password</span>
                      </NavLink>
                    </li>
                    {/* <li>
                        <a href="">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                    <path d="M10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C15.1484 5.54303 16.1274 6.38833 16.8321 7.4453C17.5367 8.50227 17.9404 9.73107 18 11V14C18.0753 14.6217 18.2954 15.2171 18.6428 15.7381C18.9902 16.2592 19.4551 16.6914 20 17H4C4.54494 16.6914 5.00981 16.2592 5.35719 15.7381C5.70457 15.2171 5.92474 14.6217 6 14V11C6.05956 9.73107 6.4633 8.50227 7.16795 7.4453C7.8726 6.38833 8.85159 5.54303 10 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 17V18C9 18.7956 9.31607 19.5587 9.87868 20.1213C10.4413 20.6839 11.2044 21 12 21C12.7956 21 13.5587 20.6839 14.1213 20.1213C14.6839 19.5587 15 18.7956 15 18V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                            </svg>
                            
                            <span className="hide-leftText"> Notifications Settings</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                    <path d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                            </svg>
                            
                            <span className="hide-leftText">Privacy Settings</span>
                        </a>
                    </li> */}
                  </ul>
                </div>

                <SideFooter useMobileBottom={false} />
              </div>
            </div>
          </div>
          <div className="mide-item">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default ProfileLayout;
