import React, { useEffect, useState } from 'react';

import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { UPDATE_FORGET_PASSWORD_REQUEST_STATUS } from '../../../store/action/ActionType';
import { RequestResetPassword } from '../../../store/action/AuthAction';
import MobilePagesFooter from '../../../component/mobilePagesFooter/MobilePagesFooter';
import TitleHelmet from '../../../component/titleHelmet/TitleHelmet';


const ResetPassword = () => {

  const dispatch = useDispatch()

  const { forgetPasswordRequestSuccess, forgetPasswordRequestFail } = useSelector(state => state.auth)

  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(RequestResetPassword(email))
  }

  useEffect(() => {
    return () => {
        dispatch({ type: UPDATE_FORGET_PASSWORD_REQUEST_STATUS })
    };
  }, [dispatch]);

  if (forgetPasswordRequestSuccess) {
    return (
      <>
        <h1>Reset Password</h1>
        <p className="text-center">Please check your email to reset password</p>
      </>
    );
  }

  return (
    <>
      <TitleHelmet title={`Songmode - Reset Password`} />
      <h1>Reset Password</h1>
      <p className="text-center">Please enter your email address.</p>
      <form onSubmit={handleSubmit}>
          <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
              <input type="email" value={email} onChange={(evt)=>setEmail(evt.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" required/>
              <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{forgetPasswordRequestFail && forgetPasswordRequestFail.data && forgetPasswordRequestFail.data.email && forgetPasswordRequestFail.data.email}</span>
          </div>
          
          <button type="submit" className="btn">Send Reset Password Link</button>
          <p>Remember your password? <NavLink to="/login">Login</NavLink></p>
      </form>
      <MobilePagesFooter />
    </>
  );
};

export default ResetPassword;