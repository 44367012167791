import React from 'react'
import { Helmet } from 'react-helmet'


function TitleHelmet({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="Get chord progressions to many songs! Learn thousands of songs fast!  Simplified interactive chord progressions, Hooks (Using Youtube Piano Content)" />
    </Helmet>
  )
}

export default TitleHelmet
