import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { requestMusic } from "../../store/action/AdminAction";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { SongSlider } from "../../component/songList/SongList";
import {
  cretaeArtistSubscription,
  removeArtistSubscription,
} from "../../store/action/SubscribeAction";
import { getAccessToken, getAccessConfig } from "../../utils";
import FilterButton from "../../component/filterButton/FilterButton";
import { Viewers } from "../../utils";
import styles from "./Artist.module.scss";
import SongsTable from "../../component/songsTable/SongsTable";
import useWindowSize from "../../hooks/useWindowSize";


const guestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
async function queryItems(slug, params) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    params,
  };
  const curToken = getAccessToken(true);

  if (curToken) {
    config.headers.Authorization = "Bearer " + getAccessToken();
  }
  try {
    const ret = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/songs/artist/${slug}/`,
      config
    );
    const data = ret.data;
    return {
      data: data.results,
      count: data.count,
      artist: data.artist,
      learnedSongs: data.learned_songs ?? [],
    };
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err, error);
    return { data: [], artist: {}, count: 0, learnedSongs: [] };
  }
}

function Artist() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistSlug } = useParams();
  const { accessToken } = useSelector((state) => state.auth);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const viewPreference = useSelector((state) => state.viewPreference);

  const requestSong = (songItem) => {
    if (accessToken) {
      dispatch(requestMusic(songItem.spotify_id, searchText, accessToken));
    } else {
      history.push("/login");
    }
  };

  const { searchItemStatus } = useSelector((state) => state.music);
  const getSongStatus = (item) => {
    return searchItemStatus[item.spotify_id] || "new";
  };

  const [items, setItems] = useState([]);
  const [learnedSongs, setLearnedSongs] = useState([]);
  const [artist, setArtist] = useState({});
  const [selectedTab, setSelectedTab] = useState("All");
  const fetchStateRef = useRef({ isLoading: false, session: 0 });
  const { isLightMode } = useSelector((state) => state.toggle);
  const { subscribedArtists } = useSelector((state) => state.subscriptions);
  const [searchText, setSearchText] = useState("");
  async function searchItems(query, artist, page) {
    let url = `${process.env.REACT_APP_BACKEND_URL}/search/artistsongs/`;
    try {
      const ret = await axios.post(url, { query, page, artist }, guestConfig);
      const data = ret.data;
      return { data: data.results, hasNext: !!data.next, count: data.count };
    } catch (err) {
      const error = err.response ? err.response : "networkError";
      console.log(err, error);
      return { data: [], hasNext: false };
    }
  }
  const handleSearch = (e) => {
    if (searchText) {
      setSelectedTab("All");
      setTimeFilter('');
      searchItems(searchText, artist.name, 1).then((res) => setItems(res.data));
      setShowSearchedSongs(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const reset = () => {
    setItems([]);
    setLearnedSongs([]);
    fetchStateRef.current = {
      isLoading: false,
      session: fetchStateRef.current.session + 1,
    };
  };

  const fetchData = async (slug) => {
    if (fetchStateRef.current.isLoading) {
      return;
    }

    fetchStateRef.current = {
      isLoading: true,
      session: fetchStateRef.current.session,
    };
    const session = fetchStateRef.current.session;

    const ret = await queryItems(slug);

    if (fetchStateRef.current.session !== session) {
      return;
    }
    fetchStateRef.current = {
      isLoading: false,
      session: fetchStateRef.current.session,
    };

    if (ret.artist.name) {
      setItems(ret.data);
      setArtist(ret.artist);
      setLearnedSongs(ret.learnedSongs);
    }
  };

  useEffect(() => {
    reset();
    fetchData(artistSlug);
  }, [artistSlug]);

  const [showSearchedSongs, setShowSearchedSongs] = useState(false);
  const [timeFilter, setTimeFilter] = useState('');
  const toggleTimeFilter = (value) => {
    setSelectedTab("All");
    setSearchText('');
    setShowSearchedSongs(false);
    const newVal = value === timeFilter ? '' : value;
    setTimeFilter(newVal);
  }

  const [allSongs, setAllSongs] = useState([]);
  useEffect(() => {
    if (!artist.id) return;
    async function getFilteredSongs() {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/songs/topsongs/`;
        const ret = await axios.get(url, {
          ...getAccessConfig(),
          params: {
            time: timeFilter || null,
            artist: artist.id || null,
          },
        });
        setAllSongs(ret.data.topSongs);
      } catch (err) {
        const error = err.response ? err.response : "networkError";
        console.log(err, error);
        return false;
      }
    }
    getFilteredSongs();
  }, [timeFilter, artist.id]);

  let countryUrl = '';
  let cityUrl = '';
  if (artist.country) {
    countryUrl = `/artists?country=${artist.country.slug}`;
    if (artist.city) {
      cityUrl = countryUrl + `&city=${artist.city.slug}`;
    }
  }

  return (
    <>
      {artist && artist.slug && <Helmet>
        <title>{`${artist.name} - Songmode`}</title>
        <meta
          name="description"
          content={`Get chord progressions to songs by "${artist.name}"! Learn songs fast!  Simplified interactive chord progressions, Hooks (Using Youtube Piano Content)`}
        />
      </Helmet>}

      <div style={{float: "left"}} className="arrow-left">
        <a href="#0" onClick={history.goBack}>
          <img
            src={isLightMode ? "/img/back-light.svg" : "/icons/arrow-left.svg"}
            alt="img"
            className="img-fluid"
          />
        </a>
      </div>
      <div style={{marginTop: "10px"}} className="profile-item">
        <div className="media">
          {artist.artwork_medium && (
            <img
              src={artist.artwork_medium}
              alt="img"
              className="img-fluid profile-images"
            />
          )}
          <div className="media-body">
            <h2 className={styles.artistName}>
              {artist.name}{" "}
              <img src="/img/green-check.png" alt="img" title="" />
            </h2>
            {(cityUrl || countryUrl) && <div className={styles.artistLocation}>
              <img src="/img/location.png" alt="Location" width="20" height="20" />
              {cityUrl && <><NavLink to={cityUrl}>{artist.city.name}</NavLink>,&nbsp;</>}
              {countryUrl && <NavLink to={countryUrl}>{artist.country.name}</NavLink>}
            </div>}
            {artist.subscriber_cnt > 0 && (
              <p>{artist.subscriber_cnt} subscribers</p>
            )}
            <div style={{ marginTop: 10 }} className="button-item">
              <ul>
                <li>
                  {subscribedArtists.some((e) => e.id === artist.id) ? (
                    <button
                      className="subscribed"
                      onClick={() => {
                        const config = {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getAccessToken(),
                          },
                        };
                        const formData = new FormData();
                        formData.append("artistId", artist.id);
                        dispatch(removeArtistSubscription(config, formData));
                      }}
                    >
                      Subscribed
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        const config = {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getAccessToken(),
                          },
                        };
                        const formData = new FormData();
                        formData.append("artistId", artist.id);
                        dispatch(cretaeArtistSubscription(config, formData));
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                </li>
                {/* {artist.genres &&
                  artist.genres.map((gg) => (
                    <li key={gg.slug}>
                      <NavLink to={`/artists?genre=${gg.slug}`}>
                        {gg.name}
                      </NavLink>
                    </li>
                  ))} */}
              </ul>
              {/* <div
                style={{
                  marginTop: "20px",
                }}
                className="big-search"
              >
                <div className="search-bar">
                  <form className="d-inline-block" onSubmit={handleSearch}>
                    <input
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        if (e.target.value.length < 1) {
                          fetchData(artistSlug);
                        }
                      }}
                      className="form-control"
                      type="search"
                      placeholder="Search songs of artist"
                      aria-label="Search"
                    />
                    <button type="submit">
                      <img
                        src={
                          isLightMode
                            ? "/img/search-light.png"
                            : "/img/search.png"
                        }
                        alt="img"
                        className="img-fluid"
                      />
                    </button>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="nav_body">
        <div className={`${styles.searchBar} search-bar`}>
          <form className="d-inline-block" onSubmit={handleSearch}>
            <input
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value.length < 1) {
                  fetchData(artistSlug);
                  setShowSearchedSongs(false);
                }
              }}
              value={searchText}
              className="form-control"
              type="search"
              placeholder={`Search songs from ${artist.name ?? "this artist"}`}
              aria-label="Search"
            />
            <button type="submit">
              <img
                src={
                  isLightMode ? "/img/search-light.png" : "/img/search.png"
                }
                alt="img"
                className="img-fluid"
              />
            </button>
          </form>
        </div>
        <ul className={`nav nav-pills mb-3 ${styles.tabList}`} id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${selectedTab === "All" ? "active" : ""}`}
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setSelectedTab("All")}
            >
              All Songs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                selectedTab === "Learned" ? "active" : ""
              }`}
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={() => setSelectedTab("Learned")}
            >
              Learned Songs
            </button>
          </li>
          <li className={`nav-item ${styles.filterButton}`} role="presentation">
            <FilterButton
              timeFilter={timeFilter}
              toggleTimeFilter={toggleTimeFilter}
            />
          </li>
        </ul>
        <div className="tab-content tab-content-coustom" id="pills-tabContent">
          <div className={`${styles.songsContainer} tab-pane fade show active`}>
            {selectedTab === "All" && (
              (timeFilter === '' && viewPreference === "carousel" && isMobile) ? (
                <SongSlider
                  items={showSearchedSongs ? items : allSongs}
                  requestSong={requestSong}
                  getSongStatus={getSongStatus}
                />
              ) : (
                <SongsTable songs={timeFilter === '' && showSearchedSongs ? items : allSongs} isRanked />
              )
            )}
            {selectedTab === "Learned" && viewPreference === "carousel" && isMobile && <SongSlider items={learnedSongs} loop={false} />}
            {selectedTab === "Learned" && (viewPreference !== "carousel" || !isMobile) && <SongsTable songs={learnedSongs} />}
            {items.length === 0 && searchText.length > 0 && (
              <span className={styles.noResults}>No results were found</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Artist;
