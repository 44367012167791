import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux'

import { NavLink } from 'react-router-dom';
import { UPDATE_REGISTRATION_STATUS } from '../../../store/action/ActionType';
import { Registration } from '../../../store/action/AuthAction';
import SocialLogin from '../../socialLogin/SocialLogin';
import MobilePagesFooter from '../../../component/mobilePagesFooter/MobilePagesFooter';
import TitleHelmet from '../../../component/titleHelmet/TitleHelmet';

const SignUp = () => {

  const dispatch = useDispatch()

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);

  const { registrationSuccessStatus, registrationFail } = useSelector(state => state.auth)

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('is_agreed', isAgreed);
    dispatch(Registration(formData));
  }

  useEffect(() => {
    return () => {
      dispatch({
        type: UPDATE_REGISTRATION_STATUS
      });
    };
  }, [dispatch]);

  if (registrationSuccessStatus) {
    return (
      <>
        <h1>Create Account</h1>
        <p className="text-center">An account has been created and activation link has been sent to this email: "{registrationSuccessStatus.email}".</p>
      </>
    )
  }

  return (
    <>
      <TitleHelmet title={`Songmode - Sign-up`} />
      <h1>Create Account</h1>
      <p className="text-center">Sign up for an account</p>
      <SocialLogin />
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="Name" className="form-label">Username</label>
          <input type="name" value={username} onChange={(evt)=>setUsername(evt.target.value)} className="form-control" id="Name" aria-describedby="emailHelp" placeholder="Enter unique Username" required/>
          <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.username && (registrationFail.data.username[0] ==='new users with this Username already exists.'?'This Username is not available' : registrationFail.data.username)}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
          <input type="email" value={email} onChange={(evt)=>setEmail(evt.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" required/>
          <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.email && registrationFail.data.email.map(err => err.replace('new users ', 'User '))}</span>
        </div>
        
        <div className="mb-3 ">
          <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
          <div className="coustom-pass">
              <input type={hidePassword?'password':'text'} value={password} onChange={(evt)=>setPassword(evt.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Enter password" required/>
              <button type="button" onClick={()=>setHidePassword(!hidePassword)}><img src={`/img/${hidePassword?'show':'play'}.svg`} alt="img" className="img-fluid" /></button>
          </div>
          <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.password}</span>
        </div>
        
        <div className="mb-3 form-check">
          <input type="checkbox" checked={isAgreed} onChange={(evt)=>setIsAgreed(evt.target.checked)} className="form-check-input" id="exampleCheck1" />
          <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <NavLink to="/termsandcondition">Terms of Service</NavLink></label>
          <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.is_agreed}</span>
        </div>
        
        <button type="submit" className="btn">Sign Up</button>
        <p>Already have an account? <NavLink to="/login">Login</NavLink></p>
      </form>
      <MobilePagesFooter />
    </>
  );
};

export default SignUp;