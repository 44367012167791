import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import YouTube from "react-youtube";

import { SET_MUSIC } from "../../store/action/ActionType";
import styles from "./SongCard.module.scss";
import SongStatsGrid from "../songStatsGrid/SongStatsGrid";
import BookmarkButton from "../bookmarkButton/BookmarkButton";

export default function SongCard({ song, requestSong, getSongStatus }) {
  // const profilePictures = [
  //   "/img/avatar-fill.png",
  //   "/img/avatar-fill.png",
  //   "/img/avatar-fill.png",
  // ];

  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const [isActiveSong, setIsActiveSong] = useState(false);
  const isRequested = getSongStatus && getSongStatus(song) !== "new";
  const { get_user_info_success: user } = useSelector((state) => state.user);
  const { activeCardSongId } = useSelector((state) => state.music);
  const { isLightMode } = useSelector((state) => state.toggle);
  const dispatch = useDispatch();

  const onRequestClick = (evt) => {
    evt.preventDefault();
    requestSong(song);
  };

  const onThumnailClick = () => {
    if (!song.youtube_id) {
      history.push(`/songs/${song.slug}-${song.id}`);
      return;
    }
    setIsActiveSong(true);
    dispatch({
      type: SET_MUSIC,
      data: { activeCardSongId: song.id },
    });
  };

  useEffect(()=>{
      if (isActiveSong && activeCardSongId !== song.id) {
          setIsActiveSong(false);
      }
  }, [activeCardSongId]);

  const onYoutubeReady = (evt) => {
    const nplayer = evt.target;
    const videoData = nplayer.getVideoData();
    const videoId = videoData.video_id;
    if (isActiveSong && videoId === song.youtube_id) {
      nplayer.playVideo();
    }
  };
  
  const isNoSong = !song.slug; 
  const isNoTutorials = isNoSong || !song.ch_tutorial_count;

  // if (isNoTutorials && !(requestSong && getSongStatus)) {
  //   console.log('No callbacks provided for');
  //   console.log(song);
  // }

  return (
    <div
      style={{
        borderTopLeftRadius: isActiveSong ? 0 : "6px",
        borderTopRightRadius: isActiveSong ? 0 : "6px",
      }}
      className={`${styles.songCard} ${isLightMode ? styles.lightMode : ''}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <div
        style={{ borderTopLeftRadius: isActiveSong ? 0 : "4px", borderTopRightRadius: isActiveSong ? 0 : "4px" }}
        className={styles.cardVideoWrapper}
      >
        {isActiveSong && <YouTube
          className={styles.cartResponsiveYoutube}
          videoId={song.youtube_id}
          onReady={onYoutubeReady}
          opts={{
            width: 640,
            height: 360,
            playerVars: {
              rel: 0,
              modestbranding: 1,
              showinfo: 0,
              fs: 0,
            },
          }}
        />}
        {!isActiveSong && <img
          src={song.youtube_id ? `https://i.ytimg.com/vi/${song.youtube_id}/mqdefault.jpg` : song.artwork_medium}
          alt={song.name}
          style={{
            opacity: isNoTutorials && (isHovered || isRequested) && 0.5,
            aspectRatio: !song.youtube_id && "1/1",
            height: !song.youtube_id && 170
          }}
          className={styles.songImg}
          onContextMenu={()=>false} // prevent opening menu on mobile
          onClick={onThumnailClick}
        />}
      </div>
      <div style={{ pointerEvents: (isNoSong || isNoTutorials) && "none" }} className={styles.songInfo}>
        <NavLink
          to={`/songs/${song.slug}-${song.id}`}
          className={`${styles.songName} d-block`}
        >
          {song.name}
        </NavLink>
        <div>
          {song.artists && !isNoSong ? 
            (song.artists.map((aa, aaidx) => (
              <Fragment key={aa.id}>
                {aaidx > 0 && ", "}
                <NavLink to={`/artists/${aa.slug}`}>
                  <span className={`${styles.artistName} d-block`}>
                    {aa.name}
                  </span>
                </NavLink>
              </Fragment>
            ))) : (
              <span className={`${styles.artistName} d-block`}>
                {song.artists}
              </span>
          )}
        </div>
        <SongStatsGrid song={song} headerLevel={2} />
        <div style={{ justifyContent: isNoTutorials && "center" }} className={styles.footer}>
          {isNoTutorials ? (isRequested ? (
            <button className={styles.playButtonContainer}><span>Song requested</span></button>
          ) : (
            <button className={styles.playButtonContainer} onClick={onRequestClick}><span>Request song</span></button>
          )) : <>
            <NavLink className={styles.playButtonContainer} to={`/songs/${song.slug}-${song.id}`}>
              <img src="/icons/play-button.svg" alt="play" className={styles.playButton} />
              <span className={styles.playText}>Learn</span>
            </NavLink>
            <div style={{ display: "flex" }}>
              {/* <div className={styles.pfPicturesContainer}>
                {profilePictures.map((pfpSource, i) => (
                  <img key={i} src={pfpSource} alt="User pfp" className={styles.pfPicture} />
                ))}
                <div>
                  <span className={styles.pfPictureCount}>+{profilePictures.length}</span>
                </div>
              </div> */}
              <BookmarkButton
                className={styles.bookmarkButton}
                user={user}
                song={song}
              />
            </div>
          </>}
        </div>
      </div>
    </div>
  )
}

