import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from "react-helmet";
import { requestMusic } from '../../store/action/AdminAction'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap'
import GoTopOnAll from '../../component/goTopOnAll/GoTopOnAll';
import MobileSearchHeader from '../../component/mobileSearchHeader/MobileSearchHeader';
import useWindowSize from '../../hooks/useWindowSize';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow} from "swiper";
import SongsTable from '../../component/songsTable/SongsTable';

import "swiper/css";
import styles from './SearchSong.module.scss'
import FadeoutDiv from '../../component/fadeoutDiv/FadeoutDiv';
import PingPongSpinner from '../../component/pingPongSpinner/PingPongSpinner';
import SongCard from '../../component/songCard/SongCard';

const guestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
}

async function searchItems(query, kind, page) {
  //query = encodeURIComponent(query);

  let url = `${process.env.REACT_APP_BACKEND_URL}/search/tutorials/`

  try {
      const ret = await axios.post(url, { query, page, kind }, guestConfig);
      const data = ret.data;
      return { data: data.results, hasNext: !!data.next, count: data.count };
  } catch(err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(err, error);
      return { data: [], hasNext: false };
  }
}

function SearchTutorials() {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const viewPreference = useSelector((state) => state.viewPreference);
  //const [kind, setKind] = useState('song');
  const kind = 'song';

  const history = useHistory();

  const emptyState = { page: 0, searchNext: true, items: []};

  const [state, setState] = useState({ song: emptyState, artist: emptyState, query: '' });

  const fetchStateRef = useRef({ song: {isLoading: false, session: 0}, artist: {isLoading: false, session: 0} })

  const { query } = useParams();

  const reset = () => {
    setState({ song: emptyState, artist: emptyState });

    fetchStateRef.current = {
      song: {isLoading: false, session: fetchStateRef.current.song.session + 1},
      artist: {isLoading: false, session: fetchStateRef.current.artist.session + 1},
    }
  }

  const fetchData = async (kind) => {
    //debugger;
    if (fetchStateRef.current[kind].isLoading) {
      return;
    }

    fetchStateRef.current[kind].isLoading = true;
    const session = fetchStateRef.current[kind].session;

    const ret = await searchItems(query, kind, state[kind].page + 1);

    if (fetchStateRef.current[kind].session !== session) {
      return;
    }
    fetchStateRef.current[kind].isLoading = false;

    const searchNext = state[kind].searchNext && !!ret.hasNext;
    if (ret.data.length) {
      setState({...state, [kind]: { page: state[kind].page + 1, searchNext, items: [...state[kind].items, ...ret.data] }})
    } else {
      setState({...state, [kind]: { page: state[kind].page + 1, searchNext, items: state[kind].items }})
    }
  }

  const fetchSongs = async () => {
    setFetchingSongs(true);
    await fetchData('song');
    setFetchingSongs(false);
  }

  // const fetchArtists = async () => {
  //   await fetchData('artist');
  // }

  useEffect(async () => {
    reset();
    await fetchData(kind);
  }, [query])


  const { accessToken } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const requestSong = (songItem) => {
    if (accessToken) {
      dispatch(requestMusic(songItem.spotify_id, query, accessToken))
    } else {
      history.push('/login');
    }
  }

  const { searchItemStatus } = useSelector(state => state.music)
  const getSongStatus = (item) => {
    return searchItemStatus[item.spotify_id] || 'new';
  }

  const hasMore = state.song.searchNext;
  const [fetchingSongs, setFetchingSongs] = useState(false);

  return (
    <>
      <Helmet>
        <title>{`Songmode - Search for ${query}`}</title>
        <meta name="description" content={`Search results for '${query}'`} />
      </Helmet>
      <div className={`search-song ${styles.searchSong}`} style={{marginTop:isMobile?'':0}}>
        {isMobile && <MobileSearchHeader hasBoxShadow={false} />}
        <h3 className="Search-Results-for">{state.song.items && state.song.items.length < 1 && !hasMore ? 'No results' : 'Search results'} for <span>{query}</span></h3>
        {(viewPreference === "carousel" && isMobile) ? (
          state.song?.items.length > 0 ? (
            <Swiper
              style={{ marginBottom: 50 }}
              grabCursor={false}
              spaceBetween={-50}
              centeredSlides={true}
              effect={"coverflow"}
              slidesPerView={1.33}
              coverflowEffect={{
                rotate: 0,
                stretch: -100,
                depth: 50,
                modifier: 1,
                slideShadows: false,
              }}
              breakpoints={{
                470: {
                  slidesPerView: 2,
                },
                650: {
                  slidesPerView: 3,
                },
                890: {
                  slidesPerView: 4,
                },
                1170: {
                  slidesPerView: 5,
                },
                1390: {
                  slidesPerView: 6,
                },
                1900: {
                  slidesPerView: 8,
                },
              }}
              modules={[
                EffectCoverflow,
              ]}
            >
              {state.song.items.map((song, index) => (
                <SwiperSlide key={song.id ?? `null${index}`}>
                  {song && (
                    <SongCard
                      song={song}
                      requestSong={(!song.slug || !song.ch_tutorial_count) ? requestSong : null}
                      getSongStatus={(!song.slug || !song.ch_tutorial_count) ? getSongStatus : null}
                    />
                  )}
                </SwiperSlide>
              ))}
              {hasMore && (
                <SwiperSlide className={styles.loadMoreButtonWrapper}>
                  {fetchingSongs ?
                    <PingPongSpinner text="Loading more songs..."/>
                  :   
                    <button className={styles.loadMoreButton} onClick={fetchSongs}>Load more &#10230;</button>
                  }
                </SwiperSlide>
              )}
              {!isMobile && <FadeoutDiv />}
            </Swiper>
          ) : <Spinner style={{textAlign: "center", margin: "10px auto"}} animation="grow" variant="info"/>
        ) : (
          <InfiniteScroll
            dataLength={state.song.items && state.song.items.length ? state.song.items.length : 0} //This is important field to render the next data
            next={fetchSongs}
            hasMore={state.song.searchNext}
            loader={<div style={{textAlign: 'center', marginTop: 25, marginBottom: 20}}><Spinner animation="grow" variant="info"/></div>}
            endMessage={state.song.page>1 && <GoTopOnAll />}
            scrollableTarget=""
          >
            <SongsTable songs={state.song.items} requestSong={requestSong} getSongStatus={getSongStatus} />
          </InfiniteScroll>
        )}

      </div>
    </>
  )
}

export default SearchTutorials
