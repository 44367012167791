import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { NavLink } from 'react-router-dom';
import { UPDATE_REGISTRATION_STATUS } from '../../../store/action/ActionType';
import { onLogin, Registration } from '../../../store/action/AuthAction';
import { postCode } from '../../socialLogin/SocialLogin';

const CompleteSignUp = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [socialAuth, setSocialAuth] = useState(null);
  

  const { registrationFail } = useSelector(state => state.auth)

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault()
    const data = await postCode(socialAuth.provider, socialAuth.state, socialAuth.code, {
      username,
      is_agreed: isAgreed,
      partial_token: socialAuth.partial_token,
    });
    
    if (data.access) {
      onLogin(dispatch, history, { ...data, email: data.user, is_superuser: false });
    } else if (data.partial_token) {
      setSocialAuth({...socialAuth, partial_token: data.partial_token})
      setErrors(data.errors);
    } else {
      //TODO: Show error snack bar
    }
  }

  useEffect(() => {
    if (!email && !window.socialAuth) {
      history.push('/sign-up');
      return;
    }

    window.socialAuth && setSocialAuth(window.socialAuth);
  
    if (!email) {
      setUsername(window.socialAuth.initial_username);
      setEmail(window.socialAuth.email);
    }

    window.socialAuth = null;
  }, [email]);

  return (
    <>
      <h1>Complete Registration</h1>
      <p className="text-center">Please provide Username for Songmode</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="Name" className="form-label">Username</label>
          <input type="name" value={username} onChange={(evt)=>setUsername(evt.target.value)} className="form-control" id="Name" aria-describedby="emailHelp" placeholder="Enter unique Username" required/>
          <span style={{ color: 'red', float: 'left' }}>{errors && errors.username && (errors.username ==='new users with this Username already exists.'?'This Username is not available' : errors.username)}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
          <input type="email" value={email} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" readOnly={true} disabled={true}/>
        </div>
        
        <div className="mb-3 form-check">
          <input type="checkbox" checked={isAgreed} onChange={(evt)=>setIsAgreed(evt.target.checked)} className="form-check-input" id="exampleCheck1" />
          <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <NavLink to="/termsandcondition">Terms of Service</NavLink></label>
          <span style={{ color: 'red', float: 'left' }}>{errors && errors.is_agreed}</span>
        </div>
        
        <button type="submit" className="btn">Sign Up</button>
        <p>Already have an account? <NavLink to="/login">Login</NavLink></p>
      </form>
    </>
  );
};

export default CompleteSignUp;