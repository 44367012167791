import {useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";
import {changeChordStyles} from "../../utils";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Navigation} from "swiper";
import FadeoutDiv from "../../component/fadeoutDiv/FadeoutDiv";
import 'swiper/css';
import "swiper/css/effect-coverflow";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from "./ChordSwiper.module.scss";


export default function ChordSwiper({currentTime, videoDuration, setPlaybackPosition, chordData, isMobile, isPortrait, seekToChord, isEditMode}) {
  if (isMobile) {
    const chordProgressions = [...chordData.chordProgressions];
    const timestamps = [...chordData.timeStamps];
    for (let i = 0; i < chordProgressions.length; i++) {
      const chordProgression = chordProgressions[i];
      const timestampArr = timestamps[i];
      if (chordProgression.length <= 5) continue;
      const chordProgRightHalf = chordProgression.splice(chordProgression.length / 2);
      const timestampArrRightHalf = timestampArr.splice(timestampArr.length / 2);
      chordProgressions.splice(i, 1, chordProgression, chordProgRightHalf);
      timestamps.splice(i, 1, timestampArr, timestampArrRightHalf);
      i++;
    }
    chordData.chordProgressions = chordProgressions;
    chordData.timeStamps = timestamps;
  }
  const { isLightMode } = useSelector((state) => state.toggle);
  const [chordSwiper, setChordSwiper] = useState(null);
  const manualChange = useRef(false);
  const activeSlideStyles = useRef({
    // boxShadow: "5px 6px #8501EC, 5px 6px 0 1px #8E8E8E",
    // transition: "all 0.3s"
  });

  function handleClick(swiper) {
    manualChange.current = true;
    swiper.slideTo(swiper.clickedIndex, 500);
  }

  function handleSlideChange(swiper) {
    changeChordStyles(swiper);
    if (manualChange.current) {
      setPlaybackPosition(chordData.timeStamps[swiper.activeIndex].at(0));
      manualChange.current = false;
    }
  }

  useEffect(() => {
    if (chordSwiper == null) return;
    const numChords = chordData.timeStamps[chordSwiper.activeIndex].length
    const currentChord = chordData.timeStamps[chordSwiper.activeIndex].findLastIndex((chordTime) => currentTime > chordTime)
    const chordStart = chordData.timeStamps[chordSwiper.activeIndex].at(currentChord);
    const chordEnd = chordData.timeStamps[chordSwiper.activeIndex].at(currentChord + 1) ?? (chordData.timeStamps[chordSwiper.activeIndex + 1] ? chordData.timeStamps[chordSwiper.activeIndex + 1].at(0) : videoDuration);
    const slideProgress = currentTime >= chordStart ? ((currentTime - chordStart) * (100/numChords) / (chordEnd - chordStart)) + (100/numChords) * currentChord : 0;
    activeSlideStyles.current = {
      ...activeSlideStyles.current,
      background: `linear-gradient(to right, #8C65E3, #8C65E3 ${slideProgress}%, #141414 ${slideProgress}%, #141414)`,
    }
    // Change slides depending on video playback position
    for (let i = 0; i < chordData.timeStamps.length; i++) {
      const timeStamp = chordData.timeStamps[i];
      const progressionStart = timeStamp.at(0)
      const progressionEnd = chordData.timeStamps.at(i + 1) ? chordData.timeStamps.at(i + 1).at(0) : videoDuration
      if (i === 0 && currentTime < progressionStart) {
        chordSwiper.slideTo(0, 500);
        return;
      }
      if (currentTime >= progressionStart && currentTime <= progressionEnd) {
        chordSwiper.slideTo(i, 500);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, chordData]);

  return (
    <Swiper
      onSwiper={setChordSwiper}
      className={`${styles.chordProgressionSwiper} ${isEditMode?styles.chordEdit:''} ${isEditMode?'chordEdit':''} chord-swiper`}
      grabCursor={false}
      spaceBetween={isEditMode?-50:-20}
      centeredSlides={true}
      autoHeight={true}
      effect={"coverflow"}
      slidesPerView={"auto"}
      touchMoveStopPropagation={true}
      direction={(isMobile && isPortrait) ? "vertical" : "horizontal"}
      onInit={changeChordStyles}
      onSlideChange={handleSlideChange}
      onClick={handleClick}
      onTouchStart={() => manualChange.current = true}
      navigation={false}
      coverflowEffect={{
        rotate: 0,
        stretch: -100,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      modules={[EffectCoverflow, Navigation]}
      pagination={false}
    >
      {!isMobile && !isEditMode && <FadeoutDiv/>}
      {chordData.chordProgressions.map((chordProgression, index) => (
        <SwiperSlide className={styles.chordProgressionSlide} key={index}>
          {({isActive}) => {
            return (
              <div
                className={styles.chordProgression}
                style={isActive ? activeSlideStyles.current : {}}
              >
                {chordProgression.map((chord, chordIdx) => (
                  <span
                    key={chordIdx}
                    style={{ color: isLightMode ? "white" : '' }}
                    className={`${styles.chord} ${isEditMode?styles.chordEdit:''}`}
                    onClick={()=>seekToChord && seekToChord(index, chordIdx)}
                  >
                    {chord}
                  </span>
                ))}
              </div>
            );
          }}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
