import styles from "./ChordProgressionsList.module.scss";

export default function ChordProgressionsList({ style, chordProgStyle, chordProgressions }) {
	return (
		<div style={style} className={styles.container}>
			{chordProgressions?.map((chordProgression, i) => (
				<div style={chordProgStyle} key={i} className={styles.chordProgression}>
          {chordProgression.map((chord, j) => (
            <span key={j} className={styles.chord}>{chord}</span>
          ))}
        </div>
			))}
		</div>
	);
}
