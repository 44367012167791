import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios'

import styles from './AllArtists.module.scss'
import TitleHelmet from '../../component/titleHelmet/TitleHelmet';

async function queryItems(letter) {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/songs/all-artists/${letter}/`;
    const ret = await axios.get(url);
    const data = ret.data;
    return { data: data.results };
  } catch(err) {
    const error =  err.response ? err.response  : "networkError"
    console.log(err);
    return { data: [], hasNext: false, name: '' };
  }
}

const alphabetStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const miscKey = 'Misc';
const alphabet = alphabetStr.split('');
alphabet.splice(0, 0, miscKey);

function AllArtists() {

  const [items, setItems] = useState([]);
  const { letter } = useParams(); // Access URL parameters

  useEffect(() => {
    setItems([]);
    const fetchData = async (letter) => {
      const ret = await queryItems(letter);

      const artDict = ret.data.reduce((acc, cur) => {
        let flt = cur.name[0].toUpperCase();
        if (!flt.match(/[A-Z]/)) {
          flt = miscKey;
        }
  
        acc[flt] = acc[flt] || [];
        acc[flt].push(cur);
        return acc;
      }, {});
  
      const chunkSize = 8;
  
      
      let artIndex = [];
      for(let key of alphabet) {
        if (artDict[key]) {
          const cnt = artDict[key].length;
          for (let i=0; i*chunkSize<cnt; i++) {
            const startj = i*chunkSize;
            const endj = Math.min(startj+chunkSize, cnt);
            artIndex.push({
              key: key,
              artists: artDict[key].slice(startj, endj),
            });
          }
        }
      }

      setItems(artIndex);
    };
    fetchData(letter);
  }, [letter]);

  if (!letter) {
    document.location.href = '/artists/A';
    return;
  }

  return (
    <>
      <TitleHelmet title={`Songmode - All Artists - "${letter===miscKey?'Misc.':letter}"`} />
      <div className={styles.allArtists} style={{marginTop: 0, paddingTop: 0}}>
        <h4 style={{marginBottom: 20}} className={styles.header}>Artist Index</h4>

        <div className={`row ${styles.letters}`}>
            <div className="col-lg-12">
              {alphabetStr.split('').map(key=>(
                <Link key={key} to={`/all-artists/${key}`} className={`midi-badge${key===letter?' active':''}`}>{key}</Link>
              ))} 
              <Link to={`/all-artists/${miscKey}`} className={`midi-badge${miscKey===letter?' active':''}`}>Misc.</Link>
            </div>
        </div>
        
        <div style={{textAlign: 'center', width: '100%'}}>{items.length<1 && <Spinner style={{textAlign: "center", margin: "10px auto"}} animation="grow" variant="info"/>}</div>

        <div className="row">
          {items.length>0 && items.map(({ key, artists }) => (
            <div className="col-lg-3">
              <div className="item">
                  <h5>{letter}</h5>
                  <ul>
                    {artists.map(artist => (<li><Link to={`/artists/${artist.slug}`}>{artist.name}</Link></li>))}
                  </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AllArtists
