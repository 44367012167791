const genreFilterItems = [
  { title: 'All', value: '' },
  { title: 'Rap', value: 'rap' },
  { title: 'Pop', value: 'pop' },
  //{ title: 'R&B', value: 'rnb' },
  { title: 'Rock', value: 'rock' },
  { title: 'Country', value: 'country' },
];

const timeFilterItems = [
  { title: 'Day', value: 'day' },
  { title: 'Week', value: 'week' },
  { title: 'Month', value: 'month' },
  { title: 'All Time', value: '' },
];

function FilterColumn({title, value, items, toggleFilter})
{
  return (
    <div className="col-6">
      <h6>{title}</h6>
      {items.map(item=>(
        <div className="mb-3 form-check" key={item.value}>
          <input
            type="checkbox"
            className="form-check-input"
            id={'time_'+item.value}
            checked={value===item.value}
            onChange={()=>toggleFilter(item.value)}
          />
          <label
            className="form-check-label"
            htmlFor={'time_'+item.value}
          >
            {item.title}
          </label>
        </div>
      ))}
    </div>
  );
}

export default function FilterButton({ className, genreFilter, toggleGenreFilter, timeFilter, toggleTimeFilter }) {
  return (
    <div className={`text-end ${className}`}>
      <div className="filter d-inline-block">
        <div className="btn-group">
          <button
            style={{minWidth: 44}}
            type="button"
            className="btn dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            <img
              src="../img/filter-light.svg"
              alt="img"
              className="img-fluid"
            />
          </button>
          <div className="dropdown-menu dropdown-menu-lg-end">
            <form>
              <div className="filter-item">
                <div className="row">
                  <div className="col-6">
                    <h4>Filter</h4>
                  </div>
                  <div className="col-6 text-end">
                    <a href="#0">Save</a>
                  </div>
                </div>
              </div>
              <div className="filter-check-all">
                <div className="row g-0">
                  {toggleGenreFilter && <FilterColumn title="Genre" value={genreFilter} items={genreFilterItems} toggleFilter={toggleGenreFilter} />}
                  {toggleTimeFilter && <FilterColumn title="Time" value={timeFilter} items={timeFilterItems} toggleFilter={toggleTimeFilter} />}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}