import styles from "./MobileSideMenu.module.scss";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function MobileSideMenu({ style = {}, setIsSideMenuOpen }) {
	const history = useHistory();
  return (
		<nav style={{ ...style }} className={styles.mobileSideMenu}>
			<img
				onClick={() => history.push("/")}
				className={`${styles.songmodeLogo} py-2`}
				src="/img/mmlogo.png"
				alt="Songmode logo"
			/>
			<button
				onClick={() => setIsSideMenuOpen(false)}
				className={styles.closeMenuButton}
			/>
			<hr />
			<ul className={styles.sideMenuList}>
				<li>
					<NavLink to="/legal">Legal</NavLink>
				</li>
				<li>
					<NavLink to="/about">About</NavLink>
				</li>
				<li>
					<NavLink to="/contactus">Contact us</NavLink>
				</li>
				<li>
					<NavLink to="/termsandcondition">Terms and Conditions</NavLink>
				</li>
				<li>
					<NavLink to="/privacypolicy">Privacy Policy</NavLink>
				</li>
        <li>
          <NavLink to="/all-artists/A">Artist Index</NavLink>
				</li>
				<li>
					<span>© 2023 Copyright: SongMode</span>
				</li>
			</ul>
		</nav>
	);
}