import { Fragment, useEffect, useState } from 'react';
import { Viewers } from "../../utils";
import { NavLink } from "react-router-dom";
import Difficulty, { levelOptions } from "../difficulty/Difficulty";
import styles from "./MobileTutorialHeader.module.scss";
import SongStatsGrid from '../songStatsGrid/SongStatsGrid';
import YouTube from "react-youtube";

import stStyles from "../songsTable/SongsTable.module.scss";
import HLevel from '../utils/headerLevel/HLevel';

export default function MobileTutorialHeader({ tutorial, song, showHeader, secondaryColor, style, difFilters, onDifFilter }) {

  //const levelButtons = levelOptions.filter(lvo => lvo.value !== 10 && lvo.value !== 25);
  const levelButtons = levelOptions.filter(lvo => lvo.value !== 25);

  const needLargeVideo = !tutorial && !difFilters;

  let songNameLevel = 1;
  let artistLevel = 2;

  if (tutorial) {
    songNameLevel = 2;
    artistLevel = 3;
  }
  
	return (
    <div style={style} className={styles.tutorialHeaderWrapper}>
      
      {tutorial && <HLevel level={1} style={{display:showHeader?'':'none'}}>{tutorial.pageHeader}</HLevel>}

      {!needLargeVideo && <div className={styles.tutorialHeader}>
        <img
          src={song.youtube_id ? `https://i.ytimg.com/vi/${song.youtube_id}/mqdefault.jpg` : song.artwork_medium}
          alt="Song cover"
          style={{width: song.youtube_id && 160}}
        />
        <span className={styles.tutorialInfo}>
          <HLevel level={songNameLevel} className={styles.songName}>
            {tutorial ? <NavLink to={`/songs/${song.slug}-${song.id}`}>
              {song.name}
            </NavLink> : song.name}
          </HLevel>
          <HLevel level={artistLevel} className={styles.artistName}>
            {song.artists.map((aa, aaidx) => (
              <Fragment key={aa.id}>
                {aaidx > 0 && ", "}
                <NavLink to={`/artists/${aa.slug}`}>{aa.name}</NavLink>
              </Fragment>
            ))}
          </HLevel>
          {tutorial && (
            <div className={styles.tutorialViewsNDifficulty}>
              <Viewers count={tutorial.views} />
              <Difficulty
                level={tutorial.level}
                secondaryColor={secondaryColor}
              />
            </div>
          )}
        </span>
		  </div>}

      {needLargeVideo && <div>
        <YouTube
          className={stStyles.cartResponsiveYoutube}
          videoId={song.youtube_id}
          opts={{
            width: 640,
            height: 360,
            playerVars: {
              rel: 0,
              modestbranding: 1,
              showinfo: 0,
              fs: 0,
            },
          }}
        />

        <span className={styles.tutorialInfo} style={{padding: 10}}>
          <NavLink
            className={styles.songName}
            to={`/songs/${song.slug}-${song.id}`}
          >
            {song.name}
          </NavLink>
          <span className={styles.artistName}>
            {song.artists.map((aa, aaidx) => (
              <Fragment key={aa.id}>
                {aaidx > 0 && ", "}
                <NavLink to={`/artists/${aa.slug}`}>{aa.name}</NavLink>
              </Fragment>
            ))}
          </span>
          {tutorial && (
            <div className={styles.tutorialViewsNDifficulty}>
              <Viewers count={tutorial.views} />
              <Difficulty
                level={tutorial.level}
                secondaryColor={secondaryColor}
              />
            </div>
          )}
        </span>
      </div>}

      {!tutorial && <SongStatsGrid song={song} singleLine={true} headerLevel={2} />}

      {difFilters && <div className={styles.hovButtons}>
        {levelButtons.map(lvo => (<button key={lvo.value} onClick={() => onDifFilter(lvo.value, difFilters[lvo.value]!==1)} className={difFilters[lvo.value]===1?styles.activeList:''} disabled={difFilters[lvo.value]===-1}>
          <span>{lvo.label}</span>
        </button>))}
      </div>}
    </div>
	);
}
