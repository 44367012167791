import React, { useState, useEffect, Fragment, useRef } from "react";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";

import styles from "./Playlist.module.scss";
import GoTopOnAll from "../goTopOnAll/GoTopOnAll";
import SongList, { SongSlider } from "../songList/SongList";
import SongsTable from "../songsTable/SongsTable";
import useWindowSize from "../../hooks/useWindowSize";
import { useSelector } from "react-redux";
import TitleHelmet from "../titleHelmet/TitleHelmet";

async function queryItems(tagmode, slug, page, id) {
  try {
    let url = tagmode
      ? `${process.env.REACT_APP_BACKEND_URL}/music/tag/${slug}/${page}/`
      : `${process.env.REACT_APP_BACKEND_URL}/songs/playlist/${slug}/${page}/`;

    if (id) {
      url += '?id='+id;
    }

    const ret = await axios.get(url);
    const data = ret.data;
    return {
      data: data.results,
      hasNext: !!data.next,
      count: data.count,
      name: data.name,
      id: data.id
    };
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err);
    return { data: [], hasNext: false, name: "" };
  }
}

function Playlist({ slug, infinite, ads, tagmode }) {
  tagmode = tagmode ? true : false;

  const [page, setPage] = useState(0);
  const [plId, setPlId] = useState(0);
  const [searchNext, setSearchNext] = useState(true);
  const [items, setItems] = useState([]);
  const [header, setHeader] = useState("");

  const fetchStateRef = useRef({ isLoading: false, session: 0 });

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const viewPreference = useSelector((state) => state.viewPreference);

  const reset = () => {
    setPage(0);
    setSearchNext(true);
    setItems([]);

    fetchStateRef.current = {
      isLoading: false,
      session: fetchStateRef.current.session + 1,
    };
  };

  const { slug: curSlug } = useParams();

  if (!slug) {
    slug = curSlug;
  }

  const url = tagmode ? `/tag-${slug}` : `/songs/${slug}`;

  const fetchData = async (tagmode, slug, curPage, curItems) => {
    if (fetchStateRef.current.isLoading) {
      return;
    }

    fetchStateRef.current = {
      isLoading: true,
      session: fetchStateRef.current.session,
    };
    const session = fetchStateRef.current.session;

    const ret = await queryItems(tagmode, slug, curPage + 1, curPage?plId:0);

    if (fetchStateRef.current.session != session) {
      return;
    }
    fetchStateRef.current = {
      isLoading: false,
      session: fetchStateRef.current.session,
    };

    if (!ret.hasNext || !infinite) {
      setSearchNext(false);
    }
    if (ret.data.length) {
      setPage(curPage + 1);
      setPlId(ret.id);
      setItems([...curItems, ...ret.data]);
      if (header != ret.name) {
        setHeader(ret.name);
      }
    }
  };

  useEffect(async () => {
    reset();
    fetchData(tagmode, slug, 0, [], 0);
  }, [tagmode, slug]);

  return (
    <>
      {header && <TitleHelmet title={`Songmode - ${header} songs`} />}
      <h4 style={{ marginBottom: 20 }} className={styles.header}>
        {header}
      </h4>
      <InfiniteScroll
        dataLength={items && items.length ? items.length : 0} //This is important field to render the next data
        next={
          infinite || page == 0
            ? () => fetchData(tagmode, slug, page, items)
            : null
        }
        hasMore={searchNext}
        loader={
          <div style={{ textAlign: "center", marginTop: 25, marginBottom: 20 }}>
            {/* <Spinner animation="border" variant="primary" /> */}
          </div>
        }
        endMessage={infinite && page > 1 && <GoTopOnAll />}
        scrollableTarget=""
      >
        {(viewPreference === "carousel" && isMobile) && <SongSlider items={items} />}
        {(viewPreference === "list" || !isMobile) && <SongsTable songs={items} />}
      </InfiniteScroll>
      {!infinite && (
        <NavLink to={url} className="myButton">
          See More
        </NavLink>
      )}
    </>
  );
}

export default Playlist;
