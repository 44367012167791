import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import styles from "./MobilePagesFooter.module.scss";

export default function MobilePagesFooter() {
	//const { isLightMode } = useSelector((state) => state.toggle);
	
  return (
    <ul className={styles.container}>
      <li>
        <NavLink to="/legal">Legal</NavLink>
      </li>
      <li>
        <NavLink to="/about">About</NavLink>
      </li>
      <li>
        <NavLink to="/contactus">Contact us</NavLink>
      </li>
      <li>
        <NavLink to="/termsandcondition">Terms and Conditions</NavLink>
      </li>
      <li>
        <NavLink to="/privacypolicy">Privacy Policy</NavLink>
      </li>
      <li>
        <NavLink to="/all-artists/A">Artist Index</NavLink>
      </li>
      <li className={styles.copyright}>
        <span>© 2023 Copyright: SongMode</span>
      </li>
    </ul>
	);
}