import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import styles from "./MobileTutorialFooter.module.scss";

export default function MobileTutorialFooter({ tutorial, earnedPoints, unlockedPercent, unlock }) {
	const { isLightMode } = useSelector((state) => state.toggle);
	
  return (
		<div className={`${styles.channelNavBar} ${isLightMode ? styles.lightMode : ''}`}>
			<NavLink to={`/creators/${tutorial.channelSlug}`}>
				<div className={styles.channelInfoWrapper}>
					<img
						className={styles.channelPFP}
						src={tutorial.channelArtworkSmall}
						alt="Channel profile"
					/>
					<div className={styles.channelInfo}>
						<span className={styles.channelName}>{tutorial.channelName}</span>
						<span className={styles.channelSubCount}>Tap to visit channel</span>
						{/* <span className={styles.channelSubCount}>{artist.subscriber_cnt} subscribers</span> */}
					</div>
				</div>
			</NavLink>
      <button
        className={styles.scoreIcon}
        // disabled={unlockedPercent < 100}
        style={{
          cursor: unlockedPercent < 100 ? "not-allowed" : "",
          background: `linear-gradient(0deg, var(--bg-between-gradient) ${unlockedPercent}%, transparent ${unlockedPercent}%)`,
        }}
        onClick={unlock}
      >
        <img src={`/icons/circle-check${earnedPoints > 0 ? "-filled" : ''}.svg`} alt="Learn song" />
      </button>
		</div>
	);
}