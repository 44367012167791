import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";

import SVG from 'react-inlinesvg';

import SideFooter from "../../layouts/sideFooter/SideFooter";
import {SET_MUSIC, TOGGLE_THEME} from "../../store/action/ActionType";

import {getPlaylists} from "../../store/action/MusicAction";
import Switch from "../switch/Switch";
import styles from "./SongmodeSidebar.module.scss";
import PlaylistIcon from "../playlistIcon/PlaylistIcon";

function SongmodeSidebar() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {playlists, isMobileExpanded} = useSelector((state) => state.music);
  const {get_user_info_success: user} = useSelector((state) => state.user);
  const {isLightMode} = useSelector((state) => state.toggle);

  const [searchText, setsearchText] = useState("");

  const collapseSidebar = () => {
    if (isMobileExpanded) {
      dispatch({type: SET_MUSIC, data: {isMobileExpanded: false}});
    }
  };

  const handelSearch = (e) => {
    searchText &&
    history.push("/search/tutorials/" + encodeURIComponent(searchText));
  };

  useEffect(() => {
    if (!playlists || !playlists.length) {
      dispatch(getPlaylists());
    }
  }, [playlists, dispatch]);

  useEffect(() => {
    collapseSidebar();
  }, [location.pathname, location.search]);
  const setLightMode = () => dispatch({type: TOGGLE_THEME});

  return (
    <div
      id="mySidenav"
      className="sidenav "
      style={{width: isMobileExpanded ? "100%" : ""}}
    >
      <button
        className="closebtn d-lg-none d-inline-block"
        onClick={collapseSidebar}
      >
        &times;
      </button>
      <div style={{ zIndex: 1000 }} className="left-item">
        <div className="inner-left-item">
          <div className="search-bar d-lg-none d-block">
            <form className="d-inline-block" onSubmit={handelSearch}>
              <input
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
                className="form-control"
                type="search"
                placeholder="Search songs and artists"
                aria-label="Search"
              />
              <button type="submit">
                <img src="/img/search.png" alt="img" className="img-fluid"/>
              </button>
            </form>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Switch
                  isOn={!isLightMode}
                  onColor="#EF476F"
                  handleToggle={() => {
                    setLightMode();
                  }}
                />
              </li>
              {user && (
                <>
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="#0">
                      <img
                        src={
                          isLightMode ? "/img/songs-light.svg" : "/img/song.svg"
                        }
                        alt="img"
                        className="img-fluid"
                      />{" "}
                      <span>{user.total_score}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="#0">
                      <img
                        src={
                          isLightMode ? "/img/love-light.svg" : "/img/love.svg"
                        }
                        alt="img"
                        className="img-fluid"
                      />{" "}
                      <span>{user.like_count}</span>
                    </a>
                  </li>
                  {false && (
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#0">
                        <img
                          src={
                            isLightMode ? "/img/madel.svg" : "/img/madel.svg"
                          }
                          alt="img"
                          className="img-fluid"
                        />{" "}
                        <span>#{user.rating}</span>
                      </a>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>

          <div className="item1">
            <ul>
              <li>
                <h4>HOME</h4>
                <Link to="/">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 14.9998H13M15 18.9998H5C3.93913 18.9998 2.92172 18.5784 2.17157 17.8282C1.42143 17.0781 1 16.0607 1 14.9998V8.70782C0.999986 8.02003 1.17732 7.34386 1.51487 6.74461C1.85242 6.14536 2.33879 5.64326 2.927 5.28682L7.927 2.25682C8.55211 1.87801 9.26907 1.67773 10 1.67773C10.7309 1.67773 11.4479 1.87801 12.073 2.25682L17.073 5.28682C17.6611 5.64317 18.1473 6.14511 18.4849 6.74417C18.8224 7.34324 18.9998 8.0192 19 8.70682V14.9998C19 16.0607 18.5786 17.0781 17.8284 17.8282C17.0783 18.5784 16.0609 18.9998 15 18.9998Z"
                      stroke={isLightMode ? "black" : "white"}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="hide-leftText">Home</span>
                </Link>
              </li>
              <li>
                <NavLink to="/following">
                  <img
                    src={`/icons${isLightMode?'-light':''}/bell.svg`}
                    alt="img"
                    className="img-fluid"
                  />
                  <span className="hide-leftText">Following</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/" exact={true}>
                  <img
                    src={`/icons${isLightMode?'-light':''}/music.svg`}
                    alt="img"
                    className="img-fluid"
                  />
                  <span className="hide-leftText">Discover</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="item1">
            <h4>TOP SONGS</h4>
            <ul>
              <li>
                <NavLink to="/topsongs">
                  {/*<svg*/}
                  {/*  width="20"*/}
                  {/*  height="18"*/}
                  {/*  viewBox="0 0 20 18"*/}
                  {/*  fill="none"*/}
                  {/*  xmlns="http://www.w3.org/2000/svg"*/}
                  {/*>*/}
                  {/*  <path*/}
                  {/*    d="M12 17C13.6569 17 15 15.6569 15 14C15 12.3431 13.6569 11 12 11C10.3431 11 9 12.3431 9 14C9 15.6569 10.3431 17 12 17Z"*/}
                  {/*    stroke={isLightMode ? "black" : "white"}*/}
                  {/*    strokeWidth="2"*/}
                  {/*    strokeLinecap="round"*/}
                  {/*    strokeLinejoin="round"*/}
                  {/*  />*/}
                  {/*  <path*/}
                  {/*    d="M15 14V1H19M11 2H1M1 6H11M7 10H1"*/}
                  {/*    stroke={isLightMode ? "black" : "white"}*/}
                  {/*    strokeWidth="2"*/}
                  {/*    strokeLinecap="round"*/}
                  {/*    strokeLinejoin="round"*/}
                  {/*  />*/}
                  {/*</svg>*/}
                  <img
                    src={isLightMode ? "/icons-light/fire.svg" : "/icons/fire.svg"}
                    className="img-fluid"
                    style={{
                      width: '24px',
                      height: '24px',
                      // stroke: isLightMode ? "black" : "white",
                    }}
                    alt={'top-songs'}
                  />{" "}
                  <span className="hide-leftText">Top Songs</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="item1">
            <h4>PLAYLISTS</h4>
            <ul>
              {playlists &&
                playlists.map((plist) => (
                  <PlaylistItem key={plist.slug} plist={plist} isLightMode={isLightMode}/>
                ))}
            </ul>
          </div>

          {/* <SideFooter /> */}
        </div>
      </div>
    </div>
  );
}

const PlaylistItem = ({plist, isLightMode}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  
  return (<li style={{ position: "relative" }}>
    <NavLink onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} to={`/lists/${plist.slug}`}>
      <PlaylistIcon playlist={plist} isLightMode={isLightMode} />
    </NavLink>
    {showTooltip &&
      <span className={styles.plistTooltip}>{plist.name}</span>
    }
  </li>)
}

export default SongmodeSidebar;
