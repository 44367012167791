import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, Redirect } from "react-router-dom";
import YouTube from "react-youtube";
// import SongItem from "../../component/songItem/SongItem";
import { getSingeleSong } from "../../store/action/MusicAction";
import { formatDuration, Viewers } from "../../utils";
import MobileTutorialHeader from "../../component/mobileTutorialHeader/MobileTutorialHeader";
import useWindowSize from "../../hooks/useWindowSize";

import styles from "./SpotifySong.module.scss";
import Difficulty, { levelOptions } from "../../component/difficulty/Difficulty";
import RelatedTutorials from "../../component/relatedTutorials/RelatedTutorials";
import SongStatsGrid from "../../component/songStatsGrid/SongStatsGrid";
import SongsTable from "../../component/songsTable/SongsTable";

function getDifFilters(tutorials) {

  if (!tutorials || !tutorials.length) {
    return null;
  }

  const difFilters = {};
  levelOptions.filter(lvo => lvo.value !== 25).forEach(lvo => difFilters[lvo.value] = -1);
  tutorials.forEach(tut => difFilters[tut.level] = 0);
  return difFilters;
}

function getChordsForDescription(song) {
  if (!song || !song.chord_progression || !song.chord_progression.length) {
    return '';
  }

  if (song.chord_progression.length > 40) {
    return '';
  }

  return ` Chord Progession "${song.chord_progression.replaceAll(',', ', ')}".`;
}

function getHooksForDescription(song) {
  if (!song || !song.section_count) {
    return '';
  }
  const hooks = song.section_count;
  return ` "${hooks}" Hooks.`;
}

function SpotifySong() {
  const { slug } = useParams();
  const id = slug ? +slug.split("-").pop() : "";

  const { getSingleSongSuccess } = useSelector((state) => state.music);
  const { isLightMode } = useSelector((state) => state.toggle);

  const dispatch = useDispatch();
  useEffect(() => {
    if (getSingleSongSuccess && getSingleSongSuccess.song.id === id) {

      const difFilters = getDifFilters(getSingleSongSuccess.tutorials);

      setPageData({...getSingleSongSuccess, difFilters, allTutorials: getSingleSongSuccess.tutorials});
    } else {
      setPageData({});
      dispatch(getSingeleSong(id));
    }
  }, [id, getSingleSongSuccess, dispatch]);

  const [{ song, otherSongs, allTutorials, tutorials, difFilters }, setPageData] = useState({});

  const onDifFilter = (level, isOn) => {
    const newFilters = {...difFilters, [level]: isOn?1:0};
    const newTutorials = allTutorials.filter(tut => newFilters[tut.level] === 1);
    setPageData({ song, otherSongs, allTutorials, tutorials: newTutorials.length > 0 ? newTutorials: allTutorials, difFilters: newFilters });
  };

  const chordForDescription = getChordsForDescription(song);
  const hooksForDescription = getHooksForDescription(song);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const tutorialHeaderStyles = {
		position: "fixed",
		top: 0,
		paddingLeft: "5px",
		paddingRight: "5px",
		margin: 0,
		backdropFilter: "blur(20px)",
		WebkitBackdropFilter: "blur(20px)",
		boxShadow: "0 1px 5px 1px #141414",
		backgroundColor: isLightMode ? "rgba(227, 227, 231, 0.8)" : "#14141470",
	};
  if (allTutorials?.length === 1) return <Redirect to={`/tutorials/${song.slug}-${tutorials[0].id}`} />;
  return (
    <>
      {song && <Helmet>
        <title>{`Chords, Song Key, Bpm, Hooks for "${song.name}" by ${song.artists[0].name}`}</title>
        <meta
          name="description"
          content={`"${song.name}" by ${song.artists[0].name}. in the key of "${song.songKey}". "${song.tempo}" BPM.${chordForDescription}${hooksForDescription}`}
        />
      </Helmet>}
      <div className={`Blinding-Lights ${styles.song}`}>
        {song && (
          <div className="row">
            {isMobile ?
              <MobileTutorialHeader 
                style={tutorialHeaderStyles}
                song={song}
                difFilters={difFilters}
                onDifFilter={onDifFilter}
              />
            : (
              <div className="col-lg-4">
                <div className="Blinding-left">
                  {/* <SongItem song={song} /> */}
                  <span style={{ display: 'block', fontWeight: 100, letterSpacing: 0.5, fontSize: 24, lineHeight: 1.2, marginBottom: '0.5rem' }}>Song info</span>
                  <div className={`video_play ${styles.videoWrapper}`} style={{ margin: 0 }}>
                    <YouTube
                      className={styles.responsiveYoutube}
                      videoId={song.youtube_id}
                      opts={{
                        width: 640,
                        height: 360,
                        playerVars: { rel: 0, modestbranding: 1, showinfo: 0 },
                      }}
                    />
                  </div>
                  <div className="Blinding-left">
                    <div className="slider active" style={{paddingBottom: 0}}>
                      <span style={{ display: "flex", gap: 20, alignItems: "center", justifyContent: 'space-between' }} className="ex-a">
                        <span className="title-item d-block" style={{paddingTop:10}}>
                          <h1 className="songstitle d-block">{song.name}</h1>
                          <h2 className="singer-name d-block">
                            {song.artists.map((aa, aaidx) => (
                              <Fragment key={aa.id}>
                                {aaidx > 0 && ", "}
                                <NavLink to={`/artists/${aa.slug}`}>
                                  {aa.name}
                                </NavLink>
                              </Fragment>
                            ))}
                          </h2>
                        </span>
                        {/* <span className="title-item d-block">
                          <button><span>Request song</span></button>
                        </span> */}
                      </span>
                    </div>
                  </div>
                  <SongStatsGrid song={song} headerLevel={2} />
                </div>
              </div>
            )}
            {allTutorials && allTutorials.length > 0 && <div className="col-lg-6 offset-lg-1 mt-lg-0 mt-5">
              <div style={{marginTop: isMobile ? (difFilters?185:140) : 5}} className="Blinding-right">
                {isMobile ? <>
                  <h3 style={{ textAlign: "center" }}>Available Tutorials</h3>
                  <RelatedTutorials
                    tutorials={tutorials}
                    song={song}
                    isMobile={isMobile}
                    showNames={false}
                  />
                </> : (
                tutorials &&
                  tutorials.map((tut, index) => (
                    <div
                      style={{marginTop: isMobile && index === 0 && "70px"}}
                      key={tut.id}
                      className={"media" + (index > 0 ? " mt-4" : "")}
                    >
                      <NavLink
                        to={`/tutorials/${song.slug}-${tut.id}`}
                        className="user"
                      >
                        <img
                          src={tut.thumbnail}
                          alt="img"
                          className="img-fluid"
                        />
                        <span className="time d-inline-block">
                          {formatDuration(tut.duration)}
                        </span>
                      </NavLink>
                      <div className="media-body">
                        <NavLink
                          to={`/tutorials/${song.slug}-${tut.id}`}
                          className="title"
                        >
                          {tut.videoTitle}
                        </NavLink>

                        <span className={`singer-name d-block`}>
                          <NavLink to={`/creators/${tut.channelSlug}`}>
                            <img
                              src={tut.channelArtworkSmall}
                              alt="img"
                              className="img-fluid profile-images"
                              style={{
                                width: "16px",
                                height: "16px",
                                backgroundColor: "#2C1C37",
                                borderRadius: "50%",
                                marginRight: '5px',
                              }}
                              onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = '/img/user.svg';
                              }}
                            />
                            {tut.channelName}
                          </NavLink>
                        </span>

                        <div className="viewers-holder">
                          <Viewers count={tut.views} />
                        </div>
                        <Difficulty level={tut.level} />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>}

            {allTutorials && allTutorials.length === 0 && <div className="col-lg-6 offset-lg-1 mt-lg-0 mt-5">
              <div style={{marginTop: isMobile ? 325 : 5}} className="Blinding-right">
                {otherSongs && otherSongs.length > 0 && <>
                  {isMobile 
                    ? <h3 style={{ textAlign: "center" }}>Other Songs</h3>
                    : <h4 style={{ fontWeight: 100, letterSpacing: 0.5 }}>Other Songs</h4>
                  }
                  <SongsTable songs={otherSongs} isShowReleaseDate={false} />
                </>}
                {(!otherSongs || otherSongs.length < 1) && <>
                  No other songs.
                </>}
              </div>
            </div>}
          </div>
        )}
      </div>
    </>
  );
}

export default SpotifySong;
