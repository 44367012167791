import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeNumberSmall } from "../../utils";
import Difficulty from "../difficulty/Difficulty";
import styles from "./TutorialsTable.module.scss";


export default function TutorialsTable({ tutorials }) {
	const { isLightMode } = useSelector((state) => state.toggle);
	
	return (
		<div className={`top-songs ${styles.currentListSection}`}>
			<div className="Played table-responsive">
				<table className="table table-dark">
					<thead>
						<tr>
							<th scope="col" colSpan="2" className={styles.songName}>
								Song
							</th>
							<th scope="col" className={styles.artist}>
								Artist
							</th>
							<th scope="col">Creator</th>
							<th scope="col">Difficulty</th>
							<th scope="col">Views</th>
						</tr>
					</thead>
					<tbody>
						{tutorials.map((tutorial) => (
							<tr className={styles.row} key={tutorial.id}>
								<td className="table-img">
									<Link to={`/tutorials/${tutorial.slug}-${tutorial.id}`}>
										<img
											src={tutorial.thumbnail}
											alt="img"
											className={`img-fluid ${styles.youtubeThumbnail}`}
										/>{" "}
									</Link>
								</td>
								<td className={styles.songName}>
									<Link to={`/tutorials/${tutorial.slug}-${tutorial.id}`}>
										<span className="chip">{tutorial.song.name}</span>
									</Link>
									<div className={styles.artistMobile}>
										<Link to={`/artists/${tutorial.song.artists[0].slug}`}>
											{tutorial.song.artists[0].name}
										</Link>
									</div>
									<div className={styles.channelNameMobile}>
										<Link to={`/creators/${tutorial.channelSlug}`}>
											{tutorial.channelName}
										</Link>
									</div>
								</td>
								<td className={styles.artist}>
									<Link to={`/artists/${tutorial.song.artists[0].slug}`}>
										{tutorial.song.artists[0].name}
									</Link>
								</td>
								<td className={styles.channelName}>
									<Link to={`/creators/${tutorial.channelSlug}`}>
										{tutorial.channelName}
									</Link>
								</td>
								<td className={styles.difficulty}>
									<Difficulty level={tutorial.level} />
								</td>
								<td className={styles.views}>
									<span>
										<img
											style={{ filter: isLightMode ? "invert(1)" : '' }}
											src="../img/view.png"
											alt="img"
											className="img-fluid"
										/>{' '}
										{makeNumberSmall(tutorial.views)}
									</span>
								</td>
								<td className={styles.diffAndViewsMobile}>
									<span>
										<img
											style={{ filter: isLightMode ? "invert(1)" : '' }}
											src="../img/view.png"
											alt="img"
											className="img-fluid"
										/>{' '}
										{makeNumberSmall(tutorial.views)}
									</span>
									<Difficulty level={tutorial.level} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
