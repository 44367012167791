import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function SideFooter({ useMobileBottom = true }) {
  const { isLightMode } = useSelector((state) => state.toggle);

  const { isMobileExpanded } = useSelector((state) => state.music);
  const { get_user_info_success: user } = useSelector((state) => state.user);

  const showBottom = useMobileBottom && isMobileExpanded;

  return (
    <>
      <div className="item1 item05">
        <ul>
          <li>
            <NavLink to="/contactus">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8669 0.200195H3.13338C2.3554 0.200195 1.60928 0.509246 1.05917 1.05936C0.509052 1.60948 0.200001 2.35559 0.200001 3.13357L0.200001 11.9337C0.200001 12.7117 0.509052 13.4578 1.05917 14.0079C1.60928 14.558 2.3554 14.8671 3.13338 14.8671H5.26007L8.52418 17.6266C8.6566 17.7387 8.82445 17.8002 8.99792 17.8002C9.17139 17.8002 9.33925 17.7387 9.47166 17.6266L12.7402 14.8671H14.8669C15.6449 14.8671 16.391 14.558 16.9411 14.0079C17.4912 13.4578 17.8002 12.7117 17.8002 11.9337V3.13357C17.8002 2.35559 17.4912 1.60948 16.9411 1.05936C16.391 0.509246 15.6449 0.200195 14.8669 0.200195V0.200195ZM16.3336 11.9337C16.3336 12.3227 16.179 12.6957 15.904 12.9708C15.6289 13.2459 15.2559 13.4004 14.8669 13.4004H12.7402C12.3935 13.4005 12.0581 13.5234 11.7934 13.7473L9.00012 16.1064L6.20828 13.7473C5.94323 13.5231 5.60723 13.4001 5.26007 13.4004H3.13338C2.74439 13.4004 2.37133 13.2459 2.09627 12.9708C1.82121 12.6957 1.66669 12.3227 1.66669 11.9337V3.13357C1.66669 2.74458 1.82121 2.37152 2.09627 2.09647C2.37133 1.82141 2.74439 1.66688 3.13338 1.66688H14.8669C15.2559 1.66688 15.6289 1.82141 15.904 2.09647C16.179 2.37152 16.3336 2.74458 16.3336 3.13357V11.9337Z"
                  fill={isLightMode ? "black" : "white"}
                />
                <path
                  d="M5.33341 5.33339H9.00013C9.19462 5.33339 9.38115 5.25613 9.51868 5.1186C9.65621 4.98107 9.73347 4.79454 9.73347 4.60004C9.73347 4.40555 9.65621 4.21902 9.51868 4.08149C9.38115 3.94396 9.19462 3.8667 9.00013 3.8667H5.33341C5.13892 3.8667 4.95239 3.94396 4.81486 4.08149C4.67733 4.21902 4.60007 4.40555 4.60007 4.60004C4.60007 4.79454 4.67733 4.98107 4.81486 5.1186C4.95239 5.25613 5.13892 5.33339 5.33341 5.33339Z"
                  fill={isLightMode ? "black" : "white"}
                />
                <path
                  d="M12.6668 6.80029H5.33341C5.13892 6.80029 4.95239 6.87756 4.81486 7.01508C4.67733 7.15261 4.60007 7.33914 4.60007 7.53364C4.60007 7.72813 4.67733 7.91466 4.81486 8.05219C4.95239 8.18972 5.13892 8.26698 5.33341 8.26698H12.6668C12.8613 8.26698 13.0479 8.18972 13.1854 8.05219C13.3229 7.91466 13.4002 7.72813 13.4002 7.53364C13.4002 7.33914 13.3229 7.15261 13.1854 7.01508C13.0479 6.87756 12.8613 6.80029 12.6668 6.80029Z"
                  fill={isLightMode ? "black" : "white"}
                />
                <path
                  d="M12.6668 9.73389H5.33341C5.13892 9.73389 4.95239 9.81115 4.81486 9.94868C4.67733 10.0862 4.60007 10.2727 4.60007 10.4672C4.60007 10.6617 4.67733 10.8483 4.81486 10.9858C4.95239 11.1233 5.13892 11.2006 5.33341 11.2006H12.6668C12.8613 11.2006 13.0479 11.1233 13.1854 10.9858C13.3229 10.8483 13.4002 10.6617 13.4002 10.4672C13.4002 10.2727 13.3229 10.0862 13.1854 9.94868C13.0479 9.81115 12.8613 9.73389 12.6668 9.73389Z"
                  fill={isLightMode ? "black" : "white"}
                />
              </svg>

              <span className="hide-leftText">Contact us</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/about">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 0.200195C4.14036 0.200195 0.200001 4.14055 0.200001 9.00019C0.200001 13.8598 4.14036 17.8002 9 17.8002C13.8596 17.8002 17.8 13.8598 17.8 9.00019C17.8 4.14055 13.8596 0.200195 9 0.200195ZM9 16.3073C4.96536 16.3073 1.69286 13.0348 1.69286 9.00019C1.69286 4.96555 4.96536 1.69305 9 1.69305C13.0346 1.69305 16.3071 4.96555 16.3071 9.00019C16.3071 13.0348 13.0346 16.3073 9 16.3073Z"
                  fill={isLightMode ? "black" : "white"}
                />
                <path
                  d="M8.05714 5.54295C8.05714 5.79302 8.15648 6.03284 8.3333 6.20966C8.51012 6.38648 8.74994 6.48581 9 6.48581C9.25006 6.48581 9.48988 6.38648 9.6667 6.20966C9.84352 6.03284 9.94286 5.79302 9.94286 5.54295C9.94286 5.29289 9.84352 5.05307 9.6667 4.87625C9.48988 4.69943 9.25006 4.6001 9 4.6001C8.74994 4.6001 8.51012 4.69943 8.3333 4.87625C8.15648 5.05307 8.05714 5.29289 8.05714 5.54295ZM9.47143 7.74295H8.52857C8.44214 7.74295 8.37143 7.81367 8.37143 7.9001V13.243C8.37143 13.3294 8.44214 13.4001 8.52857 13.4001H9.47143C9.55786 13.4001 9.62857 13.3294 9.62857 13.243V7.9001C9.62857 7.81367 9.55786 7.74295 9.47143 7.74295Z"
                  fill={isLightMode ? "black" : "white"}
                />
              </svg>
              <span className="hide-leftText">About</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <div
        className="item2 hide-leftText"
        style={{ marginBottom: showBottom ? 90 : "" }}
      >
        {/* <NavLink to="/legal">Legal</NavLink> */}
        <NavLink to="/termsandcondition">Terms and Conditions</NavLink>
        <NavLink to="/privacypolicy">Privacy Policy</NavLink>
        <NavLink to="/all-artists/A">Artist Index</NavLink>
        <p>
          © 2022 Copyright: <br /> SongMode
        </p>
      </div>

      {showBottom && (
        <div className=" mobile-bottom-menu fixed-bottom text-center d-lg-none d-block">
          <div className="container-fluid">
            <ul className=" ms-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
              <a className="nav-link"  href="Notifications-for-mobile.html">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.6524 14.5113C16.1308 14.0464 15.6742 13.5133 15.2948 12.9266C14.8806 12.1167 14.6324 11.2322 14.5647 10.325V7.6532C14.5625 7.32865 14.5335 7.00485 14.4781 6.68506C14.0524 6.59996 13.6433 6.44654 13.2666 6.23073C13.4102 6.69309 13.4831 7.17447 13.4829 7.65861V10.3305C13.5492 11.4371 13.8536 12.5163 14.3754 13.4945C14.7488 14.0862 15.1918 14.6309 15.6951 15.1171H1.84362C2.34684 14.6309 2.78991 14.0862 3.16332 13.4945C3.68504 12.5163 3.98944 11.4371 4.05574 10.3305V7.6532C4.05289 7.02725 4.17354 6.40689 4.41078 5.82764C4.64802 5.24839 4.99719 4.72162 5.43829 4.27751C5.8794 3.83339 6.40377 3.48064 6.9814 3.23946C7.55902 2.99828 8.17854 2.87341 8.80449 2.87199C9.72058 2.87272 10.6161 3.14364 11.379 3.65083C11.2949 3.34199 11.2477 3.02427 11.2384 2.70432V2.36358C10.6736 2.08577 10.0669 1.90303 9.44271 1.82272V1.14124C9.44271 0.949739 9.36663 0.766083 9.23122 0.630673C9.09581 0.495262 8.91216 0.419189 8.72066 0.419189C8.52916 0.419189 8.3455 0.495262 8.21009 0.630673C8.07468 0.766083 7.99861 0.949739 7.99861 1.14124V1.84977C6.60087 2.04694 5.32166 2.74313 4.39714 3.80981C3.47262 4.87649 2.96521 6.24163 2.96861 7.6532V10.325C2.90087 11.2322 2.65262 12.1167 2.23845 12.9266C1.86573 13.512 1.41644 14.0449 0.902521 14.5113C0.844828 14.562 0.798589 14.6244 0.766882 14.6943C0.735175 14.7643 0.718725 14.8402 0.718628 14.917V15.6525C0.718628 15.796 0.775611 15.9335 0.877042 16.035C0.978473 16.1364 1.11604 16.1934 1.25949 16.1934H16.2954C16.4389 16.1934 16.5764 16.1364 16.6779 16.035C16.7793 15.9335 16.8363 15.796 16.8363 15.6525V14.917C16.8362 14.8402 16.8197 14.7643 16.788 14.6943C16.7563 14.6244 16.7101 14.562 16.6524 14.5113Z" fill="white"></path>
                  </svg>
                  <span className="dot-cuarcale"></span>
              </a>
            </li> */}
              {!user && (
                <>
                  <li className="nav-item button1">
                    <NavLink to="/login" className="nav-link">
                      Login
                    </NavLink>
                  </li>
                  <li className="nav-item button2">
                    <NavLink to="/sign-up" className="nav-link">
                      Sign Up
                    </NavLink>
                  </li>
                </>
              )}
              {user && (
                <li className="nav-item button3">
                  <NavLink to="/profile/edit" className="nav-link">
                    <img src="/img/user.svg" alt="img" className="img-fluid" />
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default SideFooter;
