import React from 'react'
import { useSelector } from 'react-redux'

function AuthLayout({children}) {
  const { isLightMode } = useSelector((state) => state.toggle);
  
  return (
    <div className="Login">
      <div className="Login_heading text-center">
          <a href="/"><img src="/img/mmlogo.png" alt="img" className="img-fluid mt-4" style={{ height: 35, filter: isLightMode ? "invert(1)" : "" }}/></a>
      </div>
      <div className="box">
        {children}
      </div>
    </div>
  )
}

export default AuthLayout
