import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PingPongSpinner from "../../../component/pingPongSpinner/PingPongSpinner";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { SET_USER } from "../../../store/action/ActionType";
import { getDefaultAvatar, getAccessConfig, mediaURL, getRandomColor } from "../../../utils";
import styles from "./EditProfile.module.scss";


async function postAvatar(file) {
  try {
    const config = getAccessConfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const url = `${process.env.REACT_APP_BACKEND_URL}/user/avatar/upload/`;

    const formData = new FormData();
    formData.append("avatar", file);

    const ret = await axios.post(url, formData, config);
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err);
    return false;
  }
}

async function deleteAvatar() {
  try {
    const config = getAccessConfig();
    const url = `${process.env.REACT_APP_BACKEND_URL}/user/avatar/delete/`;
    const ret = await axios.post(url, {}, config);
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err);
    return false;
  }
}

async function getCountries() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/user/countries/`;
    const ret = await axios.get(url);
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err);
    return false;
  }
}

async function updateProfile(data) {
  try {
    const config = getAccessConfig();
    const url = `${process.env.REACT_APP_BACKEND_URL}/user/update/`;
    const ret = await axios.post(url, data, config);
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err);
    return false;
  }
}

const EditProfile = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { get_user_info_success: user } = useSelector((state) => state.user);

  const [avatar, setAvatar] = useState('');

  const [countries, setCountries] = useState([]);

  const [displayName, setDisplayName] = useState("");
  const [country, setCountry] = useState("");
  const [shareMarketing, setShareMarketing] = useState(null);

  const [isProgress, setIsProgress] = useState(false);
  const { isLightMode } = useSelector((state) => state.toggle);

  const [uploading, setUploading] = useState(false);

  const uploadAvatar = async (files) => {
    if (files && files[0]) {
      setUploading(true);
      const file = files[0];
      const data = await postAvatar(file);
      const url = data.avatar + "?ts=" + new Date().getTime();
      dispatch({
        type: SET_USER,
        data: { get_user_info_success: { ...user, avatar: url } },
      });
      setUploading(false);
    }
  };

  const removeAvatar = async () => {
    await deleteAvatar();
    dispatch({
      type: SET_USER,
      data: { get_user_info_success: { ...user, avatar: null } },
    });
  };

  const saveProfile = (evt) => {
    evt.preventDefault();
    setIsProgress(true);
    updateProfile({
      display_name: displayName,
      country: country,
      is_share_marketing: shareMarketing,
    }).then((nuser) => {
      nuser &&
        dispatch({ type: SET_USER, data: { get_user_info_success: nuser } });
      setIsProgress(false);
    });
  };

  useEffect(() => {
    if (user) {
      let src = '';
      if (user.avatar) {
        src = mediaURL(user.avatar);
      } else if (user.animated_avatar) {
        src = user.animated_avatar;
      } else {      
        src = getDefaultAvatar(user.email);
      }
      setAvatar(src);
    }
  }, [user]);

  useEffect(() => {
    const uu = user || {};
    setDisplayName(uu.display_name || "");
    setCountry((uu.country && uu.country.code) || "");
    setShareMarketing(uu.is_share_marketing || false);
  }, [user && user.id]);

  useEffect(() => {
    const countriesStr = localStorage.getItem("countries_2");
    if (countriesStr) {
      setCountries(JSON.parse(countriesStr));
    } else {
      getCountries().then((countries) => {
        countries = [{ value: "", label: "Select Country..." }, ...countries];
        setCountries(countries);
        localStorage.setItem("countries_2", JSON.stringify(countries));
      });
    }
  }, []);

  async function postAnimatedAvatar(url) {
    try {
      const config = getAccessConfig();
      const postUrl = `${process.env.REACT_APP_BACKEND_URL}/user/animated-avatar/upload/`;
      const ret = await axios.post(postUrl, { animated_avatar: url }, config);
      return ret.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function rerollAvatar() {
    const bgColor = getRandomColor();
    const avatarURL = `https://api.dicebear.com/5.x/adventurer/svg?seed=${user.email}${bgColor}&backgroundColor=${bgColor}`;
    const data = await postAnimatedAvatar(avatarURL);
    dispatch({
      type: SET_USER,
      data: { get_user_info_success: { ...user, animated_avatar: data.animated_avatar } },
    });
    setAvatar(avatarURL);
  }

  return (
    <div className="Edit-Profile">
      <div className="Edit-top">
        <h2>
          <a href="#0" onClick={history.goBack}>
            <img
              src={isLightMode ? "/img/back-light.svg" : "/icons/arrow-left.svg"}
              alt="img"
              className="img-fluid"
            />
          </a>
          Edit Profile
        </h2>
      </div>
      {user && (
        <div className="Avatar" style={{ marginTop: -20 }}>
          <NavLink to={`/users/${user.username}`} style={{ marginBottom: 20, fontWeight: "bold", fontSize: "18px" }}>
            View Profile &#x279C;
          </NavLink>

          <div className="media" style={{ alignItems: "center" }}>
            {uploading ? (
              <div style={{ width: "120px", marginRight: "4px" }}>
                <PingPongSpinner text="Uploading..." />
              </div>
            ) : (
              <img
                src={avatar}
                alt="img"
                className="img-fluid"
              />
            )
            }
            <div className="media-body">
              <label
                className="btn-profile"
                htmlFor="avatarFile"
                style={{ cursor: "pointer", marginBottom: "4px", whiteSpace: "nowrap" }}
              >
                Upload &nbsp;
              </label>
              <input
                onChange={(evt) => uploadAvatar(evt.target.files)}
                type="file"
                name="avatarFile"
                id="avatarFile"
                className="d-none"
              />
              <button
                type="button"
                className="btn-profile btn-profile-default"
                onClick={removeAvatar}
              >
                Remove
              </button>
            </div>
            {!user.avatar && 
              <button className={styles.rerollBtn} onClick={rerollAvatar}>
                <img src="/icons/dice.svg" alt="Dice" />
                <span>Reroll avatar</span>
              </button>
            }
          </div>
          <div className="Login">
            <div className="box">
              <form onSubmit={saveProfile}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="dName" className="form-label">
                        Display Name
                      </label>
                      <input
                        type="name"
                        className="form-control"
                        id="dName"
                        value={displayName}
                        onChange={(evt) => setDisplayName(evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="Username" className="form-label">
                        Username
                      </label>
                      <input
                        value={user.username}
                        readOnly
                        type="name"
                        className="form-control"
                        id="Username"
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    value={user.email}
                    readOnly
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>

                <div className="mb-3 ">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Country
                  </label>
                  <select
                    className="form-select"
                    placeholder="Select Country..."
                    onChange={(evt) => setCountry(evt.target.value)}
                    value={country}
                  >
                    {countries.map((ct, idx) => (
                      <option key={idx} value={ct.value} id={ct.value}>
                        {ct.label}
                      </option>
                    ))}
                  </select>
                  {/* <Select options={countries} className="form-select"/> */}
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input "
                    id="exampleCheck1"
                    checked={shareMarketing}
                    onChange={(evt) => setShareMarketing(evt.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Share my data with SongMode for marketing purposes.
                  </label>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn d-inline-block w-auto">
                    {isProgress && (
                      <Spinner
                        animation="border"
                        variant="warning"
                        size="sm"
                        style={{ marginLeft: -26, marginRight: 10 }}
                      />
                    )}
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
