import { useState } from "react";
import { useSelector } from "react-redux";

import { getComponentPitches } from "../../utils";
import styles from "./SongStatsGrid.module.scss";
import HLevel from "../utils/headerLevel/HLevel";

export default function SongStatsGrid({ song, tutUniqueProgsCount, setUniqueProgsVisible, tutorialKey, changeKey, isRootNotes, singleLine, headerLevel }) {
	const { isLightMode } = useSelector((state) => state.toggle);
  const [showTooltip, setShowTooltip] = useState(false);
	const [keyChanged, setKeyChanged] = useState(false);
	const _key = tutorialKey || song?.songKey;
  const { componentPitches, commonNotesCount, isSharpChords } = getComponentPitches(_key) ?? {};
	
	const [relativeTransposeCount, setRelativeTransposeCount] = useState(0);
	if (relativeTransposeCount > 11 || relativeTransposeCount < -11) setRelativeTransposeCount(0);

	function transpose(direction) {
		changeKey(direction);
		setRelativeTransposeCount(prev => prev + (direction === "up" ? 1 : -1));
		if (!keyChanged) {
			setKeyChanged(true);
		}
	}
  
	return (
		<div className={`${styles.songStats} ${isLightMode ? styles.lightMode : ''}`} style={{gridTemplateRows: singleLine?'1fr':''}}>
			<HLevel level={headerLevel} className={styles.key}>
				<div className={styles.cdiv}>
					<div className={styles.crow}>Key&nbsp;</div>
					{commonNotesCount != null && componentPitches != null && (
						<span
							onMouseOver={() => setShowTooltip(true)}
							onMouseLeave={() => setShowTooltip(false)}
							className={`${styles.sharpCount} ${styles.cspan}`}
						>
							&nbsp;{commonNotesCount}{commonNotesCount !== 0 && (isSharpChords ? '♯' : '♭')}&nbsp;
							{showTooltip && (
								<span className={styles.tooltip}>
									<span>{`${_key[0]}${_key.includes('♯') ? "-sharp" : _key.includes('♭') ? "-flat" : ''} ${_key.includes('m') ? "minor" : "major"}`}</span>
									<br />
									<span>{componentPitches.join(" | ")}</span>
								</span>
							)}
						</span>
					)}
				</div>
				<div className={styles.cdiv}>
					{tutorialKey && <button onClick={() => transpose("down")} title="Transpose down"><img src="/icons/circle-arrow-down.svg" alt="Arrow pointing down" /></button>}
					<div className={styles.crow}>{_key ?
						<div className={styles.cspan}>
							{_key}
							{/* Render relative count only if key is changed by the user */}
							{keyChanged && <>&nbsp;<span style={{ fontWeight: 100 }}>({relativeTransposeCount > 0 ? `+${relativeTransposeCount}` : relativeTransposeCount})</span></>}
						</div> : '-'
					}</div>
					{tutorialKey && <button onClick={() => transpose("up")} title="Transpose up"><img src="/icons/circle-arrow-up.svg" alt="Arrow pointing up" /></button>}
				</div>
			</HLevel>
			<HLevel level={headerLevel} title="Beats Per Minute" className={styles.tempo} style={{borderBottom: singleLine?0:''}}>
				<div className={styles.crow}>BPM</div>
				<div className={styles.cspan}>{song?.tempo > 0 ? song.tempo : "-"}</div>
			</HLevel>
			{!singleLine && <HLevel level={headerLevel} className={styles.releaseDate}>
				<div className={styles.crow}>Released</div>
				<div className={styles.cspan}>{song?.released_date ? song.released_date : "-"}</div>
			</HLevel>}
			{!singleLine && <HLevel level={headerLevel}
				onClick={tutUniqueProgsCount > 0 ? (() => setUniqueProgsVisible(prev => !prev)) : null}
				onMouseEnter={tutUniqueProgsCount > 0 ? (e => e.target.classList.add(styles.hoverUniqueChords)) : null}
				onMouseLeave={tutUniqueProgsCount > 0 ? (e => e.target.classList.remove(styles.hoverUniqueChords)) : null}
				className={styles.uniqueChords}
				title={`Show/hide unique ${isRootNotes?'root note':'chord'} progressions for this tutorial.`}
			>
				<div className={styles.crow}>{isRootNotes?'Root note':'Chord'} progressions</div>
				<div className={styles.cspan}>
					{tutUniqueProgsCount > 0 ? tutUniqueProgsCount : (song?.ch_progression_count > 0 ? song.ch_progression_count : "-")}
				</div>
			</HLevel>}
		</div>
	);
}
