import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
// import { SwiperSlide } from "swiper/react";
import {
  formatNumber,
  getAccessConfig,
  getAccessToken,
  getDefaultAvatar,
  Viewers,
  // makeGrid,
  // mediaURL,
} from "../../../utils";
import {TOGGLE_THEME} from "../../../store/action/ActionType";
import {
  removeArtistSubscription,
  removeCreatorSubscription,
} from "../../../store/action/SubscribeAction";
import styles from "./ViewProfile.module.scss";
import { getImageColorInfo } from "../../../utils";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileSideMenu from "../../../component/mobileSideMenu/MobileSideMenu";
import Difficulty from "../../../component/difficulty/Difficulty";
import BottomNavbar from "../../../layouts/bottomNavbar/BottomNavbar";
import TutorialsTable from "../../../component/tutorialsTable/TutorialsTable";
import SongsTable from "../../../component/songsTable/SongsTable";
const Switch = React.lazy(() => import("../../../component/switch/Switch"));


async function getProfileData() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/tutorials/profile-lists/`;
    const ret = await axios.get(url, getAccessConfig());
    return ret.data;
  } catch (err) {
    const error = err.response ? err.response : "networkError";
    console.log(err);
    console.log(error);
    return false;
  }
}

const ViewProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { uname } = useParams();

  const { get_user_info_success: user } = useSelector((state) => state.user);
  const { subscribedCreators, subscribedArtists } = useSelector(
    (state) => state.subscriptions
  );

  const setLightMode = () => dispatch({type: TOGGLE_THEME});

  const [tabKey, setTabKey] = useState("played");
  const [selectedTab, setSelectedTab] = useState("creators");
  const [data, setData] = useState({
    played: [],
    unfinished: [],
    requested: [],
    subscribed: [],
    favorites: [],
  });

  const tabs = [
    { key: "played", label: "Learned" },
    { key: "unfinished", label: "Bookmarked" },
    { key: "requested", label: "Requested" },
    { key: "subscribed", label: "Subscribed" },
  ];

  useEffect(() => {
    if (!getAccessToken() || (user && user.username !== uname)) {
      history.push("/login");
      return;
    }

    if (!user || !uname) {
      return;
    }

    getProfileData().then((dd) => {
      for (let rr of dd.requested) {
        rr.requestedAt = new Date(rr.requested_at).toLocaleTimeString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      setData({
        ...dd,
        subscribed: subscribedCreators.concat(subscribedArtists),
      });
    });
  }, [history, subscribedArtists, subscribedCreators, uname, user]);
  const { isLightMode } = useSelector((state) => state.toggle);


  const primaryColor = useRef('');
  function handleSongImageMount(node) {
    if (node == null) return;
    if (user.avatar) {
      const rgbPalette = getImageColorInfo(node);
      primaryColor.current = `rgb(${rgbPalette.primary?.r}, ${rgbPalette.primary?.g}, ${rgbPalette.primary?.b})`;
    } else if (user.animated_avatar) {
      primaryColor.current = `#${user.animated_avatar.slice(-6, user.animated_avatar.length)}`;
    } else {
      primaryColor.current = "#141414";
    }
  }
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [bookmarkType, setBookmarkType] = useState("tutorials");
  
  const [filterText, setFilterText] = useState('');
  const filteredItems = useMemo(() => {
    if (tabKey === "unfinished" && bookmarkType === "songs") {
      return data.favorites.filter(song => (
        song?.name.toLowerCase().includes(filterText.toLowerCase()) ||
        song?.artists[0].name.toLowerCase().includes(filterText.toLowerCase())
      ));
    }
    return data[tabKey].filter(tutorial => (
      tutorial.song?.name.toLowerCase().includes(filterText.toLowerCase()) ||
      tutorial.song?.artists[0].name.toLowerCase().includes(filterText.toLowerCase())
  ))}, [data, tabKey, filterText, bookmarkType]);

  if (!user) return null;
  return (
    <>
      <div className="profile-item profile-page">
        <BottomNavbar style={{marginLeft: "-12px"}}/>
        <div style={{display: "block"}} className="media">
          {isMobile && <>
            <button
              style={{ backgroundColor: isLightMode ? "transparent" : '' }}
              onClick={() => setIsSideMenuOpen(true)}
              className={styles.sideMenuButton}
            >
              <img
                style={{ filter: isLightMode ? "invert(1)" : '' }}
                src="/icons/hamburger.svg" 
                alt="Side menu" 
              />
            </button>
            <MobileSideMenu style={{ left: isSideMenuOpen ? 0 : "-100%", backgroundColor: isLightMode ? "rgba(255, 255, 255, 0.8)" : ''}} setIsSideMenuOpen={setIsSideMenuOpen} />
          </>}
          <div className={styles.pfpBackground} style={{backgroundColor: primaryColor.current}} />
          <NavLink
            className={styles.editButton}
            to={`/profile/edit`}
          >
            <img style={{ filter: isLightMode ? "invert(1)" : '' }} alt="Edit button" src="/icons/edit.svg" />
          </NavLink>
          <div className={`box-img ${styles.boxImg}`}>
            <img
              ref={handleSongImageMount}
              src={user.avatar || user.animated_avatar || getDefaultAvatar(user.email)}
              alt="img"
              className={`${styles.pfp} img-fluid profile-images`}
            />
          </div>
          <div className={`media-body ${styles.mediaBody}`}>
            <div>
              <h2>{user.name} </h2>
              <p className="location">
                <img style={{ filter: isLightMode ? "invert(1)" : '' }} src="/img/location.png" alt="img" className="img-fluid" />
                {user.country.name || "Songtopia"}
                {/* {false && <sub>10</sub>} */}
              </p>
            </div>
            <ul className={styles.userStats}>
              <li>
                <a href="#0">
                  <img
                    style={{ filter: isLightMode ? "invert(1)" : '' }}
                    src="/img/song.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  <span>{user.total_score} total score</span>
                </a>
              </li>
              <li>
                <a href="#0">
                  <img
                    style={{ filter: isLightMode ? "invert(1)" : '' }}
                    src="/img/love-w.png"
                    alt="img"
                    className="img-fluid"
                  />
                  <span>{user.like_count} liked comments</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.modeSwitchContainer}>
          <Switch
            isOn={!isLightMode}
            onColor="var(--bg-primary)"
            handleToggle={() => {
              setLightMode();
            }}
            style={{marginRight: '20px'}}
          />
          <img
            src={isLightMode ? "/icons-light/dark-light-mode.svg" : "/icons/dark-light-mode.svg"}
            alt="img"
            style={{width: "24px", height: "24px"}}
          />
        </div>
      </div>
      <div className="nav_body profile-body">
        <ul className={`nav nav-pills mb-3 ${styles.statsTabs}`} id="pills-tab" role="tablist">
          {tabs.map((tb) => (
            <li key={tb.key} className="nav-item" role="presentation">
              <button
                onClick={() => {setTabKey(tb.key); setFilterText('');}}
                className={"nav-link" + (tb.key === tabKey ? " active" : "")}
                type="button"
              >
                {tb.label} <span>{formatNumber(data[tb.key]?.length)}</span>
              </button>
            </li>
          ))}
          {/* <li className="nav-item" role="presentation">
            <button className="nav-link" id="pills-3-tab" data-bs-toggle="pill" data-bs-target="#pills-3" type="button" role="tab" aria-controls="pills-3" aria-selected="false">Performances <span>11</span></button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="pills-4-tab" data-bs-toggle="pill" data-bs-target="#pills-4" type="button" role="tab" aria-controls="pills-4" aria-selected="false">Subscriptions <span>16</span></button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="pills-5-tab" data-bs-toggle="pill" data-bs-target="#pills-5" type="button" role="tab" aria-controls="pills-5" aria-selected="false">Requested <span>26</span></button>
          </li> */}
          {/* <li className="nav-item">
            <div className="search-bar">
              <form className="d-inline-block">
                <input className="form-control" type="search" placeholder="Search songs and artists" aria-label="Search" />
                <button type="submit"><img src="/img/search.png" alt="img" className="img-fluid"/></button>
              </form>
            </div>
          </li> */}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div style={{ overflowX: tabKey === "subscribed" && isMobile && "hidden" }} className={`tab-pane fade show active ${styles.tabContent}`}>
            {(tabKey === "played" || tabKey === "unfinished") && (
              <div className={styles.playedTab}>
                <div className={`search ${styles.searchFilterContainer}`}>
                  <div className="search-bar">
                    <form onSubmit={(e) => e.preventDefault()} className="d-inline-block">
                      <input
                        onChange={(e) => setFilterText(e.target.value)}
                        value={filterText}
                        className="form-control"
                        type="search"
                        placeholder="Filter tutorials by name"
                        aria-label="Search"
                      />
                      <button><img src="/img/search.png" alt="img" className="img-fluid" /></button>
                    </form>
                  </div>
                </div>
                {tabKey === "unfinished" && <div className={styles.sectionPickerButtonContainer}>
                  <button
                    onClick={() => setBookmarkType("tutorials")}
                    className={bookmarkType === "tutorials" ? styles.activeSection : ''}
                  >
                    <span>Tutorials</span>
                  </button>
                  <button
                    onClick={() => setBookmarkType("songs")}
                    className={bookmarkType === "songs" ? styles.activeSection : ''}
                  >
                    <span>Songs</span>
                  </button>
                </div>}
                {bookmarkType === "tutorials" && (
                  <TutorialsTable tutorials={filteredItems} />
                )}
                {bookmarkType === "songs" && (
                  <SongsTable songs={filteredItems} />
                )}
              </div>
            )}

            {tabKey === "requested" && (
              <SongsTable songs={data.requested} isRequestedTable />
            )}

            {tabKey === "subscribed" && (
              <div>
                <div className={`${styles.subscribedTabs} container-fluid tab-buttons`}>
                  <button
                    onClick={() => {
                      setSelectedTab("artists");
                    }}
                    className={`${selectedTab === "artists" ? "active" : ""} ${styles.subscribedTab}`}
                  >
                    Artists
                  </button>
                  <button
                    onClick={() => {
                      setSelectedTab("creators");
                    }}
                    className={`${selectedTab === "creators" ? "active" : ""} ${styles.subscribedTab}`}
                  >
                    Creators
                  </button>
                </div>

                {selectedTab === "creators" && (
                  <div style={{display: "flex", justifyContent: "center"}} className="mide-item">
                    <div className={`Top-Artists ${styles.subscribedWrapper}`}>
                      {subscribedCreators &&
                        subscribedCreators.map((aa, index) => (
                          <div
                            key={aa ? aa.id : `null${index}`}
                            className={styles.subscribedElement}
                          >
                            {aa ? (
                              <div style={{ margin: 0 }} className="banner-item text-center">
                                <div className="subscribe-card">
                                  <NavLink
                                    to={`/creators/${aa.slug}`}
                                    className="d-block"
                                  >
                                    <span className="box d-block">
                                      {/* <img
                                      src="/img/green-check.svg"
                                      alt="img"
                                      className="img-check"
                                    /> */}
                                      <img
                                        src={aa.thumbnail}
                                        alt={aa.name}
                                        className="img-fluid"
                                      />
                                    </span>
                                    <span className="p mb-0 d-block">
                                      {aa.creatorName}
                                    </span>
                                  </NavLink>
                                  <p>{aa.subscriberCount} subscribers</p>
                                  <ul>
                                    <li style={{ width: "202px" }}>
                                      <button
                                        className="subscribed"
                                        onClick={() => {
                                          const config = {
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                              Authorization:
                                                "Bearer " +
                                                getAccessToken(),
                                            },
                                          };
                                          const formData = new FormData();
                                          formData.append(
                                            "channelId",
                                            aa.id
                                          );
                                          dispatch(
                                            removeCreatorSubscription(
                                              config,
                                              formData
                                            )
                                          );
                                        }}
                                      >
                                        Subscribed
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {selectedTab === "artists" && (
                  <div style={{display: "flex", justifyContent: "center"}} className="mide-item">
                    <div className={`${styles.subscribedWrapper} Top-Artists`}>
                      {subscribedArtists &&
                        subscribedArtists.map((aa, index) => (
                          <div
                            key={aa ? aa.id : `null${index}`}
                            className={styles.subscribedElement}
                          >
                            {aa ? (
                              <div style={{ margin: 0 }} className="banner-item text-center">
                                <div className="subscribe-card">
                                  <NavLink
                                    to={`/artists/${aa.slug}`}
                                    className="d-block"
                                  >
                                    <span className="box d-block">
                                      {/* <img
                                      src="/img/green-check.svg"
                                      alt="img"
                                      className="img-check"
                                    /> */}
                                      <img
                                        src={aa.thumbnail}
                                        alt={aa.artistName}
                                        className="img-fluid"
                                      />
                                    </span>
                                    <span className="p mb-0 d-block">
                                      {aa.artistName}
                                    </span>
                                  </NavLink>
                                  <p>{aa.subscriberCount} subscribers</p>
                                  <ul>
                                    <li style={{ width: "202px" }}>
                                      <button
                                        className="subscribed"
                                        onClick={() => {
                                          const config = {
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                              Authorization:
                                                "Bearer " +
                                                getAccessToken(),
                                            },
                                          };
                                          const formData = new FormData();
                                          formData.append(
                                            "artistId",
                                            aa.id
                                          );
                                          dispatch(
                                            removeArtistSubscription(
                                              config,
                                              formData
                                            )
                                          );
                                        }}
                                      >
                                        Subscribed
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="pills-5"
            role="tabpanel"
            aria-labelledby="pills-5-tab"
          >
            <div className="Played">
              <div className="table-responsive"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
