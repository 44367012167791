import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeNumberSmall } from "../../utils";

import styles from "./SongsTable.module.scss";
import BookmarkButton from "../bookmarkButton/BookmarkButton";
import { SET_MUSIC } from "../../store/action/ActionType";
import YouTube from "react-youtube";
import useWindowSize from "../../hooks/useWindowSize";

export default function SongsTable({ songs, isRanked, requestSong, getSongStatus, isRequestedTable, isShowReleaseDate }) {

  if (isShowReleaseDate !== false) {
    isShowReleaseDate = true;
  }

  const history = useHistory();
  const dispatch = useDispatch();

	const { get_user_info_success: user } = useSelector((state) => state.user);
  const { isLightMode } = useSelector((state) => state.toggle);
  
  const { activeCardSongId } = useSelector((state) => state.music);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 992;

  const onThumnailClick = (song) => {
    if (!song.youtube_id) {
      history.push(`/songs/${song.slug}-${song.id}`);
      return;
    }
    dispatch({
      type: SET_MUSIC,
      data: { activeCardSongId: song.id },
    });
  };

  const isRankedContainer = isRanked;

	return (
    <div className={isRankedContainer?styles.rankedContainer:''}>
      <div className="Played table-responsive">
        <table className={`table table-dark ${isLightMode ? styles.lightMode : ''}`}>
          <thead>
            <tr>
              {!isRequestedTable && !isMobile && <th scope="col">{isRanked && '#'}</th>}
              <th scope="col" colSpan="2" className={styles.songName}>
                Song
              </th>
              {!isRequestedTable && !isMobile && <th></th>}
              {songs[0]?.videosViews != null && <th scope="col">Related Tutorials<br/>Views</th>}
              {isRequestedTable && <>
                <th scope="col">Status</th>
                <th scope="col">Requested at</th>
              </>}
              {!isRequestedTable && <>
                <th scope="col">Key</th>
                <th scope="col">BPM</th>
                {isShowReleaseDate && <th scope="col">Release Date</th>}
              </>}
            </tr>
          </thead>
          <tbody>
            {songs.map((song, i) => (
              <Row
                key={`${song.id}${i}`}
                song={song}
                i={i}
                isRanked={isRanked}
                requestSong={requestSong}
                getSongStatus={getSongStatus}
                isRequestedTable={isRequestedTable}
                user={user}
                isActiveSong={activeCardSongId===song.id}
                onThumnailClick={onThumnailClick}
                isMobile={isMobile}
                isLightMode={isLightMode}
                isShowReleaseDate={isShowReleaseDate}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
	);
}

function Row({ song, i, isRanked, requestSong, getSongStatus, isRequestedTable, user, isActiveSong, onThumnailClick, isMobile, isLightMode, isShowReleaseDate }) {
	const isRequested = getSongStatus && getSongStatus(song) !== "new";
	const isNoTutorials = !song.slug || !song.ch_tutorial_count;

  const onYoutubeReady = (evt) => {
    const nplayer = evt.target;
    const videoData = nplayer.getVideoData();
    const videoId = videoData.video_id;
    if (isActiveSong && videoId === song.youtube_id) {
      nplayer.playVideo();
    }
  };

  const hasKey = (song.song_key || song.songKey) != null;
  const hasTempo = song.tempo != null;
  //const hasRelease = song.released_date != null;
	
	return (
    <>
      <tr className={`${styles.row} ${isActiveSong?styles.activeVideo:''}`} data-request={isNoTutorials && requestSong && "show"}>
        {!isRequestedTable && !isMobile && (
          <td className={`table-bold ${styles.rank}`}>
            <BookmarkButton
              className={styles.optionsButton}
              song={song}
              user={user}
            />
            {isRanked && <span>{i + 1}</span>}
          </td>
        )}
        <td className={isMobile?'table-img':styles.dkTableImg} width={isMobile?'':'1%'}>
            <img
              src={song.youtube_id ? `https://i.ytimg.com/vi/${song.youtube_id}/mqdefault.jpg` : song.artwork_medium}
              alt={song.name}
              style={{
                aspectRatio: !song.youtube_id && "1/1",
                minWidth: song.youtube_id && 120,
                minHeight: 70,
                maxHeight: 70,
                cursor: 'pointer',
              }}
              onClick={()=>onThumnailClick(song)}
            />
        </td>
        <td className={styles.songName} style={{padding:8,maxWidth:isMobile?'':'100%'}}>
          <Link to={`/songs/${song.slug}-${song.id}`} className={styles.songNameLink}>
            <span>{song.name}</span>
          </Link>
          <div className={styles.artistMobile}>
            {song.artists?.map && song.artists.map((aa, idx) => (
              <>
                {idx > 0 && <span className={styles.linkSep}>,&nbsp;</span>}
                <Link to={`/artists/${aa.slug}`}>
                  {aa.name}
                </Link>
              </>
            ))}
            {!song.artists?.map && song.mainArtist && <Link to={`/artists/${song.mainArtist.slug}`}>
              {song.mainArtist.name}
            </Link>}
            {!song.artists?.map && !song.mainArtist && song.artists}
          </div>
          <div style={{position: 'relative', top:8}}>
            {isMobile && hasKey && <span className={styles.infoBadge}>{song.song_key || song.songKey}<br/><span>KEY</span></span>}
            {/* {isMobile && isRanked && hasKey && hasTempo && ' - '} */}
            {isMobile && hasTempo && <span className={styles.infoBadge}>{song.tempo}<br/><span>BPM</span></span>}
          </div>
        </td>
        {!isRequestedTable && !isMobile && <td style={{width:isRanked?'10%':'25%'}}><Link to={`/songs/${song.slug}-${song.id}`}><img style={{width:32, height:32, opacity:0.2}} src={`/icons${isLightMode?'-light':''}/keys.svg`} alt=""/></Link></td>}
        {!isRequestedTable ? <>
          {song.videosViews != null && (
            <td className={styles.views}>
              {makeNumberSmall(song.videosViews)}
            </td>
          )}
          <td className={styles.key} style={{minWidth:62, verticalAlign: isMobile?'top':'', position:'relative'}}>
            {!isMobile && <p>{hasKey ? (song.song_key || song.songKey) : '-'}</p>}
            {isMobile && <div className={styles.rank} style={{marginBottom:22, marginTop:2}}>
              {!isRequestedTable &&
                <BookmarkButton
                  className={styles.optionsButton}
                  song={song}
                  user={user}
                />
              }
              {isRanked && <span>{i + 1}</span>}
            </div>}

            {isMobile && <Link to={`/songs/${song.slug}-${song.id}`} className={styles.infoBadge} style={{paddingLeft:8, paddingRight: 8, verticalAlign: 'top', display:'block', marginRight: 0, marginBottom: 0, position:'absolute', right:8, bottom:8}}>
              <img style={{width:32, height:32, opacity:0.75}} src={`/icons${isLightMode?'-light':''}/keys.svg`} alt=""/>
            </Link>}

            {/* {isMobile && hasRelease && <div style={{position:'relative', height: 18}}><p style={{position: 'absolute', right: 0, fontSize:14, marginBottom: 0, textAlign: 'right'}}>{song.released_date}</p></div>} */}
          </td>
          <td className={styles.tempo}>{song.tempo != null ? `${song.tempo} BPM` : '-'}</td>
          {isShowReleaseDate && <td className={styles.releasedDate}>{song.released_date != null ? song.released_date : '-'}</td>}
          <td style={{ display: isRequested ? "inline-block" : '' }} className={styles.requestButtonContainer}>
            <button onClick={() => requestSong(song)}>
              {isRequested ? "Song requested" : "Request song"}
            </button>
          </td>
        </> : <>
          <td className={styles.requestStatus}>
            Requested
          </td>
          <td className={styles.requestedAt}>
            {song.requestedAt != null ? song.requestedAt : '-'}
          </td>
        </>}
      </tr>
      {isActiveSong && <tr className={styles.row}>
        <td colSpan={7} style={{display:'table-cell', paddingTop:0}}>
          <YouTube
            className={styles.cartResponsiveYoutube}
            videoId={song.youtube_id}
            onReady={onYoutubeReady}
            opts={{
              width: 640,
              height: 360,
              playerVars: {
                rel: 0,
                modestbranding: 1,
                showinfo: 0,
                fs: 0,
              },
            }}
          />
        </td>
      </tr>}
    </>
	)
}