import ChordProgressions from "../chordProgressions/ChordProgressions";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


export default function ChordProgressionsViewer({ tutorial, chordData, currentTime, videoDuration, setPlaybackPosition, isMobile, isPortrait, isLightMode }) {
  const is_superUser = useSelector(state => state.auth.is_superUser);
  const isAdmin = is_superUser==="true";

  return (
    <>
      <ChordProgressions
        chordData={chordData}
        currentTime={currentTime}
        videoDuration={videoDuration}
        setPlaybackPosition={setPlaybackPosition}
        isMobile={isMobile}
        isPortrait={isPortrait}
        isLightMode={isLightMode}
      />

      {isAdmin && tutorial && tutorial.chords && tutorial.chords.progressions && 
      <div style={{height: 0, position: 'relative', zIndex: 1}}>
        <Link to={`/admin/chords-editor/${tutorial.song.slug}-${tutorial.id}`} className="btn btn-info btn-sm" style={{display: 'inline-block', height: 30, position: 'relative', top: -30}}>
          Edit {tutorial.chords.isManual?'Again':'Chords'}
        </Link>
      </div>}
    </>
  );
}