import React from 'react';
import styles from './DropdownMenu.module.scss'
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";


import {TOGGLE_THEME} from "../../store/action/ActionType";


const Switch = React.lazy(() => import("../switch/Switch"));


const DropdownMenu = ({submenus, isOpen, top, right, user}) => {
  const dispatch = useDispatch();
  const { isLightMode } = useSelector((state) => state.toggle);
  const setLightMode = () => dispatch({type: TOGGLE_THEME});
  const bgColor = isLightMode ? "#ffffff" : "var(--bg-secondary)"
  return (
    <ul className={styles.dropdown}
        style={{
          display: isOpen ? "inline-block" : "none",
          top: top,
          right: right,
          background: bgColor
        }}
    >
      <div style={{display: 'flex', flexDirection: 'row-reverse', width: '98%'}}>
        <div style={{position: 'absolute', top: "-18px"}}>
          <svg width="25" height="12" viewBox="0 0 373 264" fill="#271433" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M180.01 4.00226C183.203 -0.426801 189.797 -0.426803 192.99 4.00226L370.917 250.822C374.732 256.113 370.951 263.5 364.428 263.5H8.57238C2.04946 263.5 -1.73163 256.113 2.08281 250.822L180.01 4.00226Z"
              fill={bgColor}/>
          </svg>
        </div>
      </div>
      {submenus.map((submenu, index) => (
        <NavLink key={index} to={submenu.url} style={{width: "100%"}}>
          <li className={isLightMode ? styles.lightMode : styles.darkMode}>
            <span style={{color: isLightMode}}>{submenu.icon}{" "}{submenu.title}</span>
          </li>
        </NavLink>
      ))}
      <hr style={{borderTop: isLightMode ? "1.5px solid #000000" : "1.5px solid #FFFFFF",opacity: 0.5, width: "90%", borderRadius: "2px", margin: 'auto'}}/>
      <li className="nav-item" style={{margin: "1px !important"}}>
        <Switch
          isOn={!isLightMode}
          onColor="var(--bg-tertiary)"
          handleToggle={() => {
            setLightMode();
          }}
          style={{marginRight: '20px'}}
        />
        <img
          src={isLightMode ? "/icons-light/dark-light-mode.svg" : "/icons/dark-light-mode.svg"}
          alt="img"
          style={{width: "24px", height: "24px"}}
        />
        {user &&
          <NavLink to={`/users/${user.username}`} className={styles.userStats}>
            <div>
              <img
                src={
                  isLightMode
                    ? "/img/song-light.png"
                    : "/img/song.svg"
                }
                alt="img"
                className="img-fluid"
              />{" "}
              <span>{user?.total_score}</span>
            </div>
            <div>
              <img
                src={
                  isLightMode
                    ? "/icons-light/heart.svg"
                    : "/icons/heart.svg"
                }
                alt="img"
                className="img-fluid"
              />{" "}
              <span>{user?.like_count}</span>
            </div>
          </NavLink>
        }
      </li>
    </ul>
  );
};

export default DropdownMenu;