import React, {useEffect, useRef} from "react";

import {makeGrid} from "../../utils";
import TutorialItem from "../tutorialItem/TutorialItem";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
// import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import {EffectCoverflow, Navigation} from "swiper";

import styles from "../../container/Slider.module.scss";
import {useSelector} from "react-redux";
import FadeoutDiv from "../fadeoutDiv/FadeoutDiv";
import useWindowSize from "../../hooks/useWindowSize";

function TutorialList({items}) {
  const {isLightMode} = useSelector((state) => state.toggle);

  return (
    <>
      {items &&
        makeGrid(items, 6).map((row, ridx) => (
          <div key={ridx} className="top-songs">
            <div className="row g-0">
              {row.map((song, index) => (
                <div
                  key={song ? song.id : `null${index}`}
                  className={
                    "col-lg-2 col-md-3 col-6" +
                    (index > 1 ? " mt-lg-0 mt-4" : "")
                  }
                >
                  {song && song.slug && <TutorialItem tutorial={song}/>}
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
}

function TutorialSlider({items}) {
  const swiperButtonPrevRef = useRef(null);
  const swiperButtonNextRef = useRef(null);
  const windowSize = useWindowSize();

  return (
    <div className={`top-songs ${styles.slider}`}>
      {items && items.length > 0 &&
        <Swiper
          grabCursor={true}
          spaceBetween={-50}
          centeredSlides={true}
          effect={"coverflow"}
          slidesPerView={1}
          loop={true}
          coverflowEffect={{
            rotate: 0,
            stretch: -100,
            depth: 50,
            modifier: 1,
            slideShadows: false,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          breakpoints={{
            0: {
              slidesPerView: 1.15,
              centeredSlides: true,
              spaceBetween: -70,
            },
            576: {
              slidesPerView: 3,
              // height: '80%',
              // autoHeight: false,
              centeredSlides: true,
              spaceBetween: -50,
            },
            768: {
              slidesPerView: 5,
              // height: '30%',
              // autoHeight: true,
              centeredSlides: true,
              spaceBetween: -50,
            },
            992: {
              slidesPerView: 5,
              centeredSlides: true,
              spaceBetween: -50,
            },
            1200: {
              slidesPerView: 7,
              centeredSlides: true,
              spaceBetween: -50,
            },
          }}
          modules={[
            // FreeMode,
            EffectCoverflow,
            // Pagination,
            Navigation,
          ]}
          navigation={{
            nextEl: swiperButtonPrevRef.current,
            prevEl: swiperButtonNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = swiperButtonPrevRef.current;
            swiper.params.navigation.nextEl = swiperButtonNextRef.current;
          }}
        >
          {/*No fadeout when one slide on page*/}
          {/*{window.innerWidth >= 576 && <FadeoutDiv/>}*/}
          <FadeoutDiv style={{opacity: window.innerWidth < 576 ? 0.5 : 1}}/>
          {items && items.length > 0 &&
            items.map((tutorial, index) => (
              <SwiperSlide key={tutorial ? tutorial.id : `null${index}`}>
                {tutorial && <TutorialItem tutorial={tutorial}/>}
              </SwiperSlide>
            ))}
          {
            windowSize.width >= 576 && <>
              <div ref={swiperButtonPrevRef} className="swiper-button-prev" style={{color: "#938999"}}/>
              <div ref={swiperButtonNextRef} className="swiper-button-next" style={{color: "#938999"}}/>
            </>
          }
        </Swiper>
      }
    </div>
  );
}

export default TutorialList;
export {TutorialSlider};
