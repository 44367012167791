import { NavLink } from 'react-router-dom';
import Difficulty from '../difficulty/Difficulty';
import { formatDuration, Viewers } from '../../utils';
import { Fragment } from 'react';
import styles from "./RelatedTutorials.module.scss";

export default function RelatedTutorials({ tutorials, song, setRelatedTutorialsOpen, isMobile, showNames = true }) {
	return (
		<div className={`${styles.relatedTutorials} Blinding-right`}>
			{tutorials &&
				tutorials.map((tut, index) => (
					<div key={tut.id} className={`media ${styles.tutorialContainer}`}>
						<NavLink
							to={`/tutorials/${song.slug}-${tut.id}`}
							className="user"
							style={{ marginRight: 10 }}
							onClick={() => setRelatedTutorialsOpen && setRelatedTutorialsOpen(false)}
						>
							<img
								src={tut.thumbnail}
								alt="img"
								className="img-fluid"
								style={{ width: 140 }}
							/>
							{!isMobile && (
								<span className="time d-inline-block">
									{formatDuration(tut.duration)}
								</span>
							)}
						</NavLink>
						<div className="media-body">
							<div className="row">
								<div className="col-12">
									{showNames && <>
										<NavLink
											to={`/tutorials/${song.slug}-${tut.id}`}
											className={`title ${styles.songName}`}
											onClick={() => setRelatedTutorialsOpen && setRelatedTutorialsOpen(false)}
										>
											{tut.song?.name}
										</NavLink>
										<span className={`singer-name d-block ${styles.artistName}`}>
											{tut.song?.artists.map((aa, aaidx) => (
												<Fragment key={aa.id}>
													{aaidx > 0 && ", "}
													<NavLink to={`/artists/${aa.slug}`}>{aa.name}</NavLink>
												</Fragment>
											))}
										</span>
									</>}
									<span style={{ marginTop: !showNames && "12px" }} className={`singer-name d-block`}>
										<NavLink className={styles.creatorName} to={`/creators/${tut.channelSlug}`}>
												<img
													src={tut.channelArtworkSmall}
													alt="img"
													className="img-fluid profile-images"
													style={{
														width: "16px",
														height: "16px",
														backgroundColor: "#2C1C37",
														borderRadius: "50%",
														marginRight: '5px',
													}}
													onError={({currentTarget}) => {
														currentTarget.onerror = null; // prevents looping
														currentTarget.src = '/img/user.svg';
													}}
												/>
											{tut.channelName}
										</NavLink>
									</span>
									<div className="viewers-holder" style={{ maxWidth: 165 }}>
										<Viewers count={tut.views} />
										<Difficulty level={tut.level} />
									</div>
								</div>
							</div>
						</div>
						{index !== tutorials.length - 1 && <hr className={styles.separator} />}
					</div>
				))}
		</div>
	);
}
