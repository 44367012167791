import React from 'react'


function HLevel({ children, level, ...props }) {

  const TagName = `h${level}`;

  return <TagName {...props}>{children}</TagName>
}

export default HLevel
